import { useEffect, useState } from 'react';

import { useBrand } from 'reaxl-brand';

import { viewCountFetcher } from '@/fetchers';

const baseIconUrl = '/content/static/img/icon';

const urgencyDriverIcons = {
    custom: {
        imgSrc: `${baseIconUrl}/promocard/detailed.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
    peaceofmind: {
        imgSrc: `${baseIconUrl}/promocard/most_reliable.svg`,
        imgHeight: '52',
        imgWidth: '52',
    },
    incentive: {
        imgSrc: `${baseIconUrl}/promocard/affordable_new_cars.svg`,
        imgHeight: '52',
        imgWidth: '52',
    },
    reduced: {
        imgSrc: `${baseIconUrl}/promocard/price_drops.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
    newlylisted: {
        imgSrc: `${baseIconUrl}/promocard/vehicle_listing.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
    numviewed: {
        imgSrc: `${baseIconUrl}/promocard/speed_up_the_deal.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
};

const useVehicleHighlightUrgencyDrivers = ({ listing = {} }) => {
    const { brand } = useBrand();
    const {
        vehicleHighlight: {
            urgencyDriverType,
            urgencyDriverValue,
        } = {},
    } = listing;
    const [urgencyDriver, setUrgencyDriver] = useState({});

    useEffect(() => {
        const fetchViewCount = async ({ vin, dateRange = 7 }) => {
            const result = await viewCountFetcher({ vin, dateRange });
            return result;
        };

        if (urgencyDriverType && urgencyDriverValue) {
            switch (urgencyDriverType) {
                case 'DEALER_INCENTIVE':
                    setUrgencyDriver({ type: 'custom', heading: urgencyDriverValue, ...urgencyDriverIcons.custom });
                    break;
                case 'PRICE_REDUCED':
                    setUrgencyDriver({ type: 'reduced', heading: `${urgencyDriverValue}!`, ...urgencyDriverIcons.reduced });
                    break;
                case 'NEWLY_LISTED':
                    setUrgencyDriver({ type: 'newlylisted', heading: `${urgencyDriverValue}!`, ...urgencyDriverIcons.newlylisted });
                    break;
                case 'WARRANTY':
                    setUrgencyDriver({ type: 'warranty', heading: 'Limited Warranty', subText: urgencyDriverValue, ...urgencyDriverIcons.peaceofmind });
                    break;
                case 'RETURN':
                    setUrgencyDriver({ type: 'return', heading: 'Return Policy', subText: urgencyDriverValue, ...urgencyDriverIcons.peaceofmind });
                    break;
                case 'INCENTIVES':
                    setUrgencyDriver({ type: 'incentives', heading: 'Limited Time Offer!', subText: urgencyDriverValue, ...urgencyDriverIcons.incentive });
                    break;
                default:
            }
        } else if (listing.vin) {
            fetchViewCount({ vin: listing.vin }).then((count) => {
                const viewCount = count?.[brand.toUpperCase()] || 0;
                if (viewCount > 0) {
                    setUrgencyDriver({ type: 'numviewed', heading: 'Act Quick!', subText: viewCount === 1 ? `${viewCount} shopper is viewing` : `${viewCount} shoppers are viewing`, ...urgencyDriverIcons.numviewed });
                } else {
                    setUrgencyDriver({ type: 'default' });
                }
            });
        }
    }, [brand, listing, urgencyDriverType, urgencyDriverValue]);

    return urgencyDriver;
};

export default useVehicleHighlightUrgencyDrivers;
