import _get from 'lodash/get';

import {
    getScaledPrimaryImg,
} from '@/utilities';

import { pageNames } from '@atc/bonnet-paths';

import getSRPPrimaryImagesFromCtx from '@/utilities/getSRPPrimaryImagesFromCtx';

const { VEHICLE_DETAILS, SEARCH_RESULTS } = pageNames;

/* preload(http2 server push) the primary image */
export default function serverPushKeyRequests() {

    return async (ctx) => {
        if (typeof window === 'undefined') {

            const {
                data,
                store,
                res,
            } = ctx;
            const { browser } = store.getState();

            let scaledPrimaryImage;

            if (ctx.pageName === VEHICLE_DETAILS) {
                const { images } = _get(data, 'listings[0]', {});
                const primary = _get(images, 'primary', 0);
                const primaryImgSrc = _get(images, `sources[${primary}].src`, '');
                // Only add the preload on the vdp for mobile, otherwise we can't predictably know which size the
                // primary image will be for the responsive image on the vdp.  We'll have to rely on a link preload
                // in the head with the responsive srcset for desktop.
                if (primaryImgSrc && _get(browser, 'is.xs')) {
                    scaledPrimaryImage = getScaledPrimaryImg(VEHICLE_DETAILS, browser, primaryImgSrc);
                }
            } else if (ctx.pageName === SEARCH_RESULTS) {
                const primaryImages = getSRPPrimaryImagesFromCtx(ctx);
                const primaryImageSrc = _get(primaryImages, 'sources[0].src');

                if (primaryImageSrc) {
                    scaledPrimaryImage = getScaledPrimaryImg(SEARCH_RESULTS, browser, primaryImageSrc);
                }
            }

            if (scaledPrimaryImage) {
                const preloads = [`<${scaledPrimaryImage}>; rel=preload; as=image`];
                res.setHeader('Link', preloads);
            }
        }
    };
}
