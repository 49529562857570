import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import processPricingPath from '@/utilities/processPricingPath';

import { srpDuckPreset } from '@/ducks/presets';

import CrawlPathModelYearsFetcher from '@/fetchers/CrawlPathModelYearsFetcher';
import CrawlPathTrimsFetcher from '@/fetchers/CrawlPathTrimsFetcher';
import ListingCountFetcher from '@/fetchers/ListingCountFetcher.js';
import YmmModelInfoFetcher from '@/fetchers/srp/YmmModelInfoFetcher';

const srpSEOPricingDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'pricing',
    initialState: {
        modelYearsData: [],
        trimsData: [],
    },
}).extend({
    creators: () => ({
        loadModelYearsData: () => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;
            const modelYearsCrawlPathData = CrawlPathModelYearsFetcher(query);
            const { links = [] } = await modelYearsCrawlPathData;

            const updatedModelYearLinks = await processPricingPath({
                data: links,
                query,
                isModelYearData: true,
            });

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                modelYearsData: updatedModelYearLinks,
            }));
        },
        loadTrimsData: () => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;
            const trimCrawlPathData = CrawlPathTrimsFetcher(query);
            const { links = [] } = await trimCrawlPathData;

            const updatedTrimLinks = await processPricingPath({
                data: links,
                query,
                isModelYearData: false,
            });

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                trimsData: updatedTrimLinks,
            }));
        },
        loadSafetyInfo: () => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;

            const currentYear = new Date().getFullYear();
            let queryYear = query?.startYear;

            if (!query?.startYear) {
                queryYear = currentYear - 1;
            }

            const params = {
                makeCode: query?.makeCode,
                modelCode: query?.modelCode,
                startYear: queryYear,
            };

            const safetyInfoData = await YmmModelInfoFetcher(params);

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                safetyInfo: safetyInfoData,
            }));
        },
        loadGreatPriceListingCount: () => async (dispatch, getState) => {
            const state = getState();
            const query = {
                ...state?.query,
                searchRadius: 0,
                dealType: 'greatprice',
            };

            const greatPriceListingCount = await ListingCountFetcher(query);

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                greatPriceListingCount,
            }));
        },
    }),
    selectors: () => ({
        getModelYears: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ modelYearsData }) => modelYearsData,
        )),
        getTrims: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ trimsData }) => trimsData,
        )),
        getSafetyInfo: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ safetyInfo }) => safetyInfo,
        )),
        getGreatPriceListingCount: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ greatPriceListingCount }) => greatPriceListingCount,
        )),
    }),
});

export default srpSEOPricingDuck;
