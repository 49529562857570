import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Chip } from 'reaxl';
import { useFeatures } from 'reaxl-features';

// axl

// atc
import { srpSponsorshipDuck } from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const renderSponsorshipChip = ({ handleSponsorshipClick }) => (
    <Chip
        className="margin-bottom-3 margin-top-1 text-size-400 text-bold btn-primary"
        data-cmp="sponsorshipChip"
        label="Viewing Penske Inventory"
        onClick={handleSponsorshipClick}
    />
);

/*
 * Container for the filter selections that the user will be able to remove one at a time
 */
export function SearchResultsChipsContainer() {
    const navigateToSrp = useSrpNavigation();
    const dispatch = useDispatch();
    const isSponsorshipSelected = useSelector((state) => !!srpSponsorshipDuck.selectors.getDuckState(state));

    const {
        SPONSORSHIP_RESULTS_ONLY: [isSponsorshipActive],
    } = useFeatures(['SPONSORSHIP_RESULTS_ONLY']);

    const handleSponsorshipClick = useCallback(() => {
        dispatch(srpSponsorshipDuck.creators.setFalse());
        // push new instance of srp to history
        navigateToSrp();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (!isSponsorshipActive || !isSponsorshipSelected) {
        return null;
    }

    return renderSponsorshipChip({ handleSponsorshipClick });
}

export default React.memo(SearchResultsChipsContainer);
