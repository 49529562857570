import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function TruckBedLengthFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const truckBedLengthOptions = useSelector(srpFiltersDuck.selectors.getTruckBedLengthOptions);

    const truckBedLengthValue = useSelector(srpFiltersDuck.selectors.getTruckBedLengthValue);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterValues = {
        truckBedLength: truckBedLengthValue,
    };

    const filterName = 'truckBedLength';
    const title = getFilterTitle('Truck Bed Length', truckBedLengthValue);

    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(truckBedLengthOptions).length !== 0;

    // expand the panel if active options inside of it
    useEffect(() => {
        if (truckBedLengthValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [truckBedLengthValue.length]);

    return !!truckBedLengthOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="truckBedLengthFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    withAccordion={false}
                    filter={truckBedLengthOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    errors={filtersErrors}
                />
            )}
        </AccordionPanel>
    );
}

export default TruckBedLengthFilter;
