export default [
    'CHEXP1500', // Chevrolet Express 1500
    'CHEXP2500',
    'CHEXP3500',
    'CHEXP4500',
    'CH3500PU', // Chevrolet Silverado 3500
    'CHEVLCF', // Chevrolet Low Cab Forward
    'KODC4500', // Chevrolet Kodiak C4500
    'CHEKOD5500', // Chevrolet Kodiak C5500
    'F350', // Ford F350
    'F450',
    'FORDF550',
    'FORDF650',
    'FORDF750',
    'TRANSCONN', // Ford Transit connect
    'FORDTRN250', // Ford Transit 250
    'FORDTRN350', // Ford Transit 350
    'FRESPRINT', // Freightliner sprinter
    'GMCSAV3500', // GMC Savana 3500
    'GMCSAV4500', // GMC Savana 4500
    'GMC3500PU', // GMC Sierra 3500
    'ISUNPR', // Isuzu NPR
    'ISUFTR', // Isuzu FTR
    'ISUFVR', // Isuzu FVR
    'RM4500', // Ram 4500
    'RM5500', // Ram 5500
    'RMPROMAST', // Ram ProMaster
    'RMPROCITY', // Ram ProMaster City
    'MBSPRINTER', // Mercedes-Benz Sprinter
];
