import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { pageNames } from '@atc/bonnet-paths';

import { getPixallId } from 'atc-js';

import { DFPProvider } from 'reaxl-ads';
import { useFeatures } from 'reaxl-features';
import { getActiveExperiments, useOptimizelyFeature } from 'reaxl-optimizely';

import CacheKey from '@/utilities/CacheKey';

import { birfDuck, globalConfigsDuck } from '@/ducks';

import { srpFiltersDuck, srpNoSearchResultsDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

import { dataLayerTransform } from '@/transformers';

const { HOMEPAGE, SEARCH_RESULTS, VEHICLE_DETAILS } = pageNames;

const getExperimentVariants = (userInfo, activeExperiments) => {
    const optimizelyExperimentKeys = getActiveExperiments();
    const peActiveExperiments = activeExperiments.replace(/,/g, '|');

    return optimizelyExperimentKeys ? optimizelyExperimentKeys + '|' + peActiveExperiments : peActiveExperiments;
};

function AdsProviderContainer({
    pageName,
    excludeHeaderBidding,
    adSelector,
    children,
}) {
    const {
        ads: [isAdsFeatureEnabled, { prebid_url: prebidUrl }],
        lazy_ads: [isLazyAdsEnabled, { delay, pubads_lazy_load: pubadsLazyLoad }],
    } = useFeatures(['ads', 'lazy_ads']);

    const disablePageAdsKey = `DISABLE_${pageName.toUpperCase()}_ADS`;

    const {
        ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER: [enableAdLibrariesLoadFromServer],
        ENABLE_HEADER_BIDDING_PREBID: [enableHeaderBiddingPrebid],
        ENABLE_OPENWRAP_HP: [enableOpenwrapHP],
        ENABLE_OPENWRAP_VDP: [enableOpenwrapVDP],
        ENABLE_OPENWRAP_ALL: [enableOpenwrapAll],
        ENABLE_OPENWRAP_OTHER: [enableOpenwrapOther],
        [disablePageAdsKey]: [disablePageAds],
    } = useFeatures([
        'ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER',
        'ENABLE_HEADER_BIDDING_PREBID',
        'ENABLE_OPENWRAP_HP',
        'ENABLE_OPENWRAP_VDP',
        'ENABLE_OPENWRAP_ALL',
        'ENABLE_OPENWRAP_OTHER',
        disablePageAdsKey,
    ]);

    const adsEnabled = useSelector(globalConfigsDuck.selectors.getAdsEnabled);
    const openWrapUrl = useSelector(globalConfigsDuck.selectors.getOpenWrapUrl);
    const openWrapGlobalUrl = useSelector(globalConfigsDuck.selectors.getOpenWrapGlobalUrl);
    const userInfo = useOptimizelyFeature().getUserInfo();
    const filterCondition = useSelector(srpFiltersDuck.selectors.getListingTypeValues) || [];
    const vdpListingType = useSelector((state) => _get(vdpResultsDuck.selectors.getVdpActiveInventory(state), 'listingType', '').toLowerCase());
    const { adUnit, adsLazyLoadingOffset, pageLevelTargeting, prebidAdapters } = useSelector(adSelector, _isEqual) || {};

    const getTargetIntent = () => {
        let intent = 'nl';
        if (pageName === SEARCH_RESULTS) {
            if (filterCondition.length > 0) {
                // certified should pass if either or both “Manufacturer Certified” or “Third-Party Certified” selected
                if (filterCondition.includes('3P_CERT') && filterCondition.includes('CERTIFIED')) {
                    const splicedCondition = filterCondition.toSpliced(filterCondition.indexOf('3P_CERT'), 1);
                    intent = splicedCondition.join().toLowerCase();
                } else {
                    intent = filterCondition.join().toLowerCase().replace('3p_cert', 'certified');
                }
            } else {
                intent = 'all';
            }
        } else if (pageName === VEHICLE_DETAILS) {
            intent = vdpListingType;
        }
        return intent;
    };

    const getOpenWrapUrl = () => {
        if (!excludeHeaderBidding && (enableOpenwrapAll || (enableOpenwrapHP && (pageName === HOMEPAGE))
            || (enableOpenwrapVDP && (pageName === VEHICLE_DETAILS)) || (pageName === SEARCH_RESULTS)
            || (enableOpenwrapOther && ((pageName !== HOMEPAGE) && (pageName !== VEHICLE_DETAILS) && (pageName !== SEARCH_RESULTS))))) {

            if (pageName === HOMEPAGE || pageName === VEHICLE_DETAILS) {
                return openWrapUrl;
            }

            // We purposefully don't serve the kbb openwrap script id here because it is a newer integration pattern
            // that we need to upgrade to.
            return openWrapGlobalUrl;
        }

        return null;
    };
    const pixallId = getPixallId() || 'nl';

    const disableProvider = !isAdsFeatureEnabled || !adsEnabled || disablePageAds;
    const targetingChangeWithoutRefreshAds = ['pixall_visitorid'];

    const pageInstanceId = useSelector(birfDuck.selectors.getPgInst);
    const showNoResults = useSelector(srpNoSearchResultsDuck.selectors.isShowNoResults);
    const expiredVdp = useSelector((state) => !(vdpResultsDuck.selectors.getVdpActiveInventory(state)?.id));
    const personalization = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization, _isEqual);
    const peActiveExperiments = useSelector(PersonalizationEngineModule.duck.selectors.selectExperimentsFormatted, _isEqual);
    const {
        siteSection = '',
        pageType = '',
        detailPageName = '',
    } = dataLayerTransform({ pageName, showNoResults, expiredVdp });

    const providerProps = !disableProvider ? {
        children,
        adsLazyLoadingOffset,
        adUnit,
        delay: isLazyAdsEnabled && delay,
        enableLazyLoad: isLazyAdsEnabled && pubadsLazyLoad,
        enableAdLibrariesLoading: !enableAdLibrariesLoadFromServer,
        pageLevelTargeting: {
            ...pageLevelTargeting,
            pixall_visitorid: pixallId,
            tid: getExperimentVariants(userInfo, peActiveExperiments),
            pginst: pageInstanceId,
            gasec: siteSection,
            gapg: pageType,
            gadpn: detailPageName,
            int: getTargetIntent(),
        },
        personalization,
        targetingChangeWithoutRefreshAds,
        loadPrebid: enableHeaderBiddingPrebid && !excludeHeaderBidding,
        prebidPath: CacheKey.add(prebidUrl, false),
        prebidAdapters,
        openWrapUrl: getOpenWrapUrl(),
    } : {};

    if (!disableProvider && isLazyAdsEnabled) {
        providerProps.delay = delay;
        providerProps.enableLazyLoad = pubadsLazyLoad;
    }

    return disableProvider ? children : (
        <DFPProvider
            {...providerProps}
        />
    );
}

export default React.memo(AdsProviderContainer, _isEqual);
