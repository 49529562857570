import interactionResponse from 'await-interaction-response';

export default async function handleClickableListing(event, callback, nextLink) {
    // Only do analytics and page functionality if the target was not explicitly the next/link
    // The child <H3> will be the event.target for any title link clicks
    if (!event.target.hasAttribute('data-next-link')) {

        // target and find the child next/link in the listing
        nextLink = nextLink || event?.currentTarget?.querySelector('a[data-next-link]');

        await interactionResponse();
        callback(event);
        // Pre-emptively scroll to the top to avoid layout shift from items lower on the page
        // Do this after the callback, in case callback stores current scroll position in local storage
        window.scrollTo(0, 0);

        // if the event target has a next/link as a parent then we do not need to click
        // as the bubbled event will have handled that.
        if (!event.target.closest('[data-next-link]') && nextLink) {
            nextLink.click();
        }
    }
}
