import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchJSON } from '@bonnet/next/fetch';

import { useFeatures } from 'reaxl-features';
import { VrsServiceClient } from 'reaxl-wallet';

import { consumerRatingsDuck, queryDuck } from '@/ducks';

import VrsServiceFetcher from '@/fetchers/VrsServiceFetcher';

const vrsServices = new VrsServiceClient({ vrsFetcher: VrsServiceFetcher });

function getYear({ startYear, endYear }) {
    // If no year is selected then use current year
    if (!startYear && !endYear) {
        return new Date().getFullYear();
    }

    if (startYear === endYear) {
        return startYear;
    }

    return false;
}

async function fetchConsumerRatings({ query, dispatch }) {
    const { makeCode, modelCode, makeCodeList, modelCodeList, startYear, endYear } = query;

    try {
        const yearId = getYear({ startYear, endYear });

        const querystring = `?yearId=${yearId}&atcMakeCode=${makeCode || makeCodeList}&atcModelCode=${modelCode || modelCodeList}`;
        const { items } = await fetchJSON(`/cars-for-sale/kbbresearch/reference/vehicles${querystring}`, {
            circuitBreaker: {
                id: 'kbb-ref-vehicles',
                timeout: 5000,
                resetTimeout: 300000,
                fallback: {},
            },
        });
        const kbbVehicleId = items?.[0]?.vehicleId || null;

        // Fetch consumer ratings
        let ratings;
        if (kbbVehicleId) {
            (ratings = await vrsServices.getConsumerRatings(kbbVehicleId));
        }

        // Add ratings to redux
        // NOTE: Need to check ratings for undefined/null values to ensure that Object.keys does not throw "TypeError: Cannot convert undefined or null to object"
        if (ratings && Object.keys(ratings)?.length) {
            dispatch(consumerRatingsDuck?.creators?.setKeys(ratings));
        }

    } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }
}

const shouldUpdateRatings = ({ query }) => {
    const {
        startYear,
        endYear,
        makeCode,
        modelCode,
        trimCode,
        listingType,
        sellerType,
        vehicleStyleCode,
        driveGroup,
        engineCode,
        fuelTypeGroup,
        minPrice,
        maxPrice,
        mpgRange,
    } = query;

    let nonUniqueCanonical = true;

    if (listingType && !Array.isArray(listingType) && (listingType === 'NEW' || listingType === 'CERTIFIED')) {
        nonUniqueCanonical = false;
    }

    if (sellerType && !Array.isArray('sellerType') && sellerType[0] === 'p') {
        nonUniqueCanonical = false;
    }

    if ((vehicleStyleCode && !Array.isArray(vehicleStyleCode)) || (driveGroup && !Array.isArray(driveGroup))
        || (engineCode && !Array.isArray(engineCode)) || (fuelTypeGroup && !Array.isArray(fuelTypeGroup))) {
        nonUniqueCanonical = false;
    }

    if ((minPrice && !maxPrice) || (maxPrice && !minPrice)) {
        nonUniqueCanonical = false;
    }

    if (mpgRange) {
        nonUniqueCanonical = false;
    }

    // make & model validations
    const hasMultipleSelections = (key) => Array.isArray(query[key]) && query[key].length > 1;
    const hasSingleModel = !!modelCode && (!hasMultipleSelections('makeCode') || !hasMultipleSelections('modelCode'));
    const hasSingleMake = !!makeCode && !hasMultipleSelections('makeCode');

    // yearId validations
    const yearId = getYear({ startYear, endYear });

    // only make and model are selected and has non unique canonical url
    return (hasSingleModel && hasSingleMake && yearId && !trimCode && nonUniqueCanonical);
};

export default async function useConsumerRatings() {
    const dispatch = useDispatch();
    const {
        consumer_ratings: [enableConsumerRatings, { srpRatings: enableSrpRatings }],
    } = useFeatures([
        'consumer_ratings',
    ]);
    const query = useSelector(queryDuck.selectors.getDuckState);

    useEffect(() => {
        if (enableConsumerRatings && enableSrpRatings) {
            if (shouldUpdateRatings({ query })) {
                fetchConsumerRatings({ query, dispatch });
            } else {
                dispatch(consumerRatingsDuck?.creators?.reset());
            }
        }
    }, [dispatch, enableConsumerRatings, enableSrpRatings, query]);

}
