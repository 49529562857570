import React, { memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import MaskedTextInput from 'react-text-mask';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
    AccordionPanel,
    DebouncedInput,
    ErrorMessages,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { FilterTitle } from 'reaxl-filters';

import { srpFiltersDuck } from '@/ducks/srp';

import MyWalletMonthlyPaymentFilterContainer from '@/containers/srp/MyWalletMonthlyPaymentFilterContainer';

import FilterPlaceholder from './FilterPlaceholder';

const stripNonDigits = (value) => (value ? value.toString().replace(/\D/g, '') : null);
const getNumericValue = (value) => (value ? Number.parseInt(stripNonDigits(value), 10) : '');

const PriceFilter = memo(({ onOptionChange }) => {

    const {
        my_wallet: [enableMyWallet],
    } = useFeatures(['my_wallet']);

    const priceOptions = useSelector(srpFiltersDuck.selectors.getPriceOptions);
    const minPriceOption = useSelector(srpFiltersDuck.selectors.getMinPriceOptions);
    const maxPriceOption = useSelector(srpFiltersDuck.selectors.getMaxPriceOptions);
    const minPriceValue = useSelector(srpFiltersDuck.selectors.getMinPriceValue);
    const maxPriceValue = useSelector(srpFiltersDuck.selectors.getMaxPriceValue);

    const priceFilter = { priceOptions, minPrice: minPriceOption, maxPrice: maxPriceOption };

    const filterName = 'priceRange';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(minPriceOption).length !== 0 && Object.keys(maxPriceOption).length !== 0;

    const [state, setState] = useState({
        minPrice: getNumericValue(minPriceValue),
        maxPrice: getNumericValue(maxPriceValue),
        errors: undefined,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            minPrice: getNumericValue(minPriceValue),
            maxPrice: getNumericValue(maxPriceValue),
        }));
    }, [minPriceValue, maxPriceValue]);

    const isValid = (updatedValues) => {
        const { minPrice, maxPrice } = {
            ...state,
            ...updatedValues,
        };

        let errors;

        if (minPrice && maxPrice) {
            if (minPrice === maxPrice) {
                return 'Min and Max price cannot be the same.';
            }
            if (minPrice > maxPrice) {
                return 'Max price must be greater than Min price.';
            }
        }

        if (maxPrice === 0) {
            return 'Max price must be greater than 0.';
        }

        return errors;
    };
    const handleBlur = (event) => {

        // we need to persist the original event to reuse later
        event.persist();
        const valueInState = getNumericValue(state[event.target.name]);
        const value = getNumericValue(event.target.value);

        // Update State with new value if the value has been updated
        if (valueInState !== value) {
            const errors = isValid({ [event.target.name]: value });
            if (errors) {
                setState((prevState) => ({
                    ...prevState,
                    errors,
                    [event.target.name]: null,
                }));
                setTimeout(() => {
                    event.target.value = '';
                });
            } else {
                const syntheticEvt = { ...event };
                syntheticEvt.target.value = value;
                setState((prevState) => ({
                    ...prevState,
                    errors: undefined,
                    [event.target.name]: value,
                }));
                onOptionChange(syntheticEvt, priceFilter[event.target.name]);
            }
        }
    };

    const handleKeyPress = ({ key, target }) => {
        // If Enter key is pressed then we need to blur to kick off the option change
        if (key.toLowerCase() === 'enter') {
            target.blur();
        }
    };

    const title = (
        <FilterTitle
            key="priceRangeTitle"
            title="Price & Payments"
        />
    );

    const numberMask = createNumberMask({
        prefix: '$',
        includeThousandsSeparator: true,
        allowDecimal: false,
        requireDecimal: false,
        allowLeadingZeroes: false,
    });

    const commonInputProps = {
        type: 'tel',
        minLength: '1',
        maxLength: '10',
        pattern: '[0-9,$]{1,10}',
        component: MaskedTextInput,
        mask: numberMask,
        guide: false,
        onBlur: handleBlur,
        onKeyPress: handleKeyPress,
        className: 'form-control',
    };

    const {
        placeholder: minPricePlaceholder = 'Ex:10,000',
        name: minPriceName = 'minPrice',
        label: minPriceLabel = 'Min Price',
    } = minPriceOption;

    const {
        placeholder: maxPricePlaceholder = 'Ex:20,000',
        name: maxPriceName = 'maxPrice',
        label: maxPriceLabel = 'Max Price',
    } = maxPriceOption;

    return (
        <AccordionPanel
            data-cmp="filterPriceRange"
            eventKey={filterName}
            title={title}
            contentPadding={5}
        >
            { !showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            { showFilter && (
                <>
                    <div className="row form-group margin-bottom-0">
                        <div className="col-xs-6">
                            <DebouncedInput
                                {...commonInputProps}
                                placeholder={minPricePlaceholder}
                                name={minPriceName}
                                label={minPriceLabel}
                                value={state.minPrice}
                            />
                        </div>
                        <div className="col-xs-6">
                            <DebouncedInput
                                {...commonInputProps}
                                placeholder={maxPricePlaceholder}
                                name={maxPriceName}
                                label={maxPriceLabel}
                                value={state.maxPrice}
                            />
                        </div>
                    </div>
                    <ErrorMessages errors={state.errors} />
                    {enableMyWallet && <MyWalletMonthlyPaymentFilterContainer />}
                </>
            )}
        </AccordionPanel>
    );
});

export default PriceFilter;
