import React, { memo, useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import get from 'lodash/get';

import { Button } from 'reaxl';
import { sendClick } from 'reaxl-analytics';

import getCpoByMake from '@/utilities/getCpoByMake';

import { cpoFilterTileClick } from '@/analytics/srpAnalyticsHandlers';

import { srpFiltersDuck } from '@/ducks/srp';

import certifiedSponsorFetcher from '@/fetchers/srp/certifiedSponsorFetcher';

import CertifiedPreOwnedModal from '@/components/CertifiedPreOwnedModal/CertifiedPreOwnedModal';

function MMTFilterCpoCTA({ label, cpoData }) {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    let filterMakeSelected = get(filtersValues, 'makeCode', []);
    filterMakeSelected = Array.isArray(filterMakeSelected) ? filterMakeSelected : [filterMakeSelected];

    const cpoFilterLabel = `Learn about ${label} Certified Pre-Owned`;

    const cpoByMake = useMemo(() => getCpoByMake(filterMakeSelected, cpoData), [filterMakeSelected, cpoData]);

    const handleShowModal = useCallback(async ({ event, productId, makeCode, makeLabel }) => {
        sendClick(cpoFilterTileClick, event, { cmp: `text-link::${cpoFilterLabel.replace(/\s+/g, '-').toLowerCase()}`, makeCode });

        try {
            const certifiedData = await certifiedSponsorFetcher({ productId });
            delete certifiedData.inspectionLink;
            setModalData({ certifiedData, makeLabel, makeCode });
            setShowModal(true);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching certifiedSponsor:', error);
        }
    }, [cpoFilterLabel]);

    const handleLinkClick = useCallback(async (e) => {
        const matchingLink = Object.values(cpoByMake).find((link) => link.makeLabel === label);

        if (matchingLink) {
            await handleShowModal({
                event: e,
                productId: matchingLink.productId,
                makeCode: matchingLink.makeCode,
                makeLabel: matchingLink.makeLabel,
            });
        } else {
            // eslint-disable-next-line no-console
            console.warn(`No matching link found for label: ${label}`);
        }
    }, [cpoByMake, handleShowModal, label]);

    return (
        <div className="margin-bottom-3">
            <Button
                onClick={handleLinkClick}
                bsStyle="text"
            >
                {cpoFilterLabel}
            </Button>
            <CertifiedPreOwnedModal
                showModal={showModal}
                handleCloseModal={() => setShowModal(false)}
                data={modalData}
                filtersValues={filtersValues}
            />
        </div>
    );
}

export default memo(MMTFilterCpoCTA);
