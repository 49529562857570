import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
    AccordionPanel,
} from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

/**
*  VehicleHistoryTypeFilter presents the user with the ability to filter by vehicle history attributes
*/
function VehicleHistoryTypeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {

    const vehicleHistoryValues = useSelector(srpFiltersDuck.selectors.getVehicleHistoryValues);
    const vehicleHistoryOptions = useSelector(srpFiltersDuck.selectors.getVehicleHistoryOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        vehicleHistoryType: vehicleHistoryValues,
    };

    const filterName = 'vehicleHistoryType';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Vehicle History', filterValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (vehicleHistoryValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleHistoryValues.length]);

    const vhrFilter = (
        <FilterCheckboxes
            filter={vehicleHistoryOptions}
            onOptionChange={onOptionChange}
            onOptionsClear={onOptionsClear}
            values={filterValues}
            errors={filtersErrors}
            withAccordion={false}
        />
    );

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="VehicleHistoryFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && vhrFilter}
        </AccordionPanel>
    );
}

export default VehicleHistoryTypeFilter;
