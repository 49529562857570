import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
    AccordionPanel,
} from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

/**
*  VehicleUseTypeFilter presents the user with the ability to filter by vehicle history attributes
*/
function VehicleUseTypeFilter({
    onOptionChange = () => {},
    onOptionsClear = () => {},
    handleShowExpansion = () => {},
}) {
    const vehicleUseTypeValues = useSelector(srpFiltersDuck.selectors.getVehicleUseTypeValues);
    const vehicleUseTypeOptions = useSelector(srpFiltersDuck.selectors.getVehicleUseTypeOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        vehicleUseType: vehicleUseTypeValues,
    };

    const filterName = 'vehicleUseType';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Vehicle Use Type', filterValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (vehicleUseTypeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleUseTypeValues.length]);

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="VehicleUseTypeFilter"
            title={title}
            contentPadding={5}
        >
            { !showFilter && <FilterPlaceholder uniqueKey={filterName} /> }
            { showFilter && (
                <FilterCheckboxes
                    filter={vehicleUseTypeOptions}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    values={filterValues}
                    errors={filtersErrors}
                    withAccordion={false}
                />
            )}
        </AccordionPanel>
    );
}

export default VehicleUseTypeFilter;
