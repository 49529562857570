import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { usePrevious } from '@atc/use-previous';

import {
    Button,
    Checkbox,
    Modal,
    ModalHeader,
} from 'reaxl';

function SuggestedFiltersModal(props) {

    const [isShowSuggestedModalsChecked, setIsShowSuggestedModalsChecked] = useState(false);
    const [show, setShow] = useState(true);

    const {
        makeModelDescription,
        models,
        onHide = () => { },
        onSuggestedFiltersAgree = () => { },
        onSuggestedFiltersDisagree = () => { },
        onToggleShowOption = () => { },
    } = props;

    const prevProps = usePrevious(props);

    useEffect(() => {
        if (prevProps && prevProps.makeModelDescription !== makeModelDescription) {
            setIsShowSuggestedModalsChecked(false);
            setShow(true);
        }
    }, [prevProps, makeModelDescription]);

    const handleClose = () => {
        onHide();
        setShow(false);
    };

    const handleAgree = () => {
        onSuggestedFiltersAgree();
        handleClose();
    };

    const handleDisagree = () => {
        onSuggestedFiltersDisagree();
        handleClose();
    };

    const toggleShowSuggested = () => {
        const checkedValue = !isShowSuggestedModalsChecked;
        setIsShowSuggestedModalsChecked(checkedValue);
        onToggleShowOption(checkedValue);
    };

    const renderSuggestedFilter = ({ index, model }) => (
        <div key={index}>
            Some years of the
            {' '}
            {model.selectedMakeDescription}
            {' '}
            {model.selectedModelDescription}
            {' '}
            are named the
            {' '}
            {model.suggestedMakeDescription}
            {' '}
            {model.suggestedModelDescription}
            .
        </div>
    );

    const renderSuggestedFilters = () => models.map((model, index, array) => renderSuggestedFilter({ index, model }));

    return (
        <Modal
            data-cmp="suggestedFiltersModal"
            show={show}
            onHide={handleClose}
        >
            <ModalHeader closeButton>
                <Modal.Title>
                    Model Name Clarification
                </Modal.Title>
            </ModalHeader>

            <Modal.Body>

                {renderSuggestedFilters()}

                <p className="margin-vertical-4">
                    Do you want us to include
                    {' '}
                    {makeModelDescription}
                    {' '}
                    in your results?
                </p>
                <Checkbox
                    qaid="input-doNotShowSuggested"
                    name="doNotShowSuggested"
                    onChange={toggleShowSuggested}
                    label="Don't show me this again."
                    checked={isShowSuggestedModalsChecked}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleDisagree}
                    type="submit"
                >
                    No
                </Button>
                <Button
                    onClick={handleAgree}
                    bsStyle="primary"
                    type="submit"
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

SuggestedFiltersModal.propTypes /* remove-proptypes */ = {
    /**
    * The array of suggested model objects
    */
    models: PropTypes.array.isRequired,
    /**
    * Suggested Make Model Description text for modal
    */
    makeModelDescription: PropTypes.string.isRequired,
    /**
     * callback called when user agrees to
     * combining listed suggested modals
     */
    onSuggestedFiltersAgree: PropTypes.func.isRequired,
    /**
     * callback called when user disagrees to
     * combining listed suggested modals
     */
    onSuggestedFiltersDisagree: PropTypes.func,
    /**
      * callback called when user changes status
      * of whether to be prompted again
      */
    onToggleShowOption: PropTypes.func.isRequired,
    /**
     * callback called when modal is dismissed
     */
    onHide: PropTypes.func,
};

export default SuggestedFiltersModal;
