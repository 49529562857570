import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function TransmissionFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const transmissionValues = useSelector(srpFiltersDuck.selectors.getTransmissionCodeValues);
    const transmissionOptions = useSelector(srpFiltersDuck.selectors.getTransmissionCodeOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        transmissionCode: transmissionValues,
    };

    const filterName = 'transmissionCode';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Transmission', transmissionValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (transmissionValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transmissionValues.length]);

    return !!transmissionOptions.options?.length
        && (
            <AccordionPanel
                eventKey={transmissionOptions.name}
                data-cmp="TransmissionFilter"
                title={title}
                contentPadding={5}
            >
                {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
                {showFilter && (
                    <FilterCheckboxes
                        key="TransmissionCheckboxes"
                        filter={transmissionOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        values={filterValues}
                        errors={filtersErrors}
                        withAccordion={false}
                    />
                )}
            </AccordionPanel>
        );
}

export default TransmissionFilter;
