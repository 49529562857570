import { getReference } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

const getAcceptedTrims = async (makeCodes, modelCodes) => {
    const modelPromises = [];
    const acceptedTrims = [];
    const makeCodeList = Array.isArray(makeCodes) ? makeCodes : [makeCodes];
    const modelCodeList = Array.isArray(modelCodes) ? modelCodes : [modelCodes];

    for (const makeCode of makeCodeList) {
        const payload = getReference('modelCode', { makeCode });
        modelPromises.push(payload);
    }

    const modelResponse = await Promise.all(modelPromises);

    const modelData = modelResponse.reduce((data, curr = {}) => {
        const payload = curr.payload || [];
        data.push(...payload);

        return data;
    }, []);

    for (const model of modelData) {
        if (modelCodeList.includes(model?.code)) {
            const trims = model?.trims?.map((trimData) => trimData.code) || [];

            acceptedTrims.push(...trims);
        }
    }

    return acceptedTrims;

};

export default function withSrpQueryFixes() {
    return async (ctx) => {
        // Default Listing Type to USED if there is no
        // listing type or allListingType parameters specified
        if (ctx.data.brand === brands.ATC_BRAND && !ctx.query.allListingType && !ctx.query.listingType) {
            ctx.query.listingType = 'USED';
        }

        // validate trimCodeList param
        if (ctx.query.trimCode) {
            const trimCodeList = Array.isArray(ctx.query.trimCode) ? ctx.query.trimCode : [ctx.query.trimCode];
            const sanitizedTrims = [];

            if (ctx.query.makeCode && ctx.query.modelCode) {
                const acceptedTrims = await getAcceptedTrims(ctx.query.makeCode, ctx.query.modelCode);

                for (const trimCode of trimCodeList) {
                    if ((acceptedTrims.includes(trimCode.split('|')[1]))) {
                        sanitizedTrims.push(trimCode);
                    }
                }
            }

            ctx.query.trimCode = sanitizedTrims.length === 1 ? sanitizedTrims[0] : sanitizedTrims;
        }

        if (ctx.query.lastExec) {
            delete ctx.query.lastExec;
        }

        // Temporary fix for ancient enumerated makeCode/modelCode/mmt
        let allMakeCodes = [];
        allMakeCodes.push(ctx.query.makeCodeList);

        if (ctx.query.makeCode1) {
            allMakeCodes.push(ctx.query.makeCode1);
            delete ctx.query.makeCode1;
        }
        if (ctx.query.makeCode2) {
            allMakeCodes.push(ctx.query.makeCode2);
            delete ctx.query.makeCode2;
        }
        if (ctx.query.makeCode3) {
            allMakeCodes.push(ctx.query.makeCode3);
            delete ctx.query.makeCode3;
        }
        allMakeCodes = allMakeCodes.flat().filter(Boolean);
        if (allMakeCodes.length) {
            ctx.query.makeCodeList = allMakeCodes.length > 1 ? allMakeCodes : allMakeCodes.toString();
        }

        let allModelCodes = [];
        allModelCodes.push(ctx.query.modelCodeList);
        if (ctx.query.modelCode1) {
            allModelCodes.push(ctx.query.modelCode1);
            delete ctx.query.modelCode1;
        }
        if (ctx.query.modelCode2) {
            allModelCodes.push(ctx.query.modelCode2);
            delete ctx.query.modelCode2;
        }
        if (ctx.query.modelCode3) {
            allModelCodes.push(ctx.query.modelCode3);
            delete ctx.query.modelCode3;
        }
        if (ctx.query?.showcaseOwnerId && ctx.query?.showcaseOwnerId.length > 1) {
            ctx.query.showcaseOwnerId = ctx.query.showcaseOwnerId[0];
        }
        allModelCodes = allModelCodes.flat().filter(Boolean);
        if (allModelCodes.length) {
            ctx.query.modelCodeList = allModelCodes.length > 1 ? allModelCodes : allModelCodes.toString();
        }

        if (ctx.query.mmt) {
            delete ctx.query.mmt;
        }
    };
}
