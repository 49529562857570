import { getPath, pageNames } from '@atc/bonnet-paths';

const { BUILD_PRICE } = pageNames;

/**
     * * This function handles building BAP referrer
     *
     * @param makeCode      - required
     * @param modelCode     - required
     * @param ownerId       - optional, use to fetch initial owner data
     * @param trim          - optional
     * @param useReferrer   - optional
     * @param year          - optional
     * @param zip           - optional, use to fetch all owner data
     * @returns {Promise<void>}
     */
const buildBapReferrer = async ({
    makeCode,
    modelCode,
    ownerId = '',
    trim = '',
    useReferrer = true,
    year = '',
    zip,
}) => {
    const delimiter = 'referrer';

    const bapUrl = await getPath(BUILD_PRICE, {
        makeCode,
        modelCode,
        ownerId,
        trim,
        year,
        zip,
    }, {
        referrer: useReferrer,
    });

    return bapUrl.split(delimiter).length > 1
        ? (`&${delimiter}${bapUrl.split(delimiter)[1]}`)
        : '';
};

export default buildBapReferrer;
