const adUnits = {
    atcFindcar: '/18353239/atc/findcar',
    atcFindcarNonProd: '/18353239/testatc/findcar',
    atcFixedAdId: 'fixedAdMobile',
    atcHeaderAd: {
        slotId: 'headerAd',
        size: [[728, 90]],
        targeting: {
            pos: 'a',
            prd: 'dlr',
        },
    },
    kbbFixedAdId: 'kbbAdsLeaderboard',
    kbbLeaderboardAdVDP: {
        path: '/18353239/kbb/findcar/vdp',
        slotId: 'kbbLeaderboardAdVDP',
        size: [[728, 90]],
        targeting: {
            prd: 'dlr',
        },
        lazyLoading: true,
    },
    kbbClassified: '/3030/kbb/classified',
    atcMrecAdVDP: {
        adSlotId: 'VDP300x252ad',
        adPos: 'b',
    },
    kbbMrecAdVDP: {
        adSlotId: 'kbbAdsMedRec',
        adPos: '2',
    },
};

export default adUnits;
