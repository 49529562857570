import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function SedanSizeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const sedanSizeSubtypeOptions = useSelector(srpFiltersDuck.selectors.getSedanSizeSubtypeOptions);
    const sedanSizeSubtypeValue = useSelector(srpFiltersDuck.selectors.getSedanSizeSubtypeValue);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterValues = {
        sedanSubtypeCode: sedanSizeSubtypeValue,
    };

    const title = getFilterTitle('Sedan Type', sedanSizeSubtypeValue);
    const filterName = 'sedanSizeSubtypeFilter';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(sedanSizeSubtypeOptions).length !== 0;

    useEffect(() => {
        if (sedanSizeSubtypeValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sedanSizeSubtypeValue.length]);

    return !!sedanSizeSubtypeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="sedanSizeSubtypeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    withAccordion={false}
                    filter={sedanSizeSubtypeOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    errors={filtersErrors}
                />
            )}
        </AccordionPanel>
    );
}

export default SedanSizeFilter;
