import React from 'react';

import { useSelector } from 'react-redux';

import {
    Button,
    Image,
} from 'reaxl';
import {
    sendClick,
} from 'reaxl-analytics';
import {
    buyOnlineButton,
} from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function BuyOnlineButtonContainer() {
    const {
        hyundai_buy_online: [enableHyundaiBuyOnline],
        srp_hero: [{
            cmp: buyOnlineCmp,
        }],
    } = useFeatures(['hyundai_buy_online', 'srp_hero']);

    const navigateToSrp = useSrpNavigation();

    const filterValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const makeValues = useSelector(srpFiltersDuck.selectors.getMakeFilterValues);
    const isSponsoredExperience = buyOnlineCmp === 'hyundai-buy-online';

    const handleButtonClick = (e) => {
        const updatedFilterValues = {
            ...filterValues,
        };

        if (isSponsoredExperience) {
            delete updatedFilterValues.experience;
        } else {
            Object.assign(updatedFilterValues, {
                experience: 'hyundai-buy-online',
            });
        }

        sendClick(buyOnlineButton, e, { sponsoredMake: 'hyundai', filterValues });

        navigateToSrp({
            filtersValues: updatedFilterValues,
            resetPagination: true,
        });
    };

    const shouldRenderButton = enableHyundaiBuyOnline && !isSponsoredExperience && makeValues.includes('HYUND');

    return shouldRenderButton && (
        <Button
            block
            bsStyle="secondary"
            onClick={handleButtonClick}
            data-cmp="btn-buy-online"
        >
            <Image
                alt="hyundai-logo"
                data-cmp="hyundai-srp-entry-point-icon"
                src="/content/static/img/oem/hyundai_logo.png"
            />
            <span className="padding-left-1"> Buy Online</span>
        </Button>
    );
}

export default BuyOnlineButtonContainer;
