import {
    srpFixedAdDuck,
} from '@/ducks/srp';

// find the fixed ad slot and refresh it with updated targeting values
const refreshFixedAd = ({ dispatch, refreshCount, slotId }) => {
    if (window?.googletag) {
        const adSlot = window.googletag.pubads().getSlots()
            .find((x) => x.getSlotId().getDomId() === 'div-gpt-ad-' + slotId);

        if (adSlot) {
            const updatedCount = refreshCount + 1;
            const visibility = document.visibilityState === 'visible' ? 'y' : 'n';

            adSlot.setTargeting('active_browser', visibility);
            adSlot.setTargeting('refresh', updatedCount);

            dispatch(srpFixedAdDuck.creators.setFixedAdRefresh({ refreshCount: updatedCount }));

            window.googletag.pubads().refresh([
                adSlot,
            ]);
        }
    }
};

export default refreshFixedAd;
