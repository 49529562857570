import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import { useDevice } from '@bonnet/next/device';

import { Image, Link } from 'reaxl';
import { Tip } from 'reaxl-molecules';

import getPathSRP from '@/utilities/getPathSRP';

import { queryDuck } from '@/ducks';

const renderEntryPointLink = (onClick, screenIsLessThanMd, path) => (
    <Link
        data-cmp="commercial-srp-entry-point-link"
        href={path}
        className={clsx('text-link link-undecorated', {
            'padding-right-2': screenIsLessThanMd,
        })}
        onClick={onClick}
    >
        Show commercial vehicles
    </Link>
);

const renderEntryPointIcon = (icon, onClick, path) => (
    <Link href={path}>
        <Image
            alt="commercial-vehicle-image"
            className="cursor-pointer"
            data-cmp="commercial-srp-entry-point-icon"
            onClick={onClick}
            src={icon}
            width="118"
        />
    </Link>
);

// Commercial SRP Entry Point Filter Component
function CommercialSrpFilterEntryPoint({
    className = '',
    icon,
    onCommercialEntryPointClick = () => { },
}) {

    const device = useDevice();
    const screenIsLessThanMd = device?.lessThan.md;

    const query = useSelector(queryDuck.selectors.getDuckState);
    const [commercialPath, setCommercialPath] = useState('');

    useEffect(() => {
        const commercialQuery = { ...query };

        delete commercialQuery.makeCode;
        delete commercialQuery.modelCode;
        delete commercialQuery.trimCode;
        delete commercialQuery.vehicleStyleCode;
        delete commercialQuery.zip;
        delete commercialQuery.state;
        delete commercialQuery.city;

        const generateCommercialSrpPath = async () => {
            const path = await getPathSRP({ ...commercialQuery, experience: 'commercial' }, { basePath: true });
            setCommercialPath(path);
        };

        if (!_isEmpty(commercialQuery)) {
            generateCommercialSrpPath();
        }
    }, [query]);

    const tipBody = (
        <div className={clsx('display-flex align-items-center', {
            'flex-column': device?.mediaType === 'md',
        })}
        >
            {renderEntryPointLink(onCommercialEntryPointClick, screenIsLessThanMd, commercialPath)}
            {renderEntryPointIcon(icon, onCommercialEntryPointClick, commercialPath)}
        </div>
    );

    return (
        <div
            className={className}
            data-cmp="commercial-srp-filter-entry-point"
        >
            <Tip
                body={tipBody}
                className="rounded padding-horizontal-4"
                borderColor="info-200"
                backgroundColor="info-100"
            />
        </div>
    );
}

export default CommercialSrpFilterEntryPoint;
