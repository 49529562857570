import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import { useRouter } from 'next/router';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { Heading } from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { ConsumerRatings } from 'reaxl-ratings-reviews';
import { Product as ProductSchema } from 'reaxl-schema';

import consumerRatingsDuck from '@/ducks/consumerRatingsDuck';
import { srpResultsDuck } from '@/ducks/srp';

function ConsumerRatingsContainer() {
    const consumerRatings = useSelector(consumerRatingsDuck.selectors.getConsumerRatings);
    const stats = useSelector(srpResultsDuck.selectors.getStats) || {};
    const { derivedprice } = stats;
    const inventory = useSelector((state) => _get(srpResultsDuck.selectors.getActiveInventory(state), '[0]', {}), _isEqual);

    const { brand: [, { base_url: baseUrl = '' }] } = useFeatures(['brand']);
    const router = useRouter();
    const currentPath = baseUrl.replace(/\/$/, '') + router.pathname;

    const ratings = consumerRatings?.ratings || [];
    const overallRating = ratings.find((rating) => rating.name === 'Overall Rating');
    const ratingsBreakdown = ratings.filter((rating) => rating.name !== 'Overall Rating');

    const schemaData = {
        derivedprice,
        inventory,
        rating: {
            count: consumerRatings.numberOfReviews,
            value: overallRating?.value,
        },
        url: currentPath,
    };

    return (
        <>
            <div
                id="consumer-ratings-container"
                className="container margin-vertical-4 padding-horizontal-2 padding-horizontal-sm-0"
            >
                <Heading level={4}>
                    KBB.com
                    <sup>&reg;</sup>
                    &nbsp;Consumer Reviews
                </Heading>
                <ConsumerRatings
                    overallRating={parseFloat(overallRating?.value)}
                    ratings={ratingsBreakdown}
                />
            </div>
            <ProductSchema
                {...schemaData}
            />
        </>
    );
}

export default React.memo(ConsumerRatingsContainer);
