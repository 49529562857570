import React, { useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    Toggle,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { myWalletFilterToggleClick } from 'reaxl-analytics-handlers';
import { WalletPopover } from 'reaxl-wallet';

import { paymentsDuck } from '@/ducks';

function MyWalletToggleContainer({
    withPopover,
    toggleAnalyticsData = {},
    label = 'Show Payments',
    id = 'wallet-toggle',
}) {

    const dispatch = useDispatch();

    const displayPaymentsOnListings = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const { displayModal = false } = useSelector(paymentsDuck.selectors.getMyWallet) || {};

    const {
        eventResult: toggleEventResult = 'toggle::open::show-payments',
    } = toggleAnalyticsData;

    const targetRef = useRef();

    const updatePaymentEmphasis = ({ toggleState }) => {
        dispatch(paymentsDuck.creators.updateMyWalletInteractions({
            paymentEmphasis: toggleState,
            disablePaymentPopover: true,
        }));
    };

    const toggleMyWalletModal = () => {
        dispatch(paymentsDuck.creators.updateMyWallet({ displayModal: !displayModal }));
    };

    const toggleWallet = useCallback((event) => {
        // get from userduck toggle and paymentInfo.
        if (displayPaymentsOnListings) {
            sendClick(myWalletFilterToggleClick, event, { toggleOn: false, eventResult: toggleEventResult });
            updatePaymentEmphasis({ toggleState: false });
        } else {
            sendClick(myWalletFilterToggleClick, event, { toggleOn: true, eventResult: toggleEventResult });
            updatePaymentEmphasis({ toggleState: true });
            toggleMyWalletModal();
        }
    }, [displayPaymentsOnListings, sendClick, toggleEventResult]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <span
                className="margin-right-2"
                id={id}
            >
                {label}
            </span>
            <div
                data-cmp="toggle-wallet"
                ref={targetRef}
                className="display-flex justify-content-center"
            >
                <Toggle
                    checked={displayPaymentsOnListings}
                    onChange={toggleWallet}
                    className="display-flex align-self-center"
                    ariaLabel={label}
                />
            </div>
            <WalletPopover
                ref={targetRef}
                paymentsDuck={paymentsDuck}
                withPopover={withPopover}
            />
        </>
    );
}

export default MyWalletToggleContainer;
