import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function TruckCabSizeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const bodyStyleSubtypeCodeOptions = useSelector(srpFiltersDuck.selectors.getBodyStyleSubtypeCodeOptions);
    const bodyStyleSubtypeCodeValue = useSelector(srpFiltersDuck.selectors.getBodyStyleSubtypeCodeValue);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterValues = {
        bodyStyleSubtypeCode: bodyStyleSubtypeCodeValue,
    };

    const title = getFilterTitle('Truck Cab Size', bodyStyleSubtypeCodeValue);
    const filterName = 'bodyStyleSubtypeCode';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(bodyStyleSubtypeCodeOptions).length !== 0;

    useEffect(() => {
        if (bodyStyleSubtypeCodeValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyStyleSubtypeCodeValue.length]);

    return !!bodyStyleSubtypeCodeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="truckCabSizeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    withAccordion={false}
                    filter={bodyStyleSubtypeCodeOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    errors={filtersErrors}
                />
            )}
        </AccordionPanel>
    );
}

export default TruckCabSizeFilter;
