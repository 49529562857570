import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function SUVSizeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const suvSizeSubtypeOptions = useSelector(srpFiltersDuck.selectors.getSuvSizeSubtypeOptions);
    const suvSizeSubtypeValue = useSelector(srpFiltersDuck.selectors.getSuvSizeSubtypeValue);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterValues = {
        suvSubtypeCode: suvSizeSubtypeValue,
    };

    const title = getFilterTitle('SUV Type', suvSizeSubtypeValue);
    const filterName = 'suvSizeFilter';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(suvSizeSubtypeOptions).length !== 0;

    useEffect(() => {
        if (suvSizeSubtypeValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suvSizeSubtypeValue.length]);

    return !!suvSizeSubtypeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="suvSizeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    withAccordion={false}
                    filter={suvSizeSubtypeOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    errors={filtersErrors}
                />
            )}
        </AccordionPanel>
    );
}

export default SUVSizeFilter;
