import { getPixallIdFromCookie } from 'atc-js';

import { brands } from 'reaxl-brand';

import cleanServerRequest from '@/utilities/cleanServerRequest';

export default function withRequestOptions() {
    return async (ctx) => {

        const headers = ctx.req ? ctx.req.headers : {};

        // TODO: BONNET - This should only be on KBB and Client side.
        if (ctx.data.brand === brands.KBB_BRAND) {
            headers['X-fwd-svc'] = 'atc';
        }

        // generate a fetch options object
        let options = {
            headers,
            query: ctx.query || {},
        };

        const {
            brand: [, {
                channel,
                excludedFilter,
                ignorePriority,
                relevanceConfig,
                vhrProviders,
            }],
            strong_attributes: [enableStrongAttributes],
            relevance: [customRelevanceEnabled, { relevanceVersion }],
        } = ctx.useFeatures([
            'brand',
            'relevance',
            'strong_attributes',
        ]);

        const pixAllId = getPixallIdFromCookie() || null;
        const derivedChannel = ctx.data.channel || channel;

        const selectedRelevance = customRelevanceEnabled ? relevanceVersion : relevanceConfig;
        const relevanceConfigValue = enableStrongAttributes ? selectedRelevance : 'relevance-v3';

        if (typeof window === 'undefined') {
            options = await cleanServerRequest(options);
        }

        options.query = {
            ...options.query,
            ...(derivedChannel && { channel: derivedChannel }),
            ...(excludedFilter && { excludedFilter }),
            ...(!!ignorePriority && { ignorePriority }),
            ...(relevanceConfigValue && { relevanceConfig: relevanceConfigValue }),
            // TODO: Remove the monolith vhrProviders param after 100% LSC migration
            ...(vhrProviders && { vhrProviders, vhrProvider: vhrProviders }),
            ...(pixAllId && { pixallId: pixAllId }),
            stats: ['year', 'derivedprice'],
        };
        // assign a property in ctx.data to store request options
        ctx.data.requestOptions = {
            requiredRequestLSC: {
                ...options,
                circuitBreaker: {
                    id: 'requiredCircuitBreakerLSC',
                    timeout: 20000,
                    resetTimeout: 30000,
                    fallback: {
                        showSrpError: true,
                    },
                },
            },
            optionalRequestLSC: {
                ...options,
                circuitBreaker: {
                    id: 'optionalCircuitBreakerLSC',
                    timeout: 3000,
                    resetTimeout: 30000,
                    fallback: {},
                },
            },
        };

        if (typeof window === 'undefined') {
            ctx.data.requestOptions.requiredRequestLSC.base = process.env.LSC_HOST;
            ctx.data.requestOptions.optionalRequestLSC.base = process.env.LSC_HOST;
        }
    };
}
