import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { Link } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { customBuildConditionFilterClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { InfoIcon } from 'reaxl-molecules';

import buildBapReferrer from '@/utilities/buildBapReferrer';

// fucks
import { kbbVRSDataDuck } from '@/ducks';

// TODO remove filtersValues & filtersOptions props and use specific selectors for make, model, series
function FilterBAPLink({
    filtersValues,
    filtersOptions,
}) {

    const {
        preorder: [enablePreorder, { excluded_makes: excludedMakes }],
    } = useFeatures([
        'preorder',
    ]);

    const [bapReferrer, setBapReferrer] = useState('');

    const excludeMakeCode = excludedMakes?.makeCode;

    let filterMakeSelected;
    filterMakeSelected = _get(filtersValues, 'makeCodeList', '') || _get(filtersValues, 'makeCode', []);

    // Check if filterMakeSelected is an array, and if not, convert it to an array
    if (!Array.isArray(filterMakeSelected)) {
        filterMakeSelected = filterMakeSelected ? [filterMakeSelected] : [];
    }

    const makeSelected = filterMakeSelected?.filter((make) => !excludeMakeCode.includes(make));
    const zipValue = _get(filtersValues, 'zip', false);

    const compareTrimsData = useSelector(kbbVRSDataDuck.selectors.getCompareTrims);

    let makeLabel;
    let selectedModel;
    let selectedSeries;
    let modelSelected;
    let seriesSelected;
    let bapUrl = `/cars-for-sale/build-and-price?makeCode=${makeSelected[0]}`;
    let isModelIncluded = false;
    let selectedModelCode;
    let selectedSeriesCode;

    if (makeSelected.length > 0) {
        // Get Model/series
        modelSelected = _get(filtersValues, `modelCodeList|${makeSelected[0]}`, '')
            || _get(filtersValues, `modelCode|${makeSelected[0]}`, []);
        seriesSelected = _get(filtersValues, `seriesCodeList|${makeSelected[0]}`, '')
            || _get(filtersValues, `seriesCode|${makeSelected[0]}`, []);

        // Get the selected make label
        const makes = _get(filtersOptions, 'makeCodeList.options', '') || _get(filtersOptions, 'makeCode.options', []);
        const selectedMake = makes.find((item) => item.selected && item.value === makeSelected[0]);
        makeLabel = selectedMake ? selectedMake.label : null;

        if (modelSelected.length > 0) {
            let modelCodeListOptions;
            modelCodeListOptions = _get(filtersOptions.modelCodeList, `modelCodeList|${makeSelected[0]}.options`)
                || _get(filtersOptions.modelCode, `modelCode|${makeSelected[0]}.options`);

            // Check if modelCodeListOptions is an array, and if not, convert it to an array
            if (!Array.isArray(modelCodeListOptions)) {
                modelCodeListOptions = modelCodeListOptions ? [modelCodeListOptions] : [];
            }
            const selectedModels = modelCodeListOptions?.filter((model) => model.selected);
            selectedModel = selectedModels?.map((model) => model.label).slice(-1)[0];

            selectedModelCode = selectedModels?.map((model) => model.value).slice(-1)[0];
            // Old models should not display as part of the B&P Link copy unless the model has data in the kbbVRSDataDuck
            isModelIncluded = !!Object.keys(_get(compareTrimsData, `${makeSelected[0]}.${selectedModelCode}`, {})).length;
            if (isModelIncluded) {
                bapUrl += `&modelCode=${selectedModelCode}`;
            }
        }

        if (seriesSelected.length > 0) {
            let seriesCodeListOptions;
            seriesCodeListOptions = _get(filtersOptions.seriesCodeList, `seriesCodeList|${makeSelected[0]}.options`)
                || _get(filtersOptions.seriesCode, `seriesCode|${makeSelected[0]}.options`);

            // Check if seriesCodeListOptions is an array, and if not, convert it to an array
            if (!Array.isArray(seriesCodeListOptions)) {
                seriesCodeListOptions = seriesCodeListOptions ? [seriesCodeListOptions] : [];
            }

            const selectedSeriesList = seriesCodeListOptions?.filter((series) => series.selected);
            selectedSeries = selectedSeriesList?.map((series) => series.label).slice(-1)[0];

            selectedSeriesCode = selectedSeriesList?.map((series) => series.value).slice(-1)[0];
            bapUrl = `/cars-for-sale/build-and-price?makeCode=${makeSelected[0]}&seriesCode=${selectedSeriesCode}`;
        }
    }

    const modelSeries = (isModelIncluded && (selectedModel || selectedSeries)) || '';

    const listingType = _get(filtersValues, 'listingTypes', false);
    const hasNeworNoListingTypeSelected = listingType === false || listingType.includes('NEW');

    // Get the latest model year
    const modelInCompareTrim = compareTrimsData?.[makeSelected]?.[selectedModelCode];
    const firstItem = modelInCompareTrim ? Object.values(modelInCompareTrim)[0] : null;
    const latestModelYear = firstItem ? firstItem.year : 'New';

    const bapLinkYear = modelSeries === '' ? 'New' : latestModelYear;
    // Link Label
    const bapFilterLabel = `Build Your ${bapLinkYear} ${makeLabel} ${modelSeries}`;

    const handleBAPFilterLinkClick = useCallback((event) => {
        sendClick(customBuildConditionFilterClick, event, {
            label: bapFilterLabel,
        });
    }, [bapFilterLabel]);

    // Add year to bap link when model is selected
    bapUrl = firstItem?.year ? `${bapUrl}&year=${latestModelYear}` : bapUrl;

    // Add referrer
    bapUrl += bapReferrer || '';

    // Build BAP referrer URL
    useEffect(() => {
        (async () => {
            const referrer = await buildBapReferrer({
                makeCode: filterMakeSelected,
                modelCode: selectedModel,
                zip: zipValue,
            });
            setBapReferrer(referrer);
        })();
    }, [filterMakeSelected, selectedModel, zipValue]);

    return enablePreorder && makeSelected.length > 0 && makeLabel && hasNeworNoListingTypeSelected && (
        <>
            <Link
                href={bapUrl}
                label={bapFilterLabel}
                onClick={handleBAPFilterLinkClick}
                rel="nofollow"
                undecorated
            />

            <InfoIcon
                infoText="Work with a local dealer to get the exact features and options you're looking for on your next vehicle."
                className="margin-left-1"
                placement="top"
            />
        </>
    );
}

export default FilterBAPLink;
