import { getSpotlightUrgencyDriver } from '@/utilities';

/*
 * helper method to determine the clickType to appended to the listing's href
 */
export const getClickType = (tierName, spotlightType) => {
    let listingClickType;
    switch (tierName) {
        case 'supplemental':
            listingClickType = 'supplemental';
            break;
        case 'spotlight':
            listingClickType = 'spotlight';
            break;
        case 'boost':
            listingClickType = 'ncb';
            break;
        case 'ime':
            listingClickType = 'ime';
            break;
        case 'peekaboo':
        case 'similar-highlight':
            listingClickType = 'highlight';
            break;
        case 'similar':
            listingClickType = 'similar';
            break;
        default:
            listingClickType = 'listing';
    }
    return listingClickType;
};

export const getParentId = (listingCategory, index, spotlightType) => {
    let parId;
    switch (listingCategory) {
        case 'similar':
            parId = 'similar';
            break;
        case 'similar-highlight':
            parId = 'similar-listing';
            break;
        case 'supplemental':
            parId = 'sup_v_lstg';
            break;
        case 'spotlight':
            parId = 'spv_lstg';
            break;
        case 'boost':
            parId = 'ncb';
            break;
        case 'ime':
            parId = 'ime';
            break;
        case 'peekaboo':
            parId = getSpotlightUrgencyDriver(spotlightType);
            break;
        default:
            parId = 'v_lstg';
    }

    return ['peekaboo', 'similar', 'similar-highlight'].includes(listingCategory) ? parId : parId + '_' + (index + 1);
};
