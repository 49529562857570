import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterByColor } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function InteriorColorFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const interiorColorOptions = useSelector(srpFiltersDuck.selectors.getInteriorColorOptions);
    const interiorColorValues = useSelector(srpFiltersDuck.selectors.getInteriorColorValues);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterName = 'intColorSimple';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(interiorColorOptions).length !== 0;

    const title = getFilterTitle('Interior Color', interiorColorValues);
    const filterValues = {
        intColorSimple: interiorColorValues,
    };

    // expand the panel if active options inside of it
    useEffect(() => {
        if (interiorColorValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interiorColorValues.length]);

    return (
        <AccordionPanel
            contentPadding={5}
            eventKey={filterName}
            data-cmp="filterInteriorColors"
            title={title}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterByColor
                    errors={filtersErrors}
                    filter={interiorColorOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    withAccordion={false}
                />
            )}
        </AccordionPanel>
    );
}

export default InteriorColorFilter;
