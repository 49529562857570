import { fetchJSON } from '@bonnet/next/fetch';

import { translateKeys } from '@atc/bonnet-parameters';

import { brands } from 'reaxl-brand';

import { srpNewSearchDuck, srpReuseActiveStateDuck } from '@/ducks/srp';

import addNewCarBoostData from './addNewCarBoostData';

export default function addSearchResultsPageData() {

    return async (ctx) => {
        const {
            // feature configs
            promo_card_removal: [enablePromoCardRemoval],
            spotlight: [isSpotlightEnabled],
            premium_spotlight: [enablePremiumSpotlight],
            suggested_filters: [enableSuggestedFilters],
            enable_new_car_boost: [enableNewCarBoost],
            default_num_records: [, { sortOptions: defaultNumRecordsOptions }],
            vehicle_highlight: [isVehicleHighlightEnabled],
        } = ctx.useFeatures([
            'promo_card_removal',
            'crawlPath',
            'suggested_filters',
            'enable_new_car_boost',
            'spotlight',
            'premium_spotlight',
            'default_num_records',
            'vehicle_highlight',
        ]);

        const state = ctx.store.getState();
        const reuseActiveState = srpReuseActiveStateDuck.selectors.getDuckState(state);
        const newSearch = srpNewSearchDuck.selectors.getDuckState(ctx.store.getState());

        const isFordBrand = ctx.data?.brand === brands.FORD_BRAND;

        // listings endpoint //
        const listingsEndpoint = '/rest/lsc/listing';
        const lscSearchRequired = translateKeys({ ...ctx.data.requestOptions.requiredRequestLSC.query, newSearch }, { target: 'lsc' });
        const lscSearchOptional = translateKeys({ ...ctx.data.requestOptions.optionalRequestLSC.query, newSearch }, { target: 'lsc' });

        if (enableSuggestedFilters) {
            Object.assign(lscSearchRequired, {
                includeSuggested: true,
            });
        }

        if (!!isFordBrand && !ctx.query?.numRecords) {
            Object.assign(lscSearchRequired, {
                numRecords: defaultNumRecordsOptions?.value,
            });
        }

        // if vehicle highlights are turned on, fetch extra listing in case fallback is needed for peekaboo
        if (isVehicleHighlightEnabled && enablePremiumSpotlight && !enablePromoCardRemoval) {
            const numRecordsValue = ctx.query?.numRecords || defaultNumRecordsOptions?.value;

            const numRecordsVehicleHighlight = Number(numRecordsValue) + 1;

            Object.assign(lscSearchRequired, {
                numRecords: numRecordsVehicleHighlight,
            });
        }

        if (ctx.query.experience === 'commercial') {
            Object.assign(lscSearchRequired, {
                lifestyleCategory: 'Commercial',
                filterByCategory: 'COMMERCIAL',
            });
            Object.assign(lscSearchOptional, {
                lifestyleCategory: 'Commercial',
                filterByCategory: 'COMMERCIAL',
            });
        }

        const listingsRequestOptions = {
            ...ctx.data.requestOptions.requiredRequestLSC,
            query: lscSearchRequired,
            circuitBreaker: {
                ...ctx.data.requestOptions.requiredRequestLSC.circuitBreaker,
                id: 'lsc_listing',
            },
        };

        if (typeof window === 'undefined') {
            listingsRequestOptions.responseInterceptor = (response, { json, cookies }) => {
                json._cookies = cookies;
            };
        }

        const lscRequestOptional = {
            ...ctx.data.requestOptions.optionalRequestLSC,
            query: { ...lscSearchOptional, numSpotlights: 3 },
        };

        ctx.fetchCompositeJSON.addFetch('base', fetchJSON(listingsEndpoint, listingsRequestOptions));

        // spotlights endpoint //
        const getSpotlightsEndpoint = (resultType) => `/rest/lsc/listing/spotlight/${resultType}`;
        const getSpotlightRequestOptions = (listingTier, numSpotlights) => ({
            ...lscRequestOptional,
            query: {
                ...lscRequestOptional.query,
                numSpotlights,
                ...(listingTier !== 'vehicle_highlight' && { listingTier }),
                ...(listingTier === 'vehicle_highlight' && { includeExtendedVehicleHighlights: true }),
            },
            circuitBreaker: {
                ...lscRequestOptional.circuitBreaker,
                id: `spotlight_${listingTier}`,
            },
        });

        if (isSpotlightEnabled && !reuseActiveState) {
            if (isVehicleHighlightEnabled && enablePremiumSpotlight && !enablePromoCardRemoval) {
                ctx.fetchCompositeJSON.addFetch('vehicleHighlight', fetchJSON(getSpotlightsEndpoint('vehiclehighlight'), getSpotlightRequestOptions('vehicle_highlight', 10)));
            }
            ctx.fetchCompositeJSON.addFetch('spotlights', fetchJSON(getSpotlightsEndpoint('premiumresults'), getSpotlightRequestOptions('premium', 3)));
            ctx.fetchCompositeJSON.addFetch('featuredSpotlights', fetchJSON(getSpotlightsEndpoint('results'), getSpotlightRequestOptions('featured', 6)));
            ctx.fetchCompositeJSON.addFetch('standardSpotlights', fetchJSON(getSpotlightsEndpoint('results'), getSpotlightRequestOptions('standard', 6)));
        }

        const shouldFetchNewCarBoost = enableNewCarBoost && !reuseActiveState;
        addNewCarBoostData(ctx, lscRequestOptional, shouldFetchNewCarBoost);
    };
}
