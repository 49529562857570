import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { ClientOnly } from '@atc/react-client-only';

import {
    AccordionPanel,
    SelectRange,
} from 'reaxl';
import {
    FilterTitle,
} from 'reaxl-filters';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

function YearFilterPlaceholder() {
    return (
        <>
            <div className="row skeleton__loading margin-top-1">
                <div className="col-xs-5">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>

                <div className="col-xs-5 col-xs-offset-2">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>
            </div>

            <div className="skeleton__loading margin-bottom-2">
                <div className="skeleton__spacer skeleton__placeholder" />
            </div>
        </>
    );
}

/**
*  YearFilter presents the user with the ability to select a year range
*/
function YearFilter({
    onOptionChange = () => { },
}) {
    const device = useDevice();

    const yearRangeOptions = useSelector(srpFiltersDuck.selectors.getYearOptions);
    const yearEndOptions = useSelector(srpFiltersDuck.selectors.getYearEndOptions);
    const yearEndValues = useSelector(srpFiltersDuck.selectors.getYearEndValue);
    const yearStartOptions = useSelector(srpFiltersDuck.selectors.getYearStartOptions);
    const yearStartValues = useSelector(srpFiltersDuck.selectors.getYearStartValue);

    const filterName = 'yearRange';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const isFiltersCollapsed = !!_get(device, 'lessThan.md', false);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const renderControls = isExpanded && (!isFiltersCollapsed || isFiltersVisible) && Object.keys(yearStartOptions).length !== 0 && Object.keys(yearEndOptions).length !== 0;

    const title = (
        <FilterTitle
            key="filterYearRangeTitle"
            title="Year"
        />
    );

    const minYearProps = {
        label: yearStartOptions?.label,
    };

    const maxYearProps = {
        label: yearEndOptions?.label,
    };

    const yearRangeFilters = (
        <SelectRange
            inline
            columnClasses="col-xs-6"
            options={yearRangeOptions.options}
            minSelectProps={{
                onChange: (event) => onOptionChange(event, yearStartOptions),
                value: yearStartValues || '',
                ...minYearProps,
            }}
            maxSelectProps={{
                onChange: (event) => onOptionChange(event, yearEndOptions),
                value: yearEndValues || '',
                ...maxYearProps,
            }}
        />
    );

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="filterYearRange"
            title={title}
            contentPadding={5}
        >
            <ClientOnly fallback={renderControls ? <YearFilterPlaceholder /> : null}>
                {!renderControls && <YearFilterPlaceholder uniqueKey={filterName} />}
                {renderControls && yearRangeFilters}
            </ClientOnly>
        </AccordionPanel>
    );

}

export default YearFilter;
