import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import MaskedTextInput from 'react-text-mask';

import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useDevice } from '@bonnet/next/device';

import { Input, Select } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { myWalletInputChange, myWalletSrpPlacementClick } from 'reaxl-analytics-handlers';
import { PromoCard } from 'reaxl-listing';

import { paymentsDuck } from '@/ducks';

const budgetOptions = [
    { name: 'monthly', value: 'monthly', label: 'per month' },
    { name: 'purchase', value: 'purchase', label: 'vehicle price' },
];

const currencyMask = createNumberMask({
    prefix: '$',
    pattern: '[0-9,$]{1,10}',
    type: 'money',
    includeThousandsSeparator: true,
    allowDecimal: false,
    requireDecimal: false,
    allowLeadingZeroes: false,
});

const baseIconUrl = 'https://www.autotrader.com/content/static/img/icon';

const DEFAULT_MAX_PAYMENT = 600;

function MyWalletBudgetPromoCard() {

    const device = useDevice();
    const deviceType = _get(device, 'type', '');
    const isInTabletSize = _get(device, 'is.sm', false) || _get(device, 'is.md', false);
    const isTablet = deviceType === 'tablet' || (deviceType !== 'tablet' && isInTabletSize);

    const dispatch = useDispatch();

    const [localBudget, setLocalBudget] = useState(DEFAULT_MAX_PAYMENT);
    const [localBudgetType, setLocalBudgetType] = useState('monthly');
    const [animatedButtonState, setAnimatedButtonState] = useState('');

    const handleBudgetTypeChange = (event) => {
        event.persist();
        const { name, value } = event.target;

        setLocalBudgetType(value);
        setLocalBudget(value === 'monthly' ? 450 : 25000);

        const targetValue = budgetOptions.find((option) => option.value === value)?.label?.toLowerCase();

        sendClick(myWalletInputChange, null, {
            inputName: name,
            targetValue,
            extraData: ` - ${targetValue}`,
            userModified: `BUDGET_INPUT_TOGGLED_TO_${value === 'monthly' ? 'MONTHLY' : 'TOTAL'}_BUDGET`,
        });
    };

    const debouncedOnChange = useMemo(() => _debounce((event) => {
        const sanitizedValue = event.target.value?.replace(/\D/g, '');
        setLocalBudget(sanitizedValue);

        sendClick(myWalletInputChange, null, {
            inputName: event.target.name,
            userModified: localBudgetType === 'monthly' ? 'MONTHLY_BUDGET_CHANGED' : 'BUDGET_CHANGED',
        });
    }, 300), [localBudgetType]);

    const handleBudgetChange = (event) => {
        event.persist();
        debouncedOnChange(event, 800);
    };

    const handleButtonClick = useCallback((e) => {
        setAnimatedButtonState('loading');

        const _budget = typeof localBudget === 'string' ? Number(localBudget?.replace(/[^0-9.]+/g, '')) : localBudget;
        dispatch(paymentsDuck.creators.updatePaymentsInfo({
            ...localBudgetType !== 'monthly' && { budget: _budget, monthlyBudget: undefined },
            ...localBudgetType === 'monthly' && { monthlyBudget: _budget, budget: undefined },
        }));

        dispatch(paymentsDuck.creators.updateMyWalletInteractions({ hasBudgetInteraction: true }));

        // NOTE: Wait for the budget value to be set in payments state before opening the wallet modal to ensure the budget value is present
        setTimeout(() => {
            setAnimatedButtonState('loaded');
            dispatch(paymentsDuck.creators.updateMyWallet({
                displayModal: true,
                selectedPanelName: 'budget',
            }));
        }, 1000);

        sendClick(myWalletSrpPlacementClick, e);
    }, [dispatch, localBudget, localBudgetType]);

    const budgetInputs = (
        <div className="display-flex justify-content-between align-items-stretch margin-bottom-2 margin-top-5">
            <div style={{ width: '40%' }}>
                <Input
                    className="margin-bottom-0 text-overflow"
                    component={MaskedTextInput}
                    inputMode="numeric"
                    label={localBudgetType === 'monthly' ? 'Payment' : 'Price'}
                    layout="inside"
                    mask={currencyMask}
                    name="budgetAmountInput"
                    onChange={handleBudgetChange}
                    style={{ minWidth: '40%' }}
                    value={localBudget}
                />
            </div>
            <div style={{ width: '55%' }}>
                <Select
                    aria-label="Budget Type"
                    labelProps={{
                        label: 'Type',
                    }}
                    layout="inside"
                    name="budgetType"
                    onChange={handleBudgetTypeChange}
                    options={budgetOptions}
                    style={{
                        height: '48px',
                        textOverflow: 'ellipsis',
                    }}
                    value={localBudgetType}
                />
            </div>
        </div>
    );

    const buttonStyle = {
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    return (
        <PromoCard
            animatedButton
            animatedButtonState={animatedButtonState}
            bodyClassName={clsx('text-center', {
                'padding-horizontal-2': !isTablet,
            })}
            buttonStyle={buttonStyle}
            buttonText="Set My Budget"
            className="col-xs-12 col-sm-4 display-flex"
            data-cmp="myWalletPlacement"
            handleButtonClick={handleButtonClick}
            heading="Find Cars by Budget"
            imgHeight="92"
            imgWidth="148"
            imgSrc={`${baseIconUrl}/myWallet/grid/budget_illustration.svg`}
            subText="Save time during your search by setting an affordable budget."
        >
            {budgetInputs}
        </PromoCard>
    );
}

export default MyWalletBudgetPromoCard;
