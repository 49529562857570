import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function ElectricMileRangeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const electricMileRangeValues = useSelector(srpFiltersDuck.selectors.getElectricMileRangeValues);
    const electricMileRangeOptions = useSelector(srpFiltersDuck.selectors.getElectricMileRangeOptions);

    const filteredMileRangeOptions = (electricMileRangeOptions?.options
        && electricMileRangeOptions.options.filter((option) => option.label !== 'Unspecified')) || [];
    const isMileRangeDisabled = filteredMileRangeOptions.length > 0
        && filteredMileRangeOptions.filter((option) => option.disabled).length === filteredMileRangeOptions.length;

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        electricMileRange: electricMileRangeValues,
    };

    const filterName = 'electricMileRange';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(electricMileRangeOptions).length !== 0;

    const title = getFilterTitle('Electric Mile Range', filterValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (electricMileRangeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electricMileRangeValues.length]);

    return !!electricMileRangeOptions.options?.length && !isMileRangeDisabled && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="ElectricMileRangeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    key="mileRangeCheckbox"
                    filter={electricMileRangeOptions}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    values={filterValues}
                    errors={filtersErrors}
                    withAccordion={false}
                />
            )}
        </AccordionPanel>
    );
}

export default ElectricMileRangeFilter;
