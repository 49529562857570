import { createAction, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'fixedAd',
    initialState: {
        adPos: 'top',
        closeFixedAd: false,
        showFixedAd: false,
        scrolled: false,
        refreshCount: 1,
    },
}).extend({
    types: [
        'SET_FIXED_AD_REFRESH',
    ],
    reducer: (state, action, { types }) => {
        switch (action.type) {
            case types.SET_FIXED_AD_REFRESH: {
                return {
                    ...state,
                    refreshCount: action.payload.refreshCount,
                };
            }

            default:
                return state;
        }
    },
    creators: ({ types }) => ({
        setFixedAdRefresh: createAction(types.SET_FIXED_AD_REFRESH),
    }),
    selectors: () => ({
        getCloseFixedAd: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).closeFixedAd),
        getShowFixedAd: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showFixedAd),
        getScrolled: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).scrolled),
        getRefreshCount: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).refreshCount),
    }),
});
