import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { stringifyValues } from '@atc/string-fns';

import { Button, Glyphicon } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import { keywordSearchSubmit } from '@/analytics/srpAnalyticsHandlers';

import {
    srpFiltersDuck,
    srpResultsDuck,
} from '@/ducks/srp';

import SuggestedKeywordFetcher from '@/fetchers/srp/SuggestedKeywordFetcher';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import LazyComponent from '@/components/LazyComponent';

const KeywordSearch = dynamic(() => import('@/components/KeywordSearch'), { ssr: false });

const navigateToArticlePage = (contentUrl) => {
    window.open(contentUrl);
};

function KeywordSearchContainer({
    isStickyHeader = false,
    ...rest
}) {
    const [showPlaceholderInput, setShowPlaceholderInput] = useState(true);
    const [showKeywordSearch, setShowKeywordSearch] = useState(false);
    const [inputDefaultValue, setInputDefaultValue] = useState('');
    const filters = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const isLoadingResults = useSelector(srpResultsDuck.selectors.getResultLoading);
    const searchRadius = stringifyValues(_get(filters, 'searchRadius', 0));
    // Build location object used for KeywordSearch component
    const keywordSearchLocation = {
        zip: _get(filters, 'zip', null),
        searchRadius,
    };
    const {
        suggested_keyword: [enableKeyword, { keyword_placeholder: keywordPlaceholder }],
    } = useFeatures(['suggested_keyword']);

    const navigateToSrp = useSrpNavigation();

    const handleKeywordSearch = useCallback(async (data) => {
        const { codes = {}, contentUrl } = data;

        await sendClick(keywordSearchSubmit, null, {
            ...data,
            cmp: 'fts_kyw',
        });

        if (contentUrl) {
            navigateToArticlePage(contentUrl);
        } else {
            navigateToSrp({
                action: 'replace',
                resetPagination: true,
                filtersValues: { ...codes },
            });
        }
    }, [navigateToSrp]);

    return enableKeyword && (
        <div
            data-cmp={`cntnr-kywrd${isStickyHeader ? '-sticky' : ''}`}
            {...rest}
        >
            {showPlaceholderInput && (
                <div
                    className="autocomplete-search form-control padding-0 display-flex align-items-center justify-content-between"
                >
                    <div className="flex-grow react-autosuggest__container autocomplete">
                        <input
                            type="text"
                            className="form-control"
                            data-cmp="debouncedInput"
                            aria-autocomplete="list"
                            placeholder={keywordPlaceholder}
                            onFocus={() => setShowKeywordSearch(true)}
                            onChange={(e) => { setInputDefaultValue(e.target.value); setShowKeywordSearch(true); }}
                            aria-label={keywordPlaceholder}
                            disabled={isLoadingResults}
                        />
                    </div>
                    <Button
                        type="submit"
                        bsStyle="link"
                        aria-label="Search"
                    >
                        <Glyphicon
                            glyph="search"
                            className="cursor-pointer text-accent text-size-500"
                        />
                    </Button>
                </div>
            )}
            {showKeywordSearch && (
                <LazyComponent>
                    <KeywordSearch
                        fetchSuggestions={SuggestedKeywordFetcher}
                        location={keywordSearchLocation}
                        onSearch={handleKeywordSearch}
                        showZip={false}
                        placeholder={keywordPlaceholder}
                        autoFocus
                        defaultValue={inputDefaultValue}
                        onMount={() => setShowPlaceholderInput(false)}
                    />
                </LazyComponent>
            )}
        </div>
    );
}

export default React.memo(KeywordSearchContainer, _isEqual);
