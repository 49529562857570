import React, { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _get from 'lodash/get';
import _has from 'lodash/has';

import { useDevice } from '@atc/react-device';
import { formatCurrency } from '@atc/string-fns';

import {
    Badge,
    Button,
    ExpandTrigger,
    Image,
    List,
    Panel,
    Text,
} from 'reaxl';
import { sendImpressions } from 'reaxl-analytics';
import { EmailCTA } from 'reaxl-email';
import { useFeatures } from 'reaxl-features';
import {
    OwnerAddress,
    OwnerPhoneNumber,
    OwnerWebsiteCTA,
} from 'reaxl-molecules';
import { KBBDealerRatings } from 'reaxl-ratings-reviews';

import { getAlphaListingType } from '@/utilities/getAlphaTaggingType';

import { inventoryImpressions } from '@/analytics/srpAnalyticsHandlers';

import {
    srpActiveInteractionDuck,
} from '@/ducks/srp';

import AlphaModule from '@/modules/AlphaModule';

/**
 *  This component displays dealer specific info in a position overlay grid which can be used on special types of
 *  listings like the AlphaShowcase
 *
 *  Layout it the same on landscape and portrait:
 *
 * ,-------------------------------------
 * | OwnerName         |                 |
 * | OwnerRating       |    OwnerLogo    |
 * |                   |    Tagline      |
 * |                   |.................|
 * | Address           |                 |
 * | PhoneNumber | CTA |                 |
 * | GetDirections     |  AccelerateTile |
 *  -------------------------------------
 *
 */
const renderKBBDealerRatings = (owner) => (
    <KBBDealerRatings
        owner={owner}
        widgetType="inline"
    />
);

function InventoryDealerInfo({
    clickType,
    emailOwnerProps = {},
    lazyLoad = true,
    listing = {},
    listings = [],
    listingsCount,
    onTileClick = () => { },
    onInventoryListingClick = () => { },
    onViewOwnerInventoryClick = () => { },
    onCollapsibleHeaderClick = () => { },
    owner = {},
    orientation,
    parentId,
    phoneClickHandler,
    uiContext,
}) {
    const {
        id: ownerId,
        location,
        logo,
        name,
        phone,
        rating,
        website = {},
        hideEmailForm,
    } = owner;

    const {
        disable_dealer_rating: [isDealerRatingDisabled],
    } = useFeatures([
        'disable_dealer_rating',
    ]);

    const device = useDevice();
    const dispatch = useDispatch();
    const isXs = _get(device, 'is.xs', false);
    const hasOwnerWebsite = !!website.href;
    const isPortrait = orientation === 'portrait';
    const titleTextClasses = clsx('text-gray-base', {
        'text-size-200': uiContext === 'inventory',
        'text-size-500 text-bold': uiContext === 'owner',
    });

    const showAlphaCarousel = _get(useSelector(srpActiveInteractionDuck.selectors.getDuckState), 'showAlphaCarousel', false);
    const isAlphaMarket = useSelector(AlphaModule.duck.selectors.isAlphaMarket);

    const showViewOwnerInventory = onViewOwnerInventoryClick instanceof Function;

    const showDealerRating = !isDealerRatingDisabled && rating;
    useEffect(() => {
        if (uiContext === 'owner') {
            const selectedItemIds = listings.slice(0, 2).map(({ id }) => (id));
            sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'dealer_alpha',
                    selectedItemIds,
                    pixallData: {
                        label: 'dealer-vehicle-alpha',
                        eventSource: 'vehicle',
                        listingType: getAlphaListingType(isAlphaMarket, true),
                        impressionType: 'srpi',
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerId, uiContext, isAlphaMarket]);

    const analyticsData = {
        inventoryId: listing.id,
        ownerId,
        par: parentId,
        clickType,
    };

    const renderDealerLogo = logo && (
        <Image
            className="margin-bottom-1 cursor-pointer"
            alt="Dealer Logo"
            src={logo?.src}
            lazyload={lazyLoad}
            loading={lazyLoad ? 'lazy' : 'eager'}
            onClick={(event) => onTileClick(event, owner)}
            width={71}
            height={30}
        />
    );

    const renderOffsiteOwnerTitle = (
        <Fragment key="offsiteOwnerTitle">
            <OwnerWebsiteCTA
                className={titleTextClasses}
                href={website.href}
                label={name || listing.ownerName}
                analyticsData={analyticsData}
                uiContext="link"
            />
            {showDealerRating && renderKBBDealerRatings(owner)}
        </Fragment>
    );

    const renderOwnerTitle = (
        <Fragment key="ownerTitle">
            <span className={titleTextClasses}>
                {name}
            </span>
            {showDealerRating && renderKBBDealerRatings(owner)}
        </Fragment>
    );

    const renderTitle = () => {
        const titleComponent = hasOwnerWebsite ? renderOffsiteOwnerTitle : renderOwnerTitle;
        return rating ? titleComponent : (
            <div className="margin-top-3 margin-bottom-4">
                {titleComponent}
            </div>
        );
    };

    const address = location && location.address;
    const renderAddress = address && (
        <OwnerAddress
            uiContext="default"
            location={location}
            analyticsData={analyticsData}
            wrapperComponent="div"
            linkClassName="text-gray-base text-size-200"
        />
    );

    const renderOwnerPhone = (
        <OwnerPhoneNumber
            key="ownerPhoneNumber"
            phoneClickHandler={phoneClickHandler}
            className={clsx({ 'text-link link-undecorated': isXs })}
            phone={listing.phone || phone}
            isVisible
            isCallable={isXs}
            analyticsData={analyticsData}
        />
    );

    const renderEmailOwner = (
        <EmailCTA
            clickType={clickType}
            parentId={emailOwnerProps.parentId}
            key="email-cta"
            listingId={listing.id}
            onClick={emailOwnerProps.onCTAClick}
            ownerId={ownerId}
            type="link"
            context="alpha"
            labelKey="checkAvailability"
        />
    );

    const showOwnerPhoneNumber = _has(owner, 'phone.value');
    const showOwnerEmailCTA = !!emailOwnerProps && !hideEmailForm;

    const renderDealerInfoList = () => {
        const items = [];
        if (showOwnerPhoneNumber) {
            items.push(renderOwnerPhone);
        }
        if (showOwnerEmailCTA) {
            items.push(renderEmailOwner);
        }

        return (
            <List
                inline
                items={items}
                className="text-gray-base text-size-200"
            />
        );
    };

    const assignImages = (ownerListing) => {
        let imageArray = [];

        // iterate through premimum spotlight images, up to 4
        const filteredImages = ownerListing?.images?.sources?.filter((image) => image.src !== undefined);
        if (filteredImages?.length) {
            imageArray = filteredImages.slice(0, 1);
        }

        return imageArray;
    };

    const renderListings = listings.slice(0, 2).map((ownerListing, index) => {
        const vehiclePricingDetail = ownerListing?.pricingDetail;
        const vehiclePrice = vehiclePricingDetail?.incentive || vehiclePricingDetail?.salePrice || vehiclePricingDetail?.msrp;
        const onInventoryTitleClick = (event) => onInventoryListingClick(event, ownerListing, index, clickType);

        return (
            <div
                className="col-xs-6 col-sm-5"
                key={`owner-listing-${ownerListing.id}-${index}`}
            >
                <Panel className="margin-0">
                    <div className="display-flex">
                        {assignImages(ownerListing)?.map((image) => (
                            <Image
                                key={`listing-alert-image-${ownerListing.id}-${index}`}
                                className="margin-top-1 margin-top-sm-0"
                                src={image?.src}
                                alt={image?.alt}
                                height={60}
                                width={80}
                            />
                        ))}
                        <div
                            className="padding-2 text-size-200 text-overflow"
                        >
                            <Button
                                bsStyle="text"
                                onClick={onInventoryTitleClick}
                            >
                                {ownerListing?.title}
                            </Button>
                            <Text
                                componentClass="div"
                                color="gray-base"
                                size={300}
                            >
                                {formatCurrency(vehiclePrice)}
                            </Text>
                        </div>
                    </div>
                </Panel>
            </div>
        );
    });

    const renderDealerInventoryButton = () => (
        <Button
            data-cmp="ownerViewCarsCTA"
            onClick={(event) => onViewOwnerInventoryClick(event, owner)}
            bsStyle="text"
        >
            View All Cars
        </Button>
    );

    const handleExpandTriggerClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        onCollapsibleHeaderClick();

        dispatch(srpActiveInteractionDuck.creators.setKeys({
            showAlphaCarousel: !showAlphaCarousel,
        }));
    };

    if (uiContext === 'owner') {
        return (
            <>
                {renderTitle()}
                <hr />
                <div className="row display-flex align-items-center">
                    <div
                        className="col-xs-12 col-sm-9"
                        key="contactDealer"
                    >
                        {renderAddress}
                        {renderDealerInfoList()}
                    </div>
                    <div className="hidden-xs col-sm-3 padding-top-3 padding-top-sm-0 text-sm-right">
                        {renderDealerLogo}
                    </div>
                </div>
                <div className="row margin-top-3">
                    {renderListings}
                    <div className="col-xs-12 col-sm-2 padding-top-4">{showViewOwnerInventory && renderDealerInventoryButton()}</div>
                </div>
            </>
        );
    }

    return (
        <div className="row margin-bottom-1">
            {!isPortrait && (
                <div className="col-xs-12 col-sm-9">
                    {hasOwnerWebsite ? renderOffsiteOwnerTitle : renderOwnerTitle}
                    <div
                        className="display-flex flex-row justify-content-start flex-wrap"
                        key="contactDealer"
                    >
                        {renderDealerInfoList()}
                    </div>
                </div>
            )}
            <div className="col-xs-12 col-sm-3 align-items-center justify-content-between padding-top-3 padding-top-sm-0 text-sm-right">
                {renderDealerLogo}

                <div className="display-flex align-items-center pull-right margin-top-sm-2">
                    <Badge className="padding-vertical-1 margin-right-2">
                        {listingsCount}
                    </Badge>
                    <ExpandTrigger
                        collapsed={{ label: 'View Vehicles', glyph: 'chevron-down' }}
                        data-cmp="alpha-expand-trigger"
                        expanded={{ label: 'Hide Vehicles', glyph: 'chevron-up' }}
                        isCollapsed={!showAlphaCarousel}
                        onClick={handleExpandTriggerClick}
                        className="text-size-200 padding-vertical-1"
                    />
                </div>
            </div>
        </div>
    );
}

InventoryDealerInfo.propTypes = {
    /**
    * The className to apply to the root node
    */
    className: PropTypes.string,
    /**
     * Toggle to lazyLoad components
     */
    lazyLoad: PropTypes.bool,
    /**
     * The Listing data to display
     */
    listing: PropTypes.object,
    /**
     * The owner data to display in case a listing is not using this component
     */
    owner: PropTypes.object,
    /**
     * The handler for inventory title click
     */
    onInventoryListingClick: PropTypes.func,
    /**
     * The handler for the tile click
     */
    onTileClick: PropTypes.func,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * uiContext to control display of inventory listing side bar
     */
    uiContext: PropTypes.oneOf(['owner', 'inventory']),
    /**
     * The showPaymentRibbon boolean to show/hide payment ribbon
     */
    showAlphaPaymentRibbon: PropTypes.bool,
    /**
     * The showExtraDealerInfo boolean to show/hide the ExtraDealerInfo component
     */
    showExtraDealerInfo: PropTypes.bool,
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * Object for personal payment
     */
    personalPaymentProps: PropTypes.object,
};

export default InventoryDealerInfo;
