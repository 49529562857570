import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { srpActiveInteractionDuck } from '@/ducks/srp';
import { vdpDealerDiffDuck } from '@/ducks/vdp';

export const DealerDiffModal = dynamic(() => import(
    /* webpackChunkName: "DealerDiffModal" */
    '@/components/DealerDiffModal/index.js'
), { ssr: false });
function SRPDealerDiffModalWrapper() {
    const dispatch = useDispatch();
    const { content: dealerDiffModalContent } = useSelector(vdpDealerDiffDuck.selectors.getDuckState);
    const showDealerDiffModal = useSelector(srpActiveInteractionDuck.selectors.getShowDealerDiffModal);

    const handleDealerDiffModalToggle = useCallback(() => dispatch(srpActiveInteractionDuck.creators.setKeys({ showDealerDiffModal: !showDealerDiffModal })), [dispatch, showDealerDiffModal]);

    return (
        showDealerDiffModal && (
            <DealerDiffModal
                modalContent={dealerDiffModalContent}
                onToggle={handleDealerDiffModalToggle}
                showModal={showDealerDiffModal}
            />
        ));
}

export default React.memo(SRPDealerDiffModalWrapper);
