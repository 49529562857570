import { fetchJSON } from '@bonnet/next/fetch';

export default function fetchSearchBookmarks(searchBookmarkId, idtoken) {
    return fetchJSON(
        '/account-api/searchbookmarks',
        {
            query: {
                searchBookmarkId,
            },
            circuitBreaker: {
                id: 'searchBookmark',
                timeout: 3000,
                resetTimeout: 30000,
            },
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                idtoken,
            },
        }
    );
}

export function fetchShareBookmark(shareBookmarkId, idtoken) {
    return fetchJSON(
        `/account-api/searchbookmarks/share-bookmark/${shareBookmarkId}`,
        {
            circuitBreaker: {
                id: 'shareBookmark',
                timeout: 3000,
                resetTimeout: 300000,
            },
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                accesstoken: 'accesstoken',
                idtoken,
            },
        }
    );
}

export function updateSearchBookmark(bookmark, idtoken) {
    return fetchJSON(
        '/account-api/search',
        {
            body: bookmark,
            circuitBreaker: {
                id: 'updateSearchBookmark',
                timeout: 3000,
                resetTimeout: 300000,
            },
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                idtoken,
            },
        }
    );
}
