import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { usePathname } from 'next/navigation';

import { useFeatures } from 'reaxl-features';
import { ListingCollection } from 'reaxl-schema';

import { ownersDuck, userDuck } from '@/ducks';

import { srpContentDuck, srpResultsDuck } from '@/ducks/srp';

function SRPListingCollection() {
    const {
        DISABLE_SRP_SEO_SCHEMA: [disableSEOSchema],
        brand: [, { base_url: baseUrl }],
        schema: [, { listing_type: schemaType, vehicle_feature_description: featureDescription, vehicle_price_description: priceDescription }],
    } = useFeatures([
        'DISABLE_SRP_SEO_SCHEMA',
        'brand',
        'schema',
    ]);

    const path = usePathname();

    const owners = useSelector(ownersDuck.selectors.getOwners);
    const listingsWithoutOwner = useSelector(srpResultsDuck.selectors.getActiveInventory);
    const inventoryListings = listingsWithoutOwner.map((listing) => ({ ...listing, owner: owners?.[listing.ownerId] }));
    const srpContent = useSelector(srpContentDuck.selectors.getDuckState);
    const userLocation = useSelector(userDuck.selectors.getCityState);

    const schemaTemplate = useMemo(() => ({
        featureDescription,
        priceDescription,
    }), [featureDescription, priceDescription]);

    return !disableSEOSchema && (
        <ListingCollection
            inventory={inventoryListings}
            schemaTemplate={schemaTemplate}
            srpContent={srpContent}
            schemaType={schemaType}
            baseUrl={baseUrl}
            searchLocation={userLocation}
            schemaUrl={`${baseUrl}cars-for-sale${path}`}
        />
    );
}

export default React.memo(SRPListingCollection);
