import React, { memo, useState } from 'react';

import {
    Button,
    Glyphicon,
    Link,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    SubHeading,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { electricVehicleFilterInfoClick, electricVehicleResearchArticleClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

function VehicleTypeExplainer({ title, glyph, children }) {
    return (
        <div className="display-flex flex-column gap-4">
            <SubHeading
                size={400}
                className="display-flex align-items-center gap-3"
            >
                <Glyphicon
                    glyph={glyph}
                    className="text-size-500 text-success"
                />
                {title}
            </SubHeading>
            {children}
        </div>
    );
}

function ElectricVehicleInfo() {

    const {
        ev_info: [showEvInfo, { learnMoreUrl: evArticles }],
    } = useFeatures([
        'ev_info',
    ]);

    const [showModel, setShowModel] = useState(false);

    const handleShowModel = async (event) => {
        sendClick(electricVehicleFilterInfoClick, event, {
            label: 'electric-vehicle-types-differences',
        });
        setShowModel(true);
    };
    const handleCloseModel = () => setShowModel(false);

    const handleResearchArticle = async (event) => {
        sendClick(electricVehicleResearchArticleClick, event, {
            label: 'learn-more-about-differences-between-each-fuel-type',
        });
    };

    return showEvInfo && (
        <>
            <Button
                bsStyle="text"
                className="margin-horizontal-1 padding-top-1 margin-bottom-4"
                key="EVModelLink"
                block
                onClick={handleShowModel}
            >
                Electric Vehicle Types & Differences
            </Button>
            <Modal
                bsSize="small"
                show={showModel}
                onHide={handleCloseModel}
                data-cmp="modal-cert-dtls"
                key="renderElectricInfoModal"
                dialogClassName="certified-tile-modal"
            >
                <ModalHeader closeButton>
                    <ModalTitle>
                        Electric Vehicle Types & Differences
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="display-flex flex-column gap-5">
                    <VehicleTypeExplainer
                        title="Electric"
                        glyph="electric"
                    >
                        Fully Electric vehicles rely on battery power and use no gasoline. These require plug-in charging at home or public charging stations.
                    </VehicleTypeExplainer>
                    <VehicleTypeExplainer
                        title="Hybrid"
                        glyph="hybrid"
                    >
                        Hybrid vehicles rely on both battery power and gasoline.They do not require plug-in charging and will need regular fueling just like a gas engine vehicle.
                    </VehicleTypeExplainer>
                    <VehicleTypeExplainer
                        title="Plug-in Hybrid"
                        glyph="plugin-hybrid"
                    >
                        Plug-In Hybrid vehicles rely on both battery power and gasoline. They require plug-in charging to use the battery power for driving shorter distances (often around 30 miles), and regular fueling for longer trips. When the battery power depletes, a gas-powered engine takes over.
                    </VehicleTypeExplainer>
                    <Link
                        href={evArticles}
                        key="EVArticlesLink"
                        target="_blank"
                        onClick={handleResearchArticle}
                        className="margin-bottom-4"
                        undecorated
                    >
                        Learn more about the differences between each fuel type
                    </Link>
                </ModalBody>
            </Modal>
        </>
    );
}

export default memo(ElectricVehicleInfo);
