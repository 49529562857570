import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
    AccordionPanel,
} from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import ElectricVehicleInfo from '@/components/ElectricVehicleInfo/index.js';

import FilterPlaceholder from './FilterPlaceholder';

function FuelTypeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const fuelTypeValues = useSelector(srpFiltersDuck.selectors.getFuelTypeValues);
    const fuelTypeOptions = useSelector(srpFiltersDuck.selectors.getFuelTypeOptions);

    // Custom sorting order for Fuel Type Filters
    const fuelTypeOrder = ['Electric', 'Gasoline', 'Hybrid', 'Plug-in Hybrid', 'Diesel', 'Hydrogen'];
    fuelTypeOptions?.options?.sort((a, b) => fuelTypeOrder.indexOf(a.label) - fuelTypeOrder.indexOf(b.label));

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        fuelTypeGroup: fuelTypeValues,
    };

    const filterName = fuelTypeOptions.name;
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle(fuelTypeOptions.label, fuelTypeValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (fuelTypeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuelTypeValues.length]);

    return !!fuelTypeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="FuelTypeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <>
                    <FilterCheckboxes
                        key="fuelTypeCheckbox"
                        filter={fuelTypeOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        values={filterValues}
                        errors={filtersErrors}
                        withAccordion={false}
                    />
                    <ElectricVehicleInfo />
                </>
            )}
        </AccordionPanel>
    );
}

export default FuelTypeFilter;
