import React, { memo, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { ClientOnly } from '@atc/react-client-only';

import {
    AccordionPanel,
    Checkbox,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import {
    FilterCheckboxLabel,
    FilterLocationForm,
    FilterTitle,
} from 'reaxl-filters';

import { srpFiltersDuck } from '@/ducks/srp';

function LocationFilterPlaceholder() {
    return (
        <>
            <div className="row skeleton__loading margin-top-1">
                <div className="col-xs-5">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>

                <div className="col-xs-5 col-xs-offset-2">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>
            </div>

            <div className="skeleton__loading margin-bottom-2">
                <div className="skeleton__spacer skeleton__placeholder" />
            </div>
            <div className="row skeleton__loading margin-top-4 margin-top-sm-2">
                <div className="col-xs-1">
                    <div className="skeleton__text skeleton__placeholder margin-bottom-0" />
                </div>
                <div className="col-xs-11 padding-left-4">
                    <div className="skeleton__text skeleton__placeholder margin-bottom-0" />
                </div>

            </div>
        </>
    );
}

/**
 *  Display Market Extension Filter
 *
 */
const MarketExtensionFilter = memo(({
    className,
    onOptionChange = () => { },
    isGroupedLocationFilter = false,
}) => {

    const marketExtensionOptions = useSelector(srpFiltersDuck.selectors.getMarketExtensionOption);
    const marketExtensionValue = useSelector(srpFiltersDuck.selectors.getMarketExtensionValue);

    const [displayCheckbox, setDisplayCheckbox] = useState(marketExtensionValue !== 'off');

    useEffect(() => {
        const applyMarketExtension = marketExtensionValue !== 'off';
        setDisplayCheckbox(applyMarketExtension);
    }, [marketExtensionValue]);

    const onChangeHandler = useCallback((event) => {
        const newDisplayCheckbox = !displayCheckbox;
        setDisplayCheckbox(newDisplayCheckbox);
        /* set the e.target.value so that Redux picks up the correct selection from the Checkbox */
        event.target.value = newDisplayCheckbox ? 'include' : 'off';
        onOptionChange(event, marketExtensionOptions);
    }, [displayCheckbox, marketExtensionOptions, onOptionChange]);

    const title = 'Include results that can be delivered outside your radius';
    const label = isGroupedLocationFilter ? title : marketExtensionOptions.title;

    const displayMarketExtension = () => (
        <Checkbox
            checked={displayCheckbox}
            label={(
                <FilterCheckboxLabel
                    label={label}
                    moreInfo="Some dealers from outside your local area offer the option to deliver a car to you. Distance and shipping fees may vary."
                    isFiltersCollapsed={false}
                />
            )}
            labelClassName="margin-vertical-0"
            name="marketExtension"
            onChange={onChangeHandler}
            aria-label={label}
        />
    );

    return (
        <div
            className={className}
            data-cmp="marketExtensionCheckbox"
        >
            {marketExtensionOptions?.visible && displayMarketExtension()}
        </div>
    );
});

function LocationFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
}) {
    const {
        // Feature configs
        market_extension: [enableMarketExtension],
    } = useFeatures([
        'market_extension',
    ]);
    const device = useDevice();

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const locationOptions = useSelector(srpFiltersDuck.selectors.getLocationFilterOptions);

    const searchRadiusOptions = useSelector(srpFiltersDuck.selectors.getSearchRadiusOptions);
    const searchRadiusValues = useSelector(srpFiltersDuck.selectors.getSelectedSearchRadius);
    const searchRadiusValue = searchRadiusValues === 0 ? ['0'] : searchRadiusValues;

    const zipOptions = useSelector(srpFiltersDuck.selectors.getZipOptions);
    const zipValue = useSelector(srpFiltersDuck.selectors.getSelectedZipValue);

    const filterName = 'location';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const isFiltersCollapsed = !!_get(device, 'lessThan.md', false);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const renderControls = isExpanded && (!isFiltersCollapsed || isFiltersVisible) && Object.keys(searchRadiusOptions).length !== 0 && Object.keys(zipOptions).length !== 0;

    const title = (
        <FilterTitle
            key="filterLocationTitle"
            title="Location"
        />
    );

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="filterLocation"
            title={title}
            contentPadding={5}
        >
            <ClientOnly fallback={renderControls ? <LocationFilterPlaceholder /> : null}>
                {!renderControls && <LocationFilterPlaceholder />}
                {renderControls && (
                    <FilterLocationForm
                        filter={locationOptions}
                        errors={filtersErrors}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        resultCount={100}
                        searchRadiusOptions={searchRadiusOptions}
                        searchRadiusValue={searchRadiusValue}
                        zipOptions={zipOptions}
                        zipValue={zipValue}
                    />
                )}
                {renderControls && enableMarketExtension && (
                    <MarketExtensionFilter
                        onOptionChange={onOptionChange}
                        isGroupedLocationFilter={false}
                    />
                )}
            </ClientOnly>
        </AccordionPanel>
    );
}

export default LocationFilter;
