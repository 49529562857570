import React from 'react';

import {
    Text,
} from 'reaxl';
import {
    FilterTitle,
} from 'reaxl-filters';

const getFilterTitle = (label, value) => {
    let filterTitle = label;
    const valueCount = value.length;

    if (valueCount > 0) {
        filterTitle = (
            <>
                {label}
                {' '}
                <Text
                    weight="normal"
                    color="subdued-lighter"
                >
                    {`(${valueCount} selected)`}
                </Text>
            </>
        );
    }

    return (
        <FilterTitle
            key="filter-title"
            title={filterTitle}
        />
    );
};

export default getFilterTitle;
