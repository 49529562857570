import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import {
    Button,
    Glyphicon,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { srpRecentSearchToggle } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import {
    recentSearchDuck,
    userPreferencesDuck,
} from '@/ducks';

import RecentSearchChipsContainer from './RecentSearchChipsContainer';
import SrpAlertsContainer from './SrpAlertsContainer';

function SearchResultsRecentSearchesContainer() {
    const dispatch = useDispatch();

    const {
        recent_searches_srp: [enableRecentSearches],
        suggested_keyword: [enableKeyword],
    } = useFeatures([
        'recent_searches_srp',
        'suggested_keyword',
    ]);

    const hasRecentSearches = useSelector((state) => _get(recentSearchDuck.selectors.getLatestRecentSearches(state), 'length', 0) > 0);
    const recentSearchInitialView = useSelector(userPreferencesDuck.selectors.getShowRecentSearches);
    const [showRecentSearches, setShowRecentSearches] = useState(recentSearchInitialView);

    const displayRecentSearchContainer = enableRecentSearches && hasRecentSearches;

    const handleMyRecentSearchesClick = (event, oldShowRecentSearches) => {
        const newShowRecentSearches = !oldShowRecentSearches;
        setShowRecentSearches(newShowRecentSearches);
        dispatch(userPreferencesDuck.creators.setShowRecentSearches(newShowRecentSearches));
        sendClick(srpRecentSearchToggle, event, {
            showRecentSearches: newShowRecentSearches,
        });
    };

    return (
        <>
            <SrpAlertsContainer />
            {displayRecentSearchContainer && (
                <div className="padding-horizontal-2 padding-horizontal-sm-0">
                    <div className="margin-bottom-sm-3">
                        <div className="row display-sm-flex align-items-center">
                            <div className={clsx('col-xs-12 margin-top-2 margin-top-sm-0', { 'col-sm-2': !enableKeyword })}>
                                <Button
                                    bsStyle="text"
                                    className="text-bold"
                                    onClick={(event) => handleMyRecentSearchesClick(event, showRecentSearches)}
                                    data-cmp="recentSearches"
                                >
                                    <span className="sr-only">
                                        {showRecentSearches ? 'Hide ' : 'Show '}
                                    </span>
                                    My Recent Searches
                                    {' '}
                                    <Glyphicon
                                        aria-hidden="true"
                                        glyph={showRecentSearches ? 'chevron-up' : 'chevron-down'}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {showRecentSearches && (
                        <RecentSearchChipsContainer />
                    )}
                </div>
            )}
        </>
    );
}

export default SearchResultsRecentSearchesContainer;
