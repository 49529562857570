import getConfig from 'next/config';

import { initializeApp } from 'firebase/app';

const { publicRuntimeConfig } = getConfig();

const firebaseConfig = {
    apiKey: publicRuntimeConfig.FCM_CONFIG_KEY,
    authDomain: 'web-push-notification-non-prod.firebaseapp.com',
    projectId: 'web-push-notification-non-prod',
    storageBucket: 'web-push-notification-non-prod.firebasestorage.app',
    messagingSenderId: '433580226963',
    appId: '1:433580226963:web:88d0ad0bb240bae2b35066',
    measurementId: 'G-SH14JMVL1S',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
