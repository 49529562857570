import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import { InventoryListingPlaceholder } from 'reaxl-listing';

import getVehicleHighlightWithFallback from '@/utilities/getVehicleHighlightWithFallback';

import {
    cpoContentDuck,
    userDuck,
} from '@/ducks';

import {
    srpFiltersDuck,
    srpPaginationDuck,
    srpPrimeSpotlightDuck,
} from '@/ducks/srp';

import useVehicleHighlightUrgencyDrivers from './hooks/useVehicleHighlightUrgencyDrivers';
import InventoryBoostContainer from './InventoryBoostContainer';
import InventorySpotlightContainer from './InventorySpotlightContainer';
import PremiumSpotlightContainer from './PremiumSpotlightContainer';

/**
 * render a special listing that is not part of the base listings call
 *
 * @param listingIndex index at which the spotlight should be added
 * @param listingCategory type of special listing
 * @returns {*} the markup with spotlight(s) being injected in the results
 */

function InventorySpecialListing({
    listingIndex,
    listingCategory = 'spotlight',
}) {
    const { brand } = useBrand();

    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const userLocation = useSelector(userDuck.selectors.getCityState);
    const vehicleHighlightListings = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const certifiedSponsor = useSelector(cpoContentDuck.selectors.selectCertifiedContentSimple);

    const {
        vehicleHighlightListingsFinal: vehicleHighlightCarouselListings = [],
        vehicleHighlightCount = 0,
    } = getVehicleHighlightWithFallback(vehicleHighlightListings);
    // use first vehicle highlight listing for boo listing
    const vehicleHighlightBooListing = vehicleHighlightCount === 1 ? vehicleHighlightCarouselListings[0] : vehicleHighlightCarouselListings.shift();
    const vehicleHighlightUrgencyDriverData = useVehicleHighlightUrgencyDrivers({ listing: vehicleHighlightBooListing });
    const currentPage = useSelector(srpPaginationDuck.selectors.getCurrentPage);

    const {
        brand: [, { base_url: baseUrl }],
        override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
        premium_spotlight: [enablePremiumSpotlight],
        spotlight: [isSpotlightEnabled], // feature config
        schema: [, { listing_type: schemaType, vehicle_feature_description: featureDescription, vehicle_price_description: priceDescription }],
        remove_carfax_tile: [removeCarfaxTile],
        vehicle_highlight: [isVehicleHighlightEnabled],
    } = useFeatures([
        'brand',
        'override_carfax_tile',
        'premium_spotlight',
        'spotlight',
        'schema',
        'remove_carfax_tile',
        'vehicle_highlight',
    ]);

    const schemaTemplate = useMemo(() => ({
        featureDescription,
        priceDescription,
    }), [featureDescription, priceDescription]);

    const listingProps = {
        baseUrl,
        schemaTemplate,
        schemaType,
        userLocation,
        currentPage,
        listingIndex,
        brand,
        certifiedSponsor,
        isOverrideCarfaxTileEnabled,
        partner,
        removeCarfaxTile,
    };

    const enableVehicleHighlights = enablePremiumSpotlight && isVehicleHighlightEnabled;

    if (isFiltersVisible) {
        return (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-lstg"
                key={`${listingCategory}-placeholder-${listingIndex}`}
            />
        );
    }

    if (listingCategory === 'spotlight' && !!isSpotlightEnabled) {
        return (
            <InventorySpotlightContainer
                key={`spotlight-${listingIndex}`}
                {...listingProps}
            />
        );
    }

    if (listingCategory === 'boost') {
        return (
            <InventoryBoostContainer
                key={`boost-${listingIndex}`}
                {...listingProps}
            />
        );
    }

    if (enableVehicleHighlights && listingCategory === 'peekaboo') {
        return (
            <PremiumSpotlightContainer
                premiumSpotlight={vehicleHighlightBooListing}
                urgencyDriverData={vehicleHighlightUrgencyDriverData}
                {...listingProps}
            />
        );
    }

    return null;
}

export default InventorySpecialListing;
