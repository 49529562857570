import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _get from 'lodash/get';

import { BrowserStorage } from 'atc-js';

// Formsy

import {
    Alert,
    AnimatedButton,
    Button,
    DisclaimerText,
    Glyphicon,
    Panel,
    Popup,
    PositionedOverlay,
} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { ownerEmailSubcribeClick } from 'reaxl-analytics-handlers';
import {
    Form,
    Input,
    StandardEmailInput,
} from 'reaxl-forms';

import { subscribe } from 'axl-config';

/**
 *  The EmailSubscribeForm component is a generic form with confirmation that takes an email and query and processes based on an action passed by the parent.
 *  It also will leverage the LocalStorage 'ATC_EMAIL_SUBSCRIBE' item to control the visibility of the container via its 'isClosed' boolean.
 *
 */

let Storage;

const getPrivacyStatement = () => (
    <span>
        {subscribe.inline.privacyStatement.text}
        <Popup
            width={600}
            height={400}
            url={subscribe.privacyStatement.href}
        >
            <span className="text-link margin-left-1">
                {subscribe.privacyStatement.label}
            </span>
        </Popup>
        .
    </span>

);

function EmailSubscribeForm({
    email = '',
    emailSubscribeSend,
    registrationError,
    registrationSuccess,
    registrationFailure,
    onClose,
    onSubmit,
    user,
    title,
    uiContext,
    isSubscribeAlert = false,
    onEmailInputChange = () => { },
    ...rest
}) {
    const myForm = useRef(null);
    const isDefault = uiContext === 'default';
    const isLight = uiContext === 'light';
    const { sendClick } = useAnalytics();

    useEffect(() => {
        Storage = new BrowserStorage('emailSubscribe', {
            persist: false,
        });
        const emailSubscribe = Storage.getItem('isClosed') || false;
        if (emailSubscribe && (typeof onClose === 'function')) {
            onClose();
        }
    }, [onClose]);

    const handleClose = () => {
        Storage.setItem('isClosed', false);

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleSubmit = (data) => {
        if (!isSubscribeAlert) {
            myForm.current.reset();
            onSubmit(data);
            sendClick(ownerEmailSubcribeClick);
        } else {
            onSubmit(data);
        }
    };

    const renderCloseButton = () => typeof onClose === 'function' && (
        <Glyphicon
            glyph="remove"
            className="padding-right-2 cursor-pointer"
            onClick={handleClose}
        />
    );

    const containerClassNames = clsx({
        'bg-neutral-tertiary display-sm-flex align-items-center justify-content-center flex-1 padding-top-2 padding-bottom-0': isDefault,
        'row ': isSubscribeAlert,
    });

    const compositeTitleClassNames = clsx('text-size-300', {
        'text-center text-sm-right text-size-sm-400 margin-top-1 margin-top-sm-0 margin-bottom-1 margin-horizontal-1 margin-horizontal-sm-0 margin-right-sm-2': isDefault,
        'margin-bottom-2': isLight,
    });

    const rowClassNames = clsx('row text-left', {
        'margin-right-0': isLight,
        'display-flex justify-content-center align-items-baseline': isDefault,
        'col-lg-8 margin-horizontal-0': isSubscribeAlert,
    });

    const inputClassNames = clsx('', {
        'col-xs-7 padding-right-4': isLight && !isSubscribeAlert,
        'col-xs-12 col-sm-8 padding-right-sm-4 padding-left-lg-2 padding-horizontal-0': isLight && isSubscribeAlert,
        'margin-horizontal-2': isDefault,
    });

    const buttonClassNames = clsx({
        'col-xs-5': isLight && !isSubscribeAlert,
        'col-xs-12 col-sm-4': isLight && isSubscribeAlert,
    });

    const panelClassNames = clsx({
        'col-xs-12 padding-horizontal-0': isSubscribeAlert,
    });

    const disclaimerClassNames = clsx({
        'text-center': !isSubscribeAlert,
        'margin-bottom-4 margin-bottom-sm-0': isSubscribeAlert,
    });

    const titleClassNames = clsx('text-bold', {
        'col-lg-4 display-flex align-items-center margin-bottom-2': isSubscribeAlert,
    });

    const renderDisclaimer = (
        <DisclaimerText
            className={disclaimerClassNames}
            withContrast
        >
            {getPrivacyStatement()}
        </DisclaimerText>
    );

    const renderSubscribeButton = (props) => (
        isSubscribeAlert ? <AnimatedButton {...props} /> : <Button {...props}>Subscribe</Button>
    );

    const alertMap = {
        danger: registrationError,
        success: registrationSuccess,
        warning: registrationFailure,
    };

    const renderAlert = (alertClassNames) => {
        const alertStyle = Object.keys(alertMap).find((key) => alertMap[key]);
        return !!alertStyle && (
            <Alert
                bsStyle={alertStyle}
                className={alertClassNames}
            >
                {alertMap[alertStyle]}
            </Alert>
        );
    };

    return (
        <>
            <PositionedOverlay shrinkwrap={false}>
                <PositionedOverlay.Base>
                    <Panel
                        className={panelClassNames}
                        {...rest}
                    >
                        <Form
                            onValidSubmit={handleSubmit}
                            ref={myForm}
                        >
                            <Panel.Body className={containerClassNames}>
                                <div className={compositeTitleClassNames}>
                                    <div className={titleClassNames}>{title}</div>
                                    {isDefault && renderDisclaimer}
                                </div>

                                <div className={rowClassNames}>
                                    <div className={inputClassNames}>
                                        <StandardEmailInput
                                            maxLength="45"
                                            name="emailAddress"
                                            placeholder="Email"
                                            label=""
                                            required
                                            value={_get(user, 'emailAddress', email)}
                                            onChange={onEmailInputChange}
                                        />
                                        {isLight && isSubscribeAlert && renderDisclaimer}
                                    </div>
                                    {renderSubscribeButton({
                                        className: buttonClassNames,
                                        bsStyle: isDefault ? 'default' : 'secondary',
                                        type: 'submit',
                                        formNoValidate: true,
                                        ...emailSubscribeSend,
                                        key: 'subscribe-button',
                                    })}
                                </div>

                                {isLight && !isSubscribeAlert && renderDisclaimer}

                                <Input
                                    type="hidden"
                                    name="textPot"
                                    id="textPot"
                                    layout="elementOnly"
                                    validations={{
                                        minLength: 0,
                                    }}
                                />
                            </Panel.Body>
                        </Form>
                        {isSubscribeAlert && renderAlert('margin-horizontal-3')}
                    </Panel>
                </PositionedOverlay.Base>
                <PositionedOverlay.Anchor position="topRight">
                    {typeof onClose === 'function' && renderCloseButton()}
                </PositionedOverlay.Anchor>
            </PositionedOverlay>

            {!isSubscribeAlert && renderAlert(isDefault && 'margin-horizontal-1 margin-bottom-1')}
        </>
    );
}

EmailSubscribeForm.propTypes /* remove-proptypes */ = {
    /**
    * The className to apply to the root node
    */
    className: PropTypes.string,
    /**
    * The callback to fire on valid submit of subscribe form
    */
    onSubmit: PropTypes.func,
    /**
    * The callback to fire on close of subscribe form if no function is provided, the button will not display
    */
    onClose: PropTypes.func,
    /**
    * The copy to describe the intent of the form
    */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
    * The copy returning from the registration service in the case or a server error
    */
    registrationError: PropTypes.string,
    /**
    * The copy returning from the registration service for successful registration
    */
    registrationSuccess: PropTypes.string,
    /**
    * The copy returning from the registration service for successful registration
    */
    uiContext: PropTypes.oneOf(['default', 'light']),
};

export default React.memo(EmailSubscribeForm);
