import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function VanSizeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const vanSizeSubtypeOptions = useSelector(srpFiltersDuck.selectors.getVanSizeSubtypeOptions);
    const vanSizeSubtypeValue = useSelector(srpFiltersDuck.selectors.getVanSizeSubtypeValue);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterValues = {
        vanSubtypeCode: vanSizeSubtypeValue,
    };

    const title = getFilterTitle('Van Type', vanSizeSubtypeValue);
    const filterName = 'vanSizeFilter';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(vanSizeSubtypeOptions).length !== 0;

    useEffect(() => {
        if (vanSizeSubtypeValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vanSizeSubtypeValue.length]);

    return !!vanSizeSubtypeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="vanSizeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    withAccordion={false}
                    filter={vanSizeSubtypeOptions}
                    values={filterValues}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    errors={filtersErrors}
                />
            )}
        </AccordionPanel>
    );
}

export default VanSizeFilter;
