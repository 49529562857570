import { useSelector } from 'react-redux';

import { useFeatures } from 'reaxl-features';

import currentPageNameDuck from '@/ducks/currentPageNameDuck';
import globalConfigsDuck from '@/ducks/globalConfigsDuck';
import { srpAdsDuck } from '@/ducks/srp';
import { vdpAdsDuck } from '@/ducks/vdp';

function DisableAd({
    children,
}) {
    const page = useSelector(currentPageNameDuck.selectors.getDuckState) || '';
    const disablePageAdsKey = `DISABLE_${page.toUpperCase()}_ADS`;
    const {
        // Site Brand configs
        ads: [isAdsFeatureEnabled],
        // From Launch Control
        [disablePageAdsKey]: [disablePageAds],
    } = useFeatures(['ads', disablePageAdsKey]);

    const adsEnabled = useSelector(globalConfigsDuck.selectors.getAdsEnabled);

    const adsDuck = page === 'srp' ? srpAdsDuck : vdpAdsDuck;
    const { adUnit = '' } = useSelector(adsDuck.selectors.getDuckState);

    const disableAd = !isAdsFeatureEnabled || !adsEnabled || disablePageAds || adUnit === '';

    return !disableAd && children;
}

export default DisableAd;
