import React, { memo, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import _isEmpty from 'lodash/isEmpty';

import { sendClick } from 'reaxl-analytics';
import { commercialEntryPoint } from 'reaxl-analytics-handlers';
import { PromoCard } from 'reaxl-listing';

import getPathSRP from '@/utilities/getPathSRP';

import { queryDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';

function CommercialPromoCard() {

    const filterValues = useSelector(srpFiltersDuck.selectors.getRequestValues);
    const query = useSelector(queryDuck.selectors.getDuckState);
    const [commercialPath, setCommercialPath] = useState('');

    const handleSearchCTAClick = useCallback((event) => {
        sendClick(commercialEntryPoint, event, {
            cmp: 'cta::search-commercial-vehicles',
            eventSource: 'button',
            filterValues,
        });
    }, [filterValues]);

    useEffect(() => {
        const commercialQuery = { ...query };

        delete commercialQuery.makeCode;
        delete commercialQuery.modelCode;
        delete commercialQuery.trimCode;
        delete commercialQuery.vehicleStyleCode;

        const generateCommercialSrpPath = async () => {
            const path = await getPathSRP({ ...commercialQuery, experience: 'commercial' }, { basePath: true });
            setCommercialPath(path);
        };

        if (!_isEmpty(commercialQuery)) {
            generateCommercialSrpPath();
        }
    }, [query]);

    return (
        <PromoCard
            data-cmp="commercialPromoCard"
            buttonText="Shop Commercial"
            className="col-xs-12 col-sm-4 display-flex"
            handleButtonClick={handleSearchCTAClick}
            heading="Looking for Commercial Vehicles?"
            href={commercialPath}
            imgHeight="99px"
            imgWidth="220px"
            imgSrc="/content/static/img/commercial/commercial_truck_trans_sized.png"
            subText="We&apos;ve rounded up a few choices that could be a perfect match."
        />
    );
}
export default memo(CommercialPromoCard);
