import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function EngineCodeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const engineCodeValues = useSelector(srpFiltersDuck.selectors.getEngineCodeValues);
    const engineCodeOptions = useSelector(srpFiltersDuck.selectors.getEngineCodeOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        engineCode: engineCodeValues,
    };

    const filterName = 'engineCode';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Cylinders', engineCodeValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (engineCodeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [engineCodeValues.length]);

    return !!engineCodeOptions.options?.length && (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="EngineCodeFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <FilterCheckboxes
                    key="cylindersCheckboxes"
                    filter={engineCodeOptions}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    values={filterValues}
                    errors={filtersErrors}
                    withAccordion={false}
                />
            )}
        </AccordionPanel>

    );
}

export default EngineCodeFilter;
