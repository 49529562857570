import { fetchJSON } from '@bonnet/next/fetch';

import { getCertifiedTileByProductId } from 'axl-config';

export default async (query) => {
    const { productId, makeCode } = query;
    const certifiedContent = await fetchJSON('/content/static/json/atc/cpo/certifiedcontent.json', {
        circuitBreaker: {
            id: 'certifiedcontent',
            timeout: 5000,
            resetTimeout: 30000,
            fallback: {},
        },
    });

    if (makeCode) {
        return getCertifiedTileByProductId({ id: productId }, makeCode, certifiedContent);
    }

    return certifiedContent[productId] || {};
};
