import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { Select } from 'reaxl';
import { sendClick } from 'reaxl-analytics';

import { srpSortDropDownClick } from '@/analytics/srpAnalyticsHandlers';

import inventorySortOptions from '@/config/sort';

import {
    srpDisableScrollDuck,
    srpSortDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function FilterSortDropdownContainer() {
    const dispatch = useDispatch();
    const navigateToSrp = useSrpNavigation();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const currentSort = useSelector(srpSortDuck.selectors.getDuckState);

    const sortData = { ...inventorySortOptions, value: currentSort };

    const handleSortChange = useCallback(async (event) => {
        const sortType = _get(event, 'target.value', null) || event;

        // allow the page to scroll to top on page change
        dispatch(srpDisableScrollDuck.creators.setFalse());
        // set sort based on target value
        dispatch(srpSortDuck.creators.setSort(sortType));
        sendClick(srpSortDropDownClick, event, {
            sortType,
        });
        navigateToSrp({ resetPagination: true, isNewSearch: false });
    }, [dispatch, navigateToSrp]);

    const baseSelectClasses = clsx({
        'inside-select-label': !isXs,
    }, 'dropdown-select margin-bottom-0');

    return (
        <Select
            labelProps={!isXs ? { label: 'Sort By:' } : ''}
            onChange={handleSortChange}
            className={baseSelectClasses}
            data-cmp="filteredResultsSortControl"
            key="filteredResultsSortControl"
            {...sortData}
        />
    );
}

export default memo(FilterSortDropdownContainer);
