import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AccordionPanel } from 'reaxl';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function EngineDisplacementFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const engineDisplacementValues = useSelector(srpFiltersDuck.selectors.getEngineDisplacementValues);
    const engineDisplacementOptions = useSelector(srpFiltersDuck.selectors.getEngineDisplacementOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        engineDisplacement: engineDisplacementValues,
    };

    const filterName = 'engineDisplacement';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Engine Displacement', engineDisplacementValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (engineDisplacementValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [engineDisplacementValues.length]);

    return !!engineDisplacementOptions.options?.length
        && (
            <AccordionPanel
                eventKey={filterName}
                data-cmp="EngineDisplacementFilter"
                title={title}
                contentPadding={5}
            >
                {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
                {showFilter && (
                    <FilterCheckboxes
                        key="EngineDisplacementCheckboxes"
                        filter={engineDisplacementOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        values={filterValues}
                        errors={filtersErrors}
                        withAccordion={false}
                    />
                )}
            </AccordionPanel>

        );
}

export default EngineDisplacementFilter;
