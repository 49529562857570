import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { Alert } from 'reaxl';

import { queryDuck } from '@/ducks';

import { srpExpiredListingMessageDuck, srpFiltersDuck } from '@/ducks/srp';

// this component renders an alert message on the SRP when redirected from an expired listing
// that does not contain a search engine marketing "sem" param in the expired listing URL.
// We are leveraging the make, model, zip and redirectExpiredPage=1
export function ExpiredListingRedirectAlertMessageContainer() {

    const makeCode = _get(useSelector(queryDuck.selectors.getDuckState), 'makeCode', '');
    const modelCode = _get(useSelector(queryDuck.selectors.getDuckState), 'modelCode', '');

    const filtersLabels = useSelector(srpFiltersDuck.selectors.getLabelsByValues);
    const make = _get(filtersLabels, ['makeCode', makeCode], '');
    const model = _get(filtersLabels, [`modelCode|${makeCode}`, modelCode], '');
    const redirectExpiredPage = useSelector(srpExpiredListingMessageDuck.selectors.getRedirectExpiredPage);
    const vehicleMessage = make || model ? `${make} ${model}` : 'vehicle';

    return !!redirectExpiredPage && (
        <Alert
            bsStyle="warning"
            data-cmp="expiredListingRedirectAlertMessageContainer"
            className="margin-top-4"
        >
            <span className="text-bold">
                {`We're sorry, the ${vehicleMessage} you were looking for is no longer available.`}
            </span>
            <p>
                Here are vehicles you may be interested in.
            </p>
        </Alert>
    );
}

export default ExpiredListingRedirectAlertMessageContainer;
