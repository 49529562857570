import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { ClientOnly } from '@atc/react-client-only';

import { useFeatures } from 'reaxl-features';

import { srpActiveInteractionDuck } from '@/ducks/srp';

const CompareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "CompareListingsContainer" */
    '@/containers/CompareListingsContainer'
), { ssr: false });
const ShareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "ShareListingsContainer" */
    '@/containers/ShareListingsContainer'
), { ssr: false });
const ShareListingContainer = dynamic(() => import(
    /* webpackChunkName: "ShareListingContainer" */
    '@/containers/srp/ShareListingContainer'
), { ssr: false });
const ReportAtcListing = dynamic(() => import(
    /* webpackChunkName: "ReportAtcListing" */
    '@/components/ReportAtcListing/index.js'
), { ssr: false });
const SearchResultsEmailModalContainer = dynamic(() => import(
    /* webpackChunkName: "SearchResultsEmailModalContainer" */
    '@/containers/srp/SearchResultsEmailModalContainer'
), { ssr: false });

function SearchResultsPageModals() {

    const {
        compare: [showCompare],
        my_atc_shareable_link: [enableSharableLink],
        my_wallet: [enableMyWallet],
    } = useFeatures([
        'compare',
        'my_atc_shareable_link',
        'my_wallet',
    ]);

    const showEmailModal = useSelector(srpActiveInteractionDuck.selectors.getShowEmailModal);
    const showCompareModal = useSelector(srpActiveInteractionDuck.selectors.getShowCompareModal);
    const isShareModalActive = useSelector(srpActiveInteractionDuck.selectors.getShowShareListingModal);
    const isReportListingModalActive = useSelector(srpActiveInteractionDuck.selectors.getShowReportModal);

    return (
        <>
            {enableMyWallet && showCompare && showCompareModal && (
                <ClientOnly>
                    <CompareListingsContainer />
                </ClientOnly>
            )}

            {enableSharableLink && (
                <ClientOnly>
                    <ShareListingsContainer />
                </ClientOnly>
            )}

            {isShareModalActive && (
                <ClientOnly>
                    <ShareListingContainer />
                </ClientOnly>
            )}

            {isReportListingModalActive && (
                <ClientOnly>
                    <ReportAtcListing />
                </ClientOnly>
            )}

            {showEmailModal && <SearchResultsEmailModalContainer />}
        </>
    );
}

export default memo(SearchResultsPageModals);
