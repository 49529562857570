import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import {
    AccordionPanel,
    CheckboxGroup,
} from 'reaxl';
import {
    FilterCheckboxLabel,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import CertifiedTileFilterContainer from '@/containers/srp/CertifiedTileFilterContainer';

import FilterBAPLink from '@/components/FilterBAPLink';

import FilterPlaceholder from './FilterPlaceholder';

/**
*  ListingTypeFilter presents the user with the ability to select a condition
*/
function ListingTypeFilter({
    onOptionChange = () => { },
    handleShowExpansion = () => { },
}) {
    const listingTypeValues = useSelector(srpFiltersDuck.selectors.getListingTypeValues);
    const listingTypeOptions = useSelector(srpFiltersDuck.selectors.getListingTypeOptions);

    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const filtersOptions = useSelector(srpFiltersDuck.selectors.getFiltersOptions);

    const filterName = 'listingType';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(listingTypeOptions).length !== 0;
    const title = getFilterTitle('Condition', listingTypeValues);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (listingTypeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingTypeValues.length]);

    let isDealerCertifiedEnabled = false;

    _get(filtersOptions, 'listingType.options', []).map((option) => {
        if (option.value === '3P_CERT') {
            isDealerCertifiedEnabled = true;
        }
    });

    const chkGrpOptions = listingTypeOptions?.options?.map((option) => {
        const optionCopy = { ...option };

        if (isDealerCertifiedEnabled && option.value === 'CERTIFIED') {
            optionCopy.moreInfo = 'Vehicles undergo a detailed multi-point inspection according to brand specifications. It is reconditioned and backed by a limited manufacturer warranty. See the manufacturer\'s certified program for more details.';
        }
        if (option.value === '3P_CERT') {
            optionCopy.moreInfo = 'The dealer performs a limited inspection on the vehicle, backed by a 30-90 day limited warranty. Certifications may vary. Ask Dealer for details.';
        }

        return {
            ...optionCopy,
            label: <FilterCheckboxLabel {...optionCopy} />,
            value: optionCopy.value,
        };
    });

    const listingTypeFilter = (
        <>
            <FilterBAPLink
                filtersValues={filtersValues}
                filtersOptions={filtersOptions}
            />
            <CheckboxGroup
                name="listingTypes"
                value={listingTypeValues}
                columnClasses="col-xs-4 col-lg-2"
                options={chkGrpOptions}
                onChange={(event) => onOptionChange(event, listingTypeOptions)}
            />

            <CertifiedTileFilterContainer
                filtersValues={filtersValues}
                makeCodeKey="makeCode"
            />

        </>
    );

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="filterCondition"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && listingTypeFilter}
        </AccordionPanel>
    );

}

export default ListingTypeFilter;
