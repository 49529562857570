import React from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'reaxl';

import { srpContentDuck, srpFiltersDuck } from '@/ducks/srp';

function SRPJumpLink() {
    const srpContent = useSelector(srpContentDuck.selectors.getDuckState);
    const { locationHeading, editorials } = srpContent;
    const filtersValues = useSelector(srpFiltersDuck.selectors.getRequestValues);

    const generateLinkLabel = () => {
        // Clean location heading by removing unnecessary phrases
        let formattedHeading = locationHeading.replace(/For sale|near me/gi, '').trim();
        const isUsedListing = filtersValues?.listingType?.[0] === 'USED';

        // Append "s" for non-used listings if not already plural
        if (!isUsedListing && !formattedHeading.toLowerCase().endsWith('s')) {
            formattedHeading = `${formattedHeading}s`;
        }

        let appendTheToSentence = '';
        // Additional condition to modify the cleaned heading
        if (filtersValues?.makeCode !== undefined || filtersValues?.vehicleStyleCode) {
            formattedHeading = formattedHeading.replace(/used /i, '');

            if (isUsedListing && filtersValues?.modelCode !== undefined) {
                appendTheToSentence = 'the ';
            }
        }

        return `Learn more about ${appendTheToSentence}${formattedHeading}`;
    };

    return !!editorials?.length && (
        <Link
            className="margin-left-4 text-size-300"
            href="#model-reference-container"
            label={generateLinkLabel()}
        />
    );
}

export default React.memo(SRPJumpLink);
