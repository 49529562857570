import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';

import { useDevice } from '@bonnet/next/device';

import { AccordionPanel } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { commercialEntryPoint } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { FilterCheckboxes } from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import { srpFiltersDuck } from '@/ducks/srp';

import CommercialSrpFilterEntryPoint from '@/components/CommercialSrpFilterEntryPoint/CommercialSrpFilterEntryPoint';

import FilterPlaceholder from './FilterPlaceholder';

/**
 BodyStyleFilter renders a group of checkbox elements with image element and custom styling
**/

function BodyStyleFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const {
        commercial_entry_point: [enableCommercialEntryPoint],
        srp_body_style_filter_override: [enableCommercialBodyStyleFilterOverride, {
            label: commercialBodyStyleLabel,
            src: commercialBodyStyleSrc,
        }],
    } = useFeatures([
        'commercial_entry_point',
        'srp_body_style_filter_override',
    ]);

    const bodyStyleOptions = useSelector(srpFiltersDuck.selectors.getBodyStyleOptions);
    const bodyStyleValues = useSelector(srpFiltersDuck.selectors.getBodyStyleValues);
    const filterValuesAnalytics = useSelector(srpFiltersDuck.selectors.getRequestValues);

    const getFilterOptions = (formattedOptions) => formattedOptions && formattedOptions.map((option) => {
        // Override van/minivan filter image and label in UI when in commercial experience.
        // Filtering logic isn't affected.
        if (enableCommercialBodyStyleFilterOverride) {
            if (option.value === 'VANMV') {
                option.label.props.label = commercialBodyStyleLabel;
                option.src = commercialBodyStyleSrc;
            }
        }

        return {
            ...option,
            label: option.label,
            src: option.src || `//www.autotrader.com/content/dam/autotrader/homepage/styles/DefaultDesk_Style_${option.value}.png`,
        };
    });

    const filterValues = {
        vehicleStyleCode: bodyStyleValues,
    };

    const filterName = 'vehicleStyleCode';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    const title = getFilterTitle('Body Style', filterValues);

    const device = useDevice();
    const dispatch = useDispatch();
    const isXs = device?.is?.xs;

    const onCommercialEntryPointClick = useCallback(async (event) => {
        sendClick(commercialEntryPoint, event, {
            cmp: 'text-link::show-commercial-vehicles',
            eventSource: 'link',
            filterValues: filterValuesAnalytics,
        });

        if (isXs) {
            await interactionResponse();
            dispatch(srpFiltersDuck.creators.hideFilters());
        }
    }, [filterValuesAnalytics, dispatch, isXs]);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (bodyStyleValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyStyleValues.length]);

    return (
        <AccordionPanel
            data-cmp="BodyStyleFilter"
            title={title}
            contentPadding={showFilter ? 3 : 5}
            eventKey={filterName}
            className="visual-checkboxes"
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} /> }
            {showFilter && (
                <>
                    <FilterCheckboxes
                        withAccordion={false}
                        filter={bodyStyleOptions}
                        values={filterValues}
                        customFilterOptions={getFilterOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                    />
                    {enableCommercialEntryPoint && (
                        <CommercialSrpFilterEntryPoint
                            onCommercialEntryPointClick={onCommercialEntryPointClick}
                            icon="/content/static/img/commercial/commercial_truck_trans_sized.png"
                        />
                    )}
                </>
            )}
        </AccordionPanel>
    );
}

export default BodyStyleFilter;
