import React, { memo, useState } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import { cpoFilterTileClick } from '@/analytics/srpAnalyticsHandlers';

import { cpoContentDuck } from '@/ducks';

import certifiedSponsorFetcher from '@/fetchers/srp/certifiedSponsorFetcher';

import CertifiedPreOwnedModal from '@/components/CertifiedPreOwnedModal/CertifiedPreOwnedModal';
import CertifiedTile from '@/components/CertifiedTile';

const GM_CERT_MAKES = { BUICK: 'Buick', CHEV: 'Chevrolet', GMC: 'GMC' };
const STELLANTIS_MAKES = { CHRYSLER: 'Chrysler', DODGE: 'Dodge', JEEP: 'Jeep', FIAT: 'FIAT', RAM: 'RAM' };

/**
 *
 *Certified Pre-Owned Details.
 * Display the data provided by WordPress, currently we are passing data from Json.
 */

function CertifiedTileFilterContainer({
    filtersValues,
    makeCodeKey,
}) {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const {
        brand: [, { srp_pg_used: srpPageUsed }],
        oem_cpo_srp: [, { condition_filter: isPanelCertTile }],
    } = useFeatures([
        'brand',
        'oem_cpo_srp',
    ]);

    const certifiedContentSimple = useSelector(cpoContentDuck.selectors.selectCertifiedContentSimple);
    const makeCodeList = filtersValues && filtersValues[makeCodeKey];
    const makeCodes = makeCodeList && (typeof makeCodeList === 'object' ? makeCodeList : [makeCodeList]);
    const certifiedTileMap = {};
    const cpoHeading = isPanelCertTile ? 'Certified Program' : 'Certified Program Details';

    const handleShowModal = async ({ event, productId, makeCode, makeLabel }) => {
        sendClick(cpoFilterTileClick, event, {
            cmp: `fts_sp_srch_type - ${srpPageUsed}`,
            makeCode,
        });

        try {
            const certifiedData = await certifiedSponsorFetcher({ productId });
            // Not display inspectionLink on SRP tile
            delete certifiedData.inspectionLink;
            setModalData({ certifiedData, makeLabel, makeCode });
            setShowModal(true);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching certifiedSponsor:', error);
        }
    };

    if (makeCodes && certifiedContentSimple) {
        for (const key of Object.keys(certifiedContentSimple)) {
            if (Object.keys(certifiedTileMap).length === 2) {
                break;
            }

            for (const make of makeCodes) {
                if (make === certifiedContentSimple[key].makeCode) {
                    certifiedTileMap[key] = {
                        ...certifiedContentSimple[key],
                        productId: key,
                    };
                } else if (GM_CERT_MAKES[make]) {
                    certifiedTileMap.CARBRAVO_CERT = {
                        ...certifiedContentSimple.CARBRAVO_CERT,
                        makeCode: make,
                        makeLabel: GM_CERT_MAKES[make],
                        productId: 'CARBRAVO_CERT',
                    };
                } else if (STELLANTIS_MAKES[make]) {
                    certifiedTileMap.STELLANTIS_OFF_CERT = {
                        ...certifiedContentSimple.STELLANTIS_OFF_CERT,
                        makeCode: make,
                        makeLabel: STELLANTIS_MAKES[make],
                        productId: 'STELLANTIS_OFF_CERT',
                    };
                }
            }
        }
    }

    return Object.keys(certifiedTileMap).length > 0 && (
        <>
            <div className="margin-top-5 text-bold text-gray text-size-300 text-size-sm-300">{cpoHeading}</div>
            <div className="row margin-top-3">
                {
                    Object.values(certifiedTileMap).map((content, i) => (
                        <div
                            className={clsx({ 'col-xs-5 col-sm-3 col-md-12 col-lg-6': !isPanelCertTile })}
                            key={`FilterTile-${i}`}
                        >
                            <CertifiedTile
                                data={content}
                                isPanelCertTile={isPanelCertTile}
                                handleShowModal={handleShowModal}
                            />
                        </div>
                    ))
                }
            </div>
            {
                Object.values(certifiedTileMap).map((content, i) => content.linkText && (
                    <div
                        key={`CertifiedLink-${i}`}
                        className={clsx('row padding-left-2', {
                            'margin-top-4': !isPanelCertTile,
                        })}
                    >
                        <CertifiedTile
                            type="link"
                            data={content}
                            handleShowModal={handleShowModal}
                        />
                    </div>
                ))
            }
            <CertifiedPreOwnedModal
                showModal={showModal}
                handleCloseModal={() => setShowModal(false)}
                data={modalData}
                filtersValues={filtersValues}
            />
        </>
    );
}
export default memo(CertifiedTileFilterContainer);
