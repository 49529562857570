import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { formatNumber } from '@atc/string-fns';

import {
    AccordionPanel,
    Button,
    Glyphicon,
    MultiSelectDropdown,
    Select,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { compareTrimsClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import {
    FilterCheckboxLabel,
    FilterTitle,
} from 'reaxl-filters';

import getCpoByMake from '@/utilities/getCpoByMake';

import { cpoContentDuck } from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
} from '@/ducks/srp';

import BuyOnlineButtonContainer from '@/containers/srp/BuyOnlineButtonContainer';

import LazyComponent from '@/components/LazyComponent';
import MMTFilterCpoCTA from '@/components/MmtFilterCpo/MMTFilterCpoCTA';

import FilterPlaceholder from './FilterPlaceholder';

export const CompareTrimsLink = dynamic(() => import(
    /* webpackChunkName: "CompareTrimsLink" */
    '@/components/CompareTrimsLink'
));
const CompareTrimsContainer = dynamic(() => import(
    /* webpackChunkName: "CompareTrimsContainer" */
    '@/containers/CompareTrimsContainer'
    , { ssr: false }
));

const compareTrimCta = (trimFilterOptions, index) => (
    <LazyComponent>
        <CompareTrimsLink
            index={index}
            trimFilterOptions={trimFilterOptions}
        />
    </LazyComponent>
);

/*
 * for each trim code selected, assign a ctaValue & ctaClick in order for an additional link to appear next to the See
 * More expansion link
 */
const getTrimCodeCompareOptions = ({
    modelFilterOptions,
    onCtaClick,
}) => {
    const trimCodeOptions = {};

    if (modelFilterOptions) {
        Object.entries(modelFilterOptions).forEach(([key, value], index) => {
            Object.assign(trimCodeOptions, {
                [key]: {
                    ...value,
                    extendedOption: compareTrimCta(value, index),
                    onExtendedOptionsClick: onCtaClick,
                },
            });
        });
    }

    return trimCodeOptions;
};

/**
 *  Filter By MakeModelTrim combo renders a group of select elements and custom styling
 *
 */
function MakeModelTrimFilter({
    handleRemoveMake = () => { },
    handleShowExpansion = () => { },
    hideOptionCounts = false,
    onOptionChange = () => { },
    groupsLimit = 5,
}) {
    const {
        oem_cpo_srp: [, { mmt_filter: isMMTFilterLink }],
    } = useFeatures([
        'oem_cpo_srp',
    ]);

    const dispatch = useDispatch();

    const handleTrimCtaClick = (event, trimOptions) => {
        sendClick(compareTrimsClick, event);

        const selectedValue = trimOptions?.value || trimOptions?.[0]?.value?.split('|')?.[0];

        dispatch(srpActiveInteractionDuck.creators.setKeys({
            activeModel: selectedValue,
            showTrimsModal: true,
        }));
    };

    // MMT filters
    const makeFilterOptions = useSelector(srpFiltersDuck.selectors.getMakeFilterOptions);
    const makeFilterValues = useSelector(srpFiltersDuck.selectors.getMakeFilterValues);
    const showTrimsModal = useSelector(srpActiveInteractionDuck.selectors.getShowTrimsModal);
    const certifiedContentSimple = useSelector(cpoContentDuck.selectors.selectCertifiedContentSimple);

    const listingTypeFilterValues = useSelector(srpFiltersDuck.selectors.getListingTypeValues);

    const modelFilterOptionsRaw = useSelector(srpFiltersDuck.selectors.getModelFilterOptions);
    const modelFilterOptions = {
        ...getTrimCodeCompareOptions({
            modelFilterOptions: modelFilterOptionsRaw,
            onCtaClick: handleTrimCtaClick,
        }),
    };

    const seriesFilterOptions = useSelector(srpFiltersDuck.selectors.getSeriesFilterOptions);
    const trimFilterOptions = useSelector(srpFiltersDuck.selectors.getTrimFilterOptions);

    const modelFilterValues = useSelector(srpFiltersDuck.selectors.getModelFilterValues);
    const trimFilterValues = useSelector(srpFiltersDuck.selectors.getTrimFilterValues);

    const packageOptions = useSelector(srpFiltersDuck.selectors.getPackageFilterOptions);

    const filterName = 'makeCode';

    // expand the panel if active options inside of it
    useEffect(() => {
        if (makeFilterValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [makeFilterValues.length]);

    // Handles a special case where GM-certified cars need to be associated with a ('CARBRAVO_CERT').
    const cpoUpdatedDataMap = useMemo(() => getCpoByMake(makeFilterValues, certifiedContentSimple), [makeFilterValues, certifiedContentSimple]);

    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(makeFilterOptions).length !== 0;

    const makeCodeKey = 'makeCode';
    const modelCodeKey = 'modelCode';
    const seriesCodeKey = 'seriesCode';
    const trimCodeKey = 'trimCode';
    const packageKey = 'packages';

    const [showNewGroup, setShowNewGroup] = useState(false);
    useEffect(() => {
        if (makeFilterValues.length > 0) {
            setShowNewGroup(false);
        } else {
            setShowNewGroup(true);
        }
    }, [makeFilterValues, setShowNewGroup]);

    const title = (
        <FilterTitle
            title="Make, Model & Trim"
            key="makeModelTrimTitle"
        />
    );

    const getFilterOptions = (options, label) => {
        if (hideOptionCounts) {
            return options;
        }

        const selectOptions = [];
        selectOptions.push({
            label,
            value: label,
        });

        const optionCount = (option) => {
            if (option.count) {
                return `(${formatNumber({ value: option.count })})`;
            }

            if (option.disabled) {
                return '(0)';
            }

            return '';
        };

        options?.map((option) => !makeFilterOptions?.value?.includes(option.value) && selectOptions.push({
            ...option,
            label: `${option.label} ${optionCount(option)}`,
        }));

        return selectOptions;
    };

    const handleButtonClick = () => {
        setShowNewGroup(true);
    };

    const handleRemoveClick = useCallback((value) => {
        const payload = {
            filter: {
                name: makeCodeKey,
                value,
                isMultiple: true,
            },
            value,
            isMultiple: true,
        };
        handleRemoveMake(payload);
    }, [handleRemoveMake]);

    const renderGroup = (makeCode, index) => {
        const models = modelFilterOptions[`${modelCodeKey}|${makeCode}`];
        const series = seriesFilterOptions[`${seriesCodeKey}|${makeCode}`];

        const updateOptionLabels = (options) => options && options.map((option) => ({
            ...option,
            label: <FilterCheckboxLabel
                label={option.label}
                count={option.count}
                moreInfo={option.moreInfo}
                isFiltersCollapsed={option.isFiltersCollapsed}
                filterBadge={option.filterBadge}
            />,
        }));

        const { label } = makeFilterOptions?.options?.find((option) => option.value === makeCode) || {};

        // check if make is available in certifiedContentSimple to display the CPO link and CPO filter is not selected
        const hasMakeWithCPO = Object.values(cpoUpdatedDataMap).some((cpoData) => cpoData.makeLabel.includes(label));
        const displayCPOCTA = hasMakeWithCPO && !listingTypeFilterValues.includes('CERTIFIED');

        return (
            <div
                className="row"
                key={`${makeCode}-${index}`}
            >
                <div className="col-xs-12 padding-bottom-1">
                    <div className="text-size-300 text-subdued-lightest margin-bottom-2 text-bold">
                        {label ? `${label}` : `Vehicle ${index + 1}`}
                        {label && (
                            <Button
                                className="pull-right"
                                bsStyle="text"
                                onClick={() => handleRemoveClick(makeCode)}
                                aria-label="Remove vehicle"
                                title="Remove vehicle"
                            >
                                <Glyphicon
                                    glyph="remove"
                                    className="text-gray"
                                />
                            </Button>
                        )}
                    </div>

                    {label && displayCPOCTA && isMMTFilterLink && (
                        <MMTFilterCpoCTA
                            label={label}
                            cpoData={certifiedContentSimple}
                        />
                    )}
                    {!label && (
                        <Select
                            onChange={(event) => onOptionChange(event, makeFilterOptions)}
                            options={getFilterOptions(makeFilterOptions?.options, 'Any Make')}
                            name={makeFilterOptions.name}
                            value={makeCode || ''}
                        />
                    )}

                    {series && (
                        <MultiSelectDropdown
                            onChange={(event) => onOptionChange(event, series)}
                            options={updateOptionLabels(series?.options)}
                            name={series?.name}
                            defaultSelectLabel="Any"
                            affixLabel="Series"
                            uiContext="filters"
                        />
                    )}

                    {label && (
                        <MultiSelectDropdown
                            onChange={(event) => onOptionChange(event, models)}
                            disabled={!models?.options}
                            options={updateOptionLabels(models?.options)}
                            name={models?.name}
                            defaultSelectLabel="Any"
                            affixLabel="Models"
                            uiContext="filters"
                        />
                    )}

                    {modelFilterValues[`${modelCodeKey}|${makeCode}`]?.map((model, modelIndex) => {
                        const trim = trimFilterOptions[`${trimCodeKey}|${model}`];
                        const trimOptions = trim?.options;
                        const showTrim = trimOptions && trimOptions.length > 0;

                        return showTrim && (
                            <MultiSelectDropdown
                                key={`${model}-trim-${modelIndex}`}
                                onChange={(event) => onOptionChange(event, trim)}
                                options={updateOptionLabels(trim?.options)}
                                name={trim?.name}
                                defaultSelectLabel="Any"
                                affixLabel={`${trim?.label}s`}
                                uiContext="filters"
                            />
                        );
                    })}

                    {label && models?.extendedOption && (
                        <Button
                            bsStyle="text"
                            data-cmp="cntnr-extnd-opt"
                            onClick={(event) => {
                                models?.onExtendedOptionsClick(event, models);
                            }}
                        >
                            {models?.extendedOption}
                        </Button>
                    )}

                    {modelFilterValues[`${modelCodeKey}|${makeCode}`]?.map((model) => (
                        trimFilterValues[`${trimCodeKey}|${model}`]?.map((trim, trimIndex) => {
                            const packageOpts = packageOptions[`${packageKey}|${trim}`];

                            return packageOpts && (
                                <MultiSelectDropdown
                                    key={`${model}-trim-${trimIndex}`}
                                    onChange={(event) => onOptionChange(event, packageOpts)}
                                    options={updateOptionLabels(packageOpts?.options)}
                                    name={packageOpts?.name}
                                    defaultSelectLabel="Any"
                                    affixLabel={`${packageOpts?.label}`}
                                    uiContext="filters"
                                />
                            );
                        })
                    ))}
                </div>
            </div>
        );
    };

    // Troubleshooting t.map TypeError issue
    if (makeFilterValues && !Array.isArray(makeFilterValues)) {
        if (typeof window !== 'undefined' && window.newrelic) {
            window.newrelic.addPageAction('FilterAction', {
                makeCodeKey,
                component: 'MakeModelTrimFilter',
                makes: makeFilterValues,
                typeof: typeof makeFilterValues,
                message: `Value for ${makeCodeKey} is not an array`,
            });
        } else {
            // eslint-disable-next-line no-console
            console.error(`Value for ${makeCodeKey} is not an array. value: ${makeFilterValues} | type: ${typeof makeFilterValues}`);
        }
    }

    let makeCodeValues = makeFilterValues;

    if (typeof makeCodeValues === 'string') {
        makeCodeValues = makeFilterValues.split(' ');
    }

    const renderMakeModelTrim = (
        <>
            {makeCodeValues?.map((makeCode, index) => (
                renderGroup(makeCode, index)
            ))}

            {showNewGroup && renderGroup(null, makeCodeValues?.length || 0)}

            <BuyOnlineButtonContainer />

            {makeCodeValues?.length < groupsLimit && !showNewGroup && (
                <Button
                    block
                    onClick={handleButtonClick}
                >
                    Add Another Make
                </Button>
            )}
        </>
    );

    return (
        <AccordionPanel
            data-cmp="MMTFilter"
            eventKey={filterName}
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && renderMakeModelTrim}
            {showTrimsModal && <CompareTrimsContainer modal />}
        </AccordionPanel>
    );
}

export default MakeModelTrimFilter;
