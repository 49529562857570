import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { BrowserStorage } from 'atc-js';

import { Text } from 'reaxl';
import { sendImpressions } from 'reaxl-analytics';

import { inventoryImpressions } from '@/analytics/srpAnalyticsHandlers';

import { cbhVehicleInterestsDuck } from '@/ducks';

import { srpFixedAdDuck } from '@/ducks/srp';

import SrpListingAlert from '@/components/SrpListingAlert/index.js';

function StillInterestedAlert({
    stillInterestedVehicle,
}) {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const cbhVehiclesCache = new BrowserStorage('VIEWED_CBH_VEHICLES', { persist: true });

    const [showAlert, setShowAlert] = useState(true);

    const hasVehicleInterests = useSelector(cbhVehicleInterestsDuck.selectors.hasActiveResults);

    const vdpViewCount = stillInterestedVehicle?.viewCount;

    const stillInterestedPriceIndicator = stillInterestedVehicle?.pricingDetail?.dealIndicator;

    const scrolled = useSelector(srpFixedAdDuck.selectors.getScrolled);
    const closeFixedAd = useSelector(srpFixedAdDuck.selectors.getCloseFixedAd);

    const assignVehicleLabel = () => {
        if (stillInterestedVehicle?.isReducedPrice) {
            return 'reduced';
        }

        if (vdpViewCount > 3) {
            return 'numviewed';
        }

        if (stillInterestedPriceIndicator === 'Great' || stillInterestedPriceIndicator === 'Good') {
            return 'goodgreatprice';
        }

        return 'interested';
    };

    const stillInterestedLabel = assignVehicleLabel();

    const viewedVehicleIds = cbhVehiclesCache.getItem()?.listingIds || [];

    useEffect(() => {
        if (stillInterestedVehicle) {
            sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: stillInterestedLabel,
                    selectedItemIds: [stillInterestedVehicle.id],
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stillInterestedVehicle?.id, sendImpressions]);

    const urgencyDriverLabel = assignVehicleLabel();

    const displayVehicleLabel = () => {
        const labelKey = {
            reduced: 'Reduced Price!',
            goodgreatprice: `${stillInterestedPriceIndicator} Price`,
            numviewed: `${vdpViewCount} Viewers`,
        };

        return (
            <Text
                className="margin-left-1"
                weight="bold"
                color={urgencyDriverLabel === 'numviewed' ? 'accent' : 'success'}
            >
                {labelKey[urgencyDriverLabel]}
            </Text>
        );
    };

    const alertTitle = (
        <>
            <Text
                weight="bold"
                color="gray-base"
            >
                Still Interested?
            </Text>
            {urgencyDriverLabel !== 'interested' && (
                displayVehicleLabel()
            )}
        </>
    );

    const bottomPosition = isXs ? '60px' : '100px';

    return (hasVehicleInterests && !viewedVehicleIds.includes(stillInterestedVehicle?.id) && showAlert) && (
        <SrpListingAlert
            alertTitle={alertTitle}
            isDismissible
            displayMultipleImages={false}
            handleCloseAlert={setShowAlert}
            listing={stillInterestedVehicle}
            urgencyDriverLabel={urgencyDriverLabel}
            isStillInterestedAlert
            style={{
                boxShadow: '0 5px 10px rgba(0,0,0,.2)',
                position: 'fixed',
                bottom: scrolled && !closeFixedAd ? bottomPosition : '10px',
                right: 10,
                margin: 0,
                marginLeft: '10px',
                width: isXs ? 'unset' : '400px',
                zIndex: 10,
            }}
        />
    );
}

export default StillInterestedAlert;
