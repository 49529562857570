import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { bapDuckPreset } from '@/ducks/presets';

const bapActiveInteractionDuck = objectDuckCreator({
    ...bapDuckPreset,
    store: 'activeInteraction',
    initialState: {
        lastTouched: null,
        showTrimsModal: false,
    },
}).extend({
    selectors: () => ({
        getShowTrimsModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showTrimsModal),
    }),
});

export default bapActiveInteractionDuck;
