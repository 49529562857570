import React from 'react';

import { InventoryListingPlaceholder } from 'reaxl-listing';

export default function SupplementalSearchPlaceholder() {
    return Array(6).fill().map((_, index) => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-supplemental"
            key={`supplemental-placeholder-${index}`}
        />
    ));
}
