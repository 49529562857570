import React, { memo } from 'react';

import clsx from 'clsx';
import _get from 'lodash/get';

import { Button, Panel } from 'reaxl';

// Analytics
/**
 *
 *Certified Pre-Owned Details.
 * Display the data provided by WordPress, currently we are passing data from Json.
 */

function CertifiedTile({
    data = {},
    handleShowModal,
    type,
    isPanelCertTile,
}) {
    const makeCode = _get(data, 'makeCode', '');
    const productId = _get(data, 'productId', '');
    const linkText = _get(data, 'linkText', '');
    const cpoTile = _get(data, 'cpoTile', false);
    const makeLabel = _get(data, 'makeLabel', '');
    const makeCodeLower = makeCode?.toLowerCase();
    const onCertClick = (event) => handleShowModal({ event, makeCode: makeCodeLower, productId, makeLabel });

    if (type === 'link') {
        return (
            <Button
                bsStyle="text"
                onClick={onCertClick}
            >
                {linkText}
            </Button>
        );
    }

    const renderImage = (
        <img
            src={cpoTile}
            alt={makeLabel + ' Certified Vehicles'}
            height="30px"
            width="110px"
        />
    );

    const renderLearnMoreCTA = (
        <Button
            bsStyle="text"
            className="margin-top-1 pull-right text-size-200"
        >
            Learn more
        </Button>
    );

    const renderPanelImage = (
        <Panel className="cursor-pointer padding-0 margin-bottom-3">
            <Panel.Body className="padding-2">
                {renderImage}
                {renderLearnMoreCTA}
            </Panel.Body>
        </Panel>
    );

    return cpoTile && productId && productId !== 'FORD_BLUE_CERT' && (
        <Button
            bsStyle="text"
            data-qaid={'img-certifiedTile-' + makeLabel}
            onClick={onCertClick}
            className={clsx({ 'display-inline-block margin-bottom-4': !isPanelCertTile })}
        >
            {isPanelCertTile ? renderPanelImage : renderImage}
        </Button>
    );
}

export default memo(CertifiedTile);
