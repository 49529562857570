import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import { useFeatures } from 'reaxl-features';
import { InventoryListingPlaceholder, InventoryListingV2 } from 'reaxl-listing';

import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import { srpFiltersDuck, srpResultsDuck } from '@/ducks/srp';

function InventoryListingContainer({ listing, index, paginatedListingIndex }) {

    const getListingProps = useInventoryListingProps();
    const {
        accelerate: [, {
            enable_listing_badge: enableListingBadge,
        }],
    } = useFeatures([
        'accelerate',
    ]);

    const isResultsLoading = useSelector(srpResultsDuck.selectors.getResultLoading);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);

    if (!enableListingBadge) {
        listing.accelerate = false;
    }

    if (isResultsLoading || isFiltersVisible) {
        return (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-lstg"
                key={`inventory-placeholder-${index}`}
            />
        );
    }

    return (
        <div
            className="col-xs-12 col-sm-4 display-flex"
            key={`listing-${index}`}
        >
            <InventoryListingV2 {...getListingProps('listings', listing, index, paginatedListingIndex)} />
        </div>
    );

}

export default memo(InventoryListingContainer, _isEqual);
