import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _set from 'lodash/set';

import { getMarket } from '@atc/bonnet-reference';

import { FilterLocationModalTrigger } from 'reaxl-filters';

import {
    srpFiltersDuck,
    srpMarketExtensionDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

export const FilterLocationModalContainer = React.memo(({
    className,
}) => {
    const dispatch = useDispatch();
    const marketExtension = useSelector(srpMarketExtensionDuck.selectors.getDuckState);

    // We must clone the filter options here as we plan to mutate them below
    const filtersOptions = useSelector(srpFiltersDuck.selectors.getFiltersOptions);
    // TODO: filtersDuck -  Why is this coming in through the srp state and not in the feature flags?
    // update the options with the marketExtension visible flag
    _set(filtersOptions, 'marketExtension.visible', _get(marketExtension, 'active', false));
    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    // filtersValues state should be updated to prop values only when modal is open
    // so we are setting it to false in hide
    const [activeFiltersValues, setActiveFiltersValues] = useState(filtersValues);
    const [activeFiltersErrors, setActiveFiltersErrors] = useState(filtersErrors);

    // filterUpdated: used to determine when to update filtersValues state
    const [filterUpdated, setFilterUpdated] = useState(true);

    const [searchRadius, setSearchRadius] = useState(activeFiltersValues?.searchRadius);
    const [searchZip, setSearchZip] = useState(activeFiltersValues?.zip);

    useEffect(() => {
        if (!filterUpdated) {
            setActiveFiltersValues(filtersValues);
            setFilterUpdated(true);
        }
    }, [filterUpdated, filtersValues]);

    const navigateToSrp = useSrpNavigation();

    const handleZipUpdate = useCallback(async (event, filter) => {
        const { value = '' } = event.target;
        if (value.length === 5) {
            const response = await getMarket(value);

            if (response.success) {
                setActiveFiltersErrors({});
                setSearchZip(value);
            } else {
                setActiveFiltersErrors({ zip: 'Enter a valid ZIP Code' });
            }
        } else {
            setActiveFiltersErrors({ zip: 'Enter a valid ZIP Code' });
        }
    }, []);

    const handleOptionChange = useCallback((event, filter) => {

        event.persist();

        if (filter.name === 'zip') {
            handleZipUpdate(event, filter);
            return;
        }

        if (filter.name === 'marketExtension') {
            dispatch(srpMarketExtensionDuck.creators.setKey({ value: event.target.value }));
        }

        if (filter.name === 'searchRadius') {
            const { value = '' } = event.target;
            setSearchRadius(value);
        }

    }, [dispatch, handleZipUpdate]);

    const handleHide = useCallback((event, submitted = false) => {
        if (!submitted) {
            // revert filter selections to previous values so we don't keep the wrong values
            const modalFiltersKeys = Object.keys(activeFiltersValues).filter((key) => ['zip', 'searchRadius', 'marketExtension'].includes(key));

            const filters = modalFiltersKeys.map((key) => {
                const filter = filtersOptions[key];
                return {
                    filter: {
                        ...filter,
                    },
                    value: filtersValues[key],
                };
            });

            dispatch(srpFiltersDuck.creators.selectFilters(filters));
        }
        dispatch(srpFiltersDuck.creators.removeError({ filter: filtersOptions.zip }));
        setFilterUpdated(false);
    }, [dispatch, activeFiltersValues, filtersOptions, filtersValues]);

    const handleLocationModalSubmit = useCallback((e, onHide) => {
        const hasError = _get(filtersErrors, 'zip', false);

        if (!hasError) {

            dispatch(srpFiltersDuck.creators.applyFilterChange({ target: { value: searchZip } }, filtersOptions?.zip));
            dispatch(srpFiltersDuck.creators.applyFilterChange({ target: { value: searchRadius } }, filtersOptions?.searchRadius));
            // push new instance of srp to history
            navigateToSrp({ resetPagination: true, isNewSearch: true });

            onHide(e, true);
        }
    }, [dispatch, filtersErrors, filtersOptions?.searchRadius, filtersOptions?.zip, navigateToSrp, searchRadius, searchZip]);

    const filterOptionsWithoutMarketExtension = {
        ...filtersOptions,
        marketExtension: {
            ...filtersOptions.marketExtension,
            visible: false,
        },
    };

    return (
        <FilterLocationModalTrigger
            filters={filterOptionsWithoutMarketExtension}
            errors={activeFiltersErrors}
            values={filtersValues}
            searchRadiusValue={searchRadius}
            searchZip={searchZip}
            onOptionChange={handleOptionChange}
            onHide={handleHide}
            onSubmit={handleLocationModalSubmit}
            className={clsx('display-inline-block', className)}
        />
    );
}, _isEqual);

export default FilterLocationModalContainer;
