export default function getVehicleHighlightWithFallback(vehicleHighlightListings = []) {
    if (vehicleHighlightListings.length > 0) {
        // check to make sure returned listings have at least one VH and are not just all fallback
        const isVehicleHighlightIncluded = vehicleHighlightListings.some((listing) => listing?.vehicleHighlight?.isVehicleHighlight);
        if (isVehicleHighlightIncluded) {
            // check how many listings have isVehicleHighlight set to true
            const vehicleHighlightCount = vehicleHighlightListings.filter((listing) => listing?.vehicleHighlight?.isVehicleHighlight).length;
            if (vehicleHighlightCount > 3) {
                // return only listings with isVehicleHighlight set to true
                return {
                    vehicleHighlightListingsFinal: vehicleHighlightListings.filter((listing) => listing?.vehicleHighlight?.isVehicleHighlight),
                    vehicleHighlightCount,
                };
            }
            // return all listings
            return {
                vehicleHighlightListingsFinal: vehicleHighlightListings,
                vehicleHighlightCount,
            };

        }
        return {};

    }
    return {};

}
