import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    AccordionPanel,
    Button,
    DisclaimerText,
    ModalTrigger,
    SideBarToModal,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { FilterCheckboxes, FilterTitle } from 'reaxl-filters';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function VehicleExchangeFilter({
    onOptionChange = () => { },
    onOptionsClear = () => { },
    handleShowExpansion = () => { },
}) {
    const {
        vehicle_exchange: [enableVehicleExchange],
    } = useFeatures([
        'vehicle_exchange',
    ]);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const vehicleExchangeOptions = useSelector(srpFiltersDuck.selectors.getVehicleExchangeOptions);
    const vehicleExchangeValues = useSelector(srpFiltersDuck.selectors.getVehicleExchangeValues);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const filterName = 'vehicleExchange';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(vehicleExchangeOptions).length !== 0;

    const title = (
        <FilterTitle
            key="vehicleExchnageFilterTitle"
            title="Money Back Guarantee"
        />
    );

    const filterValues = {
        vehicleExchange: vehicleExchangeValues,
    };

    // expand the panel if active options inside of it
    useEffect(() => {
        if (vehicleExchangeValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleExchangeValues.length]);

    const vehicleExchangeDetails = () => {
        const programDescription = 'As part of the Ford Blue Advantage certification program, your vehicle comes with a 14 '
            + 'day or 1,000 mile money back guarantee – which ever comes first.  That means you can '
            + 'return your Ford Blue Advantage certified vehicle to the Dealer that you purchased it from and work together to '
            + 'find another that better suits your needs.  See your dealer for important program guidelines '
            + 'and restrictions*.';
        const modalTitle = 'Money Back Guarantee Program Details';

        return (
            <ModalTrigger
                modal={(
                    <SideBarToModal
                        title={isXs ? 'Back' : modalTitle}
                        content={(
                            <div className="margin-left-3 margin-left-sm-0">
                                {isXs && (
                                    <p><strong>{modalTitle}</strong></p>
                                )}
                                <p>
                                    Buy your Ford Blue Advantage vehicle with the peace of mind of knowing it is backed by our
                                    dealer&#39;s commitment to help you choose the right car for you.
                                </p>
                                <p>
                                    {programDescription}
                                </p>
                                <DisclaimerText>
                                    *Only available at participating Ford Dealers. Vehicle must be returned within 14 days/1,000 miles (whichever comes first) from the original sales date and mileage as stated on bill of sale and returned in same condition as sold (excludes normal wear &amp; tear). Ask your dealer for important rules or go to
                                    {' '}
                                    <a
                                        href="https://www.ford.com/money-back"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        ford.com/money-back
                                    </a>
                                    .
                                </DisclaimerText>
                            </div>
                        )}
                        collapsed={isXs}
                        sideBarOptions={{
                            fullWidth: false,
                        }}
                        modalOptions={{
                            bsSize: 'small',
                            scrollPanel: false,
                        }}
                    />
                )}
            >
                <Button
                    bsStyle="text"
                    block
                >
                    Program Details
                </Button>
            </ModalTrigger>
        );
    };

    return enableVehicleExchange && (
        <AccordionPanel
            contentPadding={5}
            eventKey={filterName}
            data-cmp="vehicleExchangeFilter"
            title={title}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && (
                <>
                    <FilterCheckboxes
                        key="filterCheckboxes"
                        filter={vehicleExchangeOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        values={filterValues}
                        errors={filtersErrors}
                        withAccordion={false}
                    />
                    {vehicleExchangeDetails()}
                </>
            )}
        </AccordionPanel>
    );
}

export default VehicleExchangeFilter;
