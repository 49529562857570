import React, { useCallback } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalScrollPanel, ModalTitle } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { CertifiedProgramOffer } from 'reaxl-molecules';

import { cpoFilterTileClick } from '@/analytics/srpAnalyticsHandlers';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function CertifiedPreOwnedModal({ showModal, handleCloseModal, data, filtersValues }) {

    const { makeLabel, makeCode, certifiedData = {} } = data;

    const navigateToSrp = useSrpNavigation();

    const handleCertifiedModalSearchClick = useCallback((event) => {

        sendClick(cpoFilterTileClick, event, {
            cmp: 'srch_cert_v',
            makeCode,
        });
        // Add certified to existing filterValues
        if (filtersValues?.listingType && Array.isArray(filtersValues.listingType)) {
            filtersValues.listingType.push('CERTIFIED');
        } else {
            filtersValues.listingType = ['CERTIFIED'];
        }
        navigateToSrp({
            resetPagination: true,
            isNewSearch: true,
            filtersValues,
        });

        handleCloseModal();

    }, [makeCode, filtersValues, navigateToSrp, handleCloseModal]);

    const CertifiedProgramOfferData = {
        ...certifiedData,
        content: certifiedData.combineContent || certifiedData.content,
        heading: certifiedData.combineHeading || certifiedData.heading,
    };

    return (
        <Modal
            data-cmp="modal-cert-dtls"
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="certified-tile-modal"
        >
            <ModalHeader closeButton>
                <ModalTitle>
                    {`${makeLabel} Certified ${makeLabel === 'Audi' ? 'pre-owned ' : ''}Program Details`}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <ModalScrollPanel>
                    <CertifiedProgramOffer
                        data={CertifiedProgramOfferData}
                        showHeading={false}
                        showTile={false}
                        showCtaLinks={false}
                        className="margin-right-2"
                    />
                </ModalScrollPanel>
            </ModalBody>
            <ModalFooter>
                <Button
                    bsStyle="link"
                    onClick={handleCloseModal}
                >
                    Cancel
                </Button>
                <Button
                    bsStyle="primary"
                    onClick={handleCertifiedModalSearchClick}
                >
                    Include Certified Vehicles
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default CertifiedPreOwnedModal;
