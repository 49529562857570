import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { LinkIcon } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { reportListingClick } from 'reaxl-analytics-handlers';

import {
    compareAddListingClick,
    shareClick,
} from '@/analytics/inventoryAnalyticsHandlers';

import {
    compareListingsDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
} from '@/ducks/srp';

// The contents of the InventoryListing popover
function InventoryQuickActionsContainer({
    clickType,
    listing,
    parentId,
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (listing?.id) {
            dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing.id]));
            dispatch(srpActiveInteractionDuck.creators.setKeys({ parentId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const compareListingIds = useSelector(compareListingsDuck.selectors.getActiveListingIds);
    const activeCompareListing = compareListingIds.includes(listing?.id);

    const handleReportClick = useCallback(async (event) => {
        sendClick(reportListingClick, event, { inventoryId: listing?.id, par: parentId });
        event.stopPropagation();
        await dispatch(srpActiveInteractionDuck.creators.setKeys({ showReportModal: true }));
    }, [dispatch, listing, parentId]);

    const handleShareClick = useCallback(async (event) => {
        event.stopPropagation();
        sendClick(shareClick, event, { type: 'icon', wasSticky: false, id: listing?.id, includePageId: false, par: parentId });
        await dispatch(srpActiveInteractionDuck.creators.setKeys({ showShareListingModal: true }));
        await dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing?.id]));
    }, [dispatch, listing?.id, parentId]);

    const handleCompareClick = useCallback(async (event) => {
        event.stopPropagation();
        if (!activeCompareListing) {
            sendClick(compareAddListingClick, event, {
                clickType,
                inventoryId: listing?.id,
                quickAction: true,
            });
            await dispatch(compareListingsDuck.creators.addToCompare(listing?.id));
            await dispatch(userPreferencesDuck.creators.setCompareToggle(true));
        }
    }, [dispatch, listing?.id, activeCompareListing, clickType]);

    const ctaClasses = 'display-flex no-wrap margin-bottom-2';
    const glyphClasses = 'text-size-400 margin-right-1 text-accent';

    const renderCompareCTA = () => (
        <div className={clsx(ctaClasses, { 'text-bold': activeCompareListing })}>
            <LinkIcon
                className={clsx({ 'text-gray-base': activeCompareListing })}
                glyph={activeCompareListing ? 'success' : 'open'}
                glyphClassName={glyphClasses}
                placement="before"
                onClick={handleCompareClick}
                undecorated
            >
                {activeCompareListing ? 'Added to Compare' : 'Compare'}
            </LinkIcon>
        </div>
    );

    return (
        <>
            {renderCompareCTA()}
            <div className={ctaClasses}>
                <LinkIcon
                    glyph="share"
                    glyphClassName={glyphClasses}
                    placement="before"
                    onClick={handleShareClick}
                    undecorated
                >
                    Share this Listing
                </LinkIcon>
            </div>
            <div className={ctaClasses}>
                <LinkIcon
                    glyph="commenting-o"
                    glyphClassName={glyphClasses}
                    placement="before"
                    onClick={handleReportClick}
                    undecorated
                >
                    Report this Listing
                </LinkIcon>
            </div>
        </>
    );
}

export default InventoryQuickActionsContainer;
