import _flatMapDeep from 'lodash/flatMapDeep';

import { getMarket, getReference } from '@atc/bonnet-reference';
import { formatCurrency } from '@atc/string-fns';

import { brands } from 'reaxl-brand';

import getPathSRP from '@/utilities/getPathSRP';

import { srpBreadcrumbsDuck } from '@/ducks/srp';

const deletableKeysMap = {
    page: 'page',
    location: ['city', 'state', 'zip'],
    owner: 'sellerType',
    condition: ['allListingType', 'listingType'],
    style: 'vehicleStyleCode',
    make: 'makeCode',
    model: 'modelCode',
    trim: 'trimCode',
    year: ['startYear', 'endYear'],
    price: ['minPrice', 'maxPrice'],
    fuelType: 'fuelTypeGroup',
    mpg: 'mpgRange',
    electricRange: 'electricMileRange',
};

const breadcrumbKeys = {
    location: {
        queryParam: ['city', 'state', 'zip'],
        referenceKey: 'location',
    },
    owner: {
        queryParam: 'sellerTypes',
        referenceKey: 'sellerType',
    },
    condition: {
        queryParam: 'listingTypes',
        referenceKey: 'listingType',
    },
    style: {
        queryParam: 'vehicleStyleCodes',
        referenceKey: 'vehicleStyleCode',
    },
    make: {
        queryParam: 'makeCodeList',
        referenceKey: 'makeCode',
    },
    model: {
        queryParam: 'modelCodeList',
        referenceKey: 'modelCode',
    },
    trim: {
        queryParam: 'trimCodeList',
        referenceKey: 'trimCode',
    },
    fuelType: {
        queryParam: 'fuelTypeGroup',
        referenceKey: 'fuelTypeGroup',
    },
    mpg: {
        queryParam: 'mpgRanges',
        referenceKey: 'mpgRange',
    },
    electricRange: {
        queryParam: 'electricMileRange',
        referenceKey: 'electricMileRange',
    },
};

const atcBreadcrumbOrder = ['page', 'owner', 'style', 'make', 'model', 'trim', 'year', 'price', 'fuelType', 'mpg', 'electricRange'];
const fbaBreadcrumbOrder = ['home', 'page', 'location', 'owner', 'condition', 'style', 'make', 'model', 'trim', 'year', 'price', 'fuelType', 'mpg', 'electricRange']; // TODO: 'year' pending decision from SEO team

const updateWithPath = async (ctx, key, breadcrumbOrder, isNational) => {
    const { data, query } = ctx;
    const { brand } = data;
    const isFordBrand = brand === brands.FORD_BRAND;

    let breadcrumbQuery = { ...query };
    const order = [...breadcrumbOrder];

    // find the index of the key in the breadcrumb order
    const keyIdx = order.indexOf(key);

    // get all of the keys that are after the current breadcrumb key from the order array
    // const deleteableKeys = order.filter((orderKey, idx) => idx > keyIdx && orderKey);
    const deletableKeys = order.slice(keyIdx + 1);

    // Iterate though and delete those key/values from the query object
    deletableKeys.forEach((delKey) => {
        const deleteableKey = [].concat(deletableKeysMap[delKey]);
        deleteableKey.forEach((_key) => delete breadcrumbQuery[_key]);
    });

    // NOTE: Default listing type to NEW unless there is one specified to remove "/all-cars" from the returned path
    if (brand !== brands.KBB_BRAND) {
        breadcrumbQuery = {
            ...breadcrumbQuery,
        };
    }

    /**
     * cs                lsc
     * makeCodeList      makeCode
     * vehicleStyleCodes vehicleStyleCode
     */

    // need to spread these because we are about to mutate them
    const breadcrumbsKeyFormatted = { ...deletableKeysMap };
    // remove query params
    /**
    * incase `listingTypes` have more than 1 value
    * [New, Used, ..]
    * that won't be displayed on breadcrumbs but query `listingtypes` still display
    * only take params is `string` not an `object`
    */

    // TODO: 'year' pending decision from SEO team
    if (isFordBrand) {
        // `year` not display on breabcrumbs
        delete breadcrumbsKeyFormatted.year;
    }

    if (isNational) {
        delete breadcrumbsKeyFormatted.location;
    }
    const breadcrumbQueryFormated = {};
    _flatMapDeep(breadcrumbsKeyFormatted).forEach((item) => {
        if (typeof breadcrumbQuery[item] === 'string' && breadcrumbQuery[item]) {
            breadcrumbQueryFormated[item] = breadcrumbQuery[item];
        }
    });

    // ATC & KBB: Remove location data from breadcrumb links
    if (!isFordBrand) {
        delete breadcrumbQueryFormated.zip;
        delete breadcrumbQueryFormated.state;
        delete breadcrumbQueryFormated.city;
        delete breadcrumbQueryFormated.location;
        delete breadcrumbQueryFormated.searchRadius;
        delete breadcrumbQueryFormated.dma;
    }

    // if experience is present in query, add experience key to getPath method
    if (query.experience) {
        breadcrumbQueryFormated.experience = query.experience;
    }

    // Pass remaining query data to getPaths
    let url = await getPathSRP(breadcrumbQueryFormated, {
        brand,
        search: true,
        target: 'cs',
        basePath: true,
    });

    if (key === 'home' && isFordBrand) {
        url = '/cars-for-sale';
    }

    return url;
};

const getLocationLabel = async (query) => {
    if (query.city && query.state) {
        const { payload } = await getMarket(query.zip);

        return !!payload && `${payload.city}, ${payload.state}`;
    }
    // else
    return '';
};

const getValueFromQueryParamOrReferenceKey = (query, key) => query[key.referenceKey];

const getLabelFromReferenceData = async (referenceKey, filterValue, query, isFordBrand) => {
    let referenceData;

    if (referenceKey === 'listingType' && typeof filterValue === 'string') {
        // NOTE: In case of lowercase listingTypes. Reference data only has uppercase
        filterValue = filterValue.toUpperCase();
        if (filterValue === '3P_CERT') {
            filterValue = 'USED';
        }
    }

    if (filterValue && referenceKey === 'modelCode') {
        const makeCode = await getValueFromQueryParamOrReferenceKey(query, breadcrumbKeys.make);
        // NOTE: Model code getReference call requires the makeCode as well.
        referenceData = await getReference(referenceKey, { makeCode });

    } else if (filterValue && referenceKey === 'trimCode' && !isFordBrand) {
        filterValue = (typeof filterValue === 'string') ? filterValue.split('|')[1] : filterValue;
        const makeCode = await getValueFromQueryParamOrReferenceKey(query, breadcrumbKeys.make);
        const modelCode = await getValueFromQueryParamOrReferenceKey(query, breadcrumbKeys.model);
        // NOTE: Trim code getReference call requires the makeCode, modelCode as well.
        referenceData = await getReference(referenceKey, { makeCode, modelCode });
    } else if (referenceKey !== 'trimCode') {
        referenceData = await getReference(referenceKey);
    }

    let matchingReference;
    if (referenceData && referenceData.payload) {
        matchingReference = referenceData.payload.filter((ref) => ref.code === filterValue);
    }

    if (matchingReference && matchingReference.length) {
        // Returns the human value for the breadcrumb label
        return matchingReference[0].name;
    }

    return '';
};

const getLabel = async (key, filterValue, query, isFordBrand, isNational) => {
    const breadcrumbKey = breadcrumbKeys[key];

    let referenceKey;
    if (breadcrumbKey) {
        ({ referenceKey } = breadcrumbKeys[key]);
    }

    switch (key) {
        case 'page': {
            if (isFordBrand) {
                return 'Cars for Sale';
            }

            // 'cause we need to include condition
            const conditionReferenceKey = breadcrumbKeys.condition.referenceKey;
            const conditionFilterValue = getValueFromQueryParamOrReferenceKey(query, breadcrumbKeys.condition);
            const conditionLabel = await getLabelFromReferenceData(conditionReferenceKey, conditionFilterValue, query);

            if (query.experience === 'commercial') {
                return `${conditionLabel || ''} Commercial Vehicles for Sale`.trimStart();
            }
            return `${conditionLabel || ''} Cars for Sale`.trimStart();
        }
        case 'home':
            return 'Home';
        case 'owner':
            return !!filterValue && (filterValue === 'p' ? 'By Owner' : 'Dealer');
        case 'mpg':
            // NOTE: Reference data can handle this once the values are updated to match 10+/20+ pattern
            return !!filterValue && typeof filterValue === 'string' && filterValue !== '0' && filterValue.replace('-', '+ ');
        case 'electricRange':
            // NOTE: Can this be added to reference data?
            return !!filterValue && (`${filterValue} Miles`);
        case 'year':
            if (query.startYear && query.endYear && parseInt(query.startYear, 10) === parseInt(query.endYear, 10)) {
                return query.startYear;
            }
            // else return empty
            return '';
        case 'price':
            // min price only label
            if (query.minPrice && !query.maxPrice) return `Over ${formatCurrency(query.minPrice)}`;
            // max price only label
            if (!query.minPrice && query.maxPrice) return `Under ${formatCurrency(query.maxPrice)}`;
            // min and max price range label
            if (query.minPrice && query.maxPrice) return `Between ${formatCurrency(query.minPrice)} - ${formatCurrency(query.maxPrice)}`;
            // else return empty
            return '';
        case 'style':
            if (!!filterValue && query?.experience === 'commercial') {
                let styleLabel = await getLabelFromReferenceData(referenceKey, filterValue, query, isFordBrand);
                if (styleLabel === 'Van / Minivan') {
                    styleLabel = 'Van';
                }
                return styleLabel && `Commercial ${styleLabel}`;
            }
            return !!filterValue && getLabelFromReferenceData(referenceKey, filterValue, query, isFordBrand);
        case 'location':
            if (!isNational) {
                return getLocationLabel(query);
            }
            break;
        default:
            // NOTE: use getReference to get the human value, if available
            return filterValue && getLabelFromReferenceData(referenceKey, filterValue, query, isFordBrand);
    }

    return '';
};

export default function withBreadcrumbPaths() {

    return async (ctx) => {
        const { query, match } = ctx;
        const { brand } = ctx.data;
        const { isNational } = match; // only add location breadcrumb if there is no location data in url
        const isFordBrand = brand === brands.FORD_BRAND;

        const newBreadcrumbOrder = isFordBrand ? [...fbaBreadcrumbOrder] : [...atcBreadcrumbOrder];

        // Iterate through breadcrumb order keys
        const breadcrumbs = await Promise.all(newBreadcrumbOrder.map(async (key) => {
            let filterValue;

            // TODO: How to clean this up?
            if (key !== 'location' && key !== 'page' && key !== 'price' && key !== 'year' && key !== 'home') {
                // Check query for any values for that breadcrumb key
                filterValue = getValueFromQueryParamOrReferenceKey(query, breadcrumbKeys[key]);
            }

            // Get the breadcrumb label from reference data, if available
            const label = await getLabel(key, filterValue, query, isFordBrand, isNational);
            // Get the href value for the breadcrumb
            const href = await updateWithPath(ctx, key, newBreadcrumbOrder, isNational);

            return { key, label, href };
        }));

        // Process schema items
        const schemaItems = newBreadcrumbOrder.map((key) => {
            const { label, href } = breadcrumbs.filter((crumb) => crumb.key === key)[0];

            return {
                key,
                name: label,
                value: href,
            };
        });

        // Set breadcrumb data on ctx.data
        ctx.data.breadcrumbs = {
            sorted: breadcrumbs,
            schemaItems,
        };

        // Dispatch to redux state
        ctx.store.dispatch(srpBreadcrumbsDuck.creators.setKeys({ ...ctx.data.breadcrumbs }));
    };
}
