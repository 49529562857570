import React, { memo, useCallback, useEffect } from 'react';

import clsx from 'clsx';

import { getSpotlightUrgencyDriver } from '@/utilities';

import {
    Image,
    SponsoredText,
    Text,

} from 'reaxl';
import { sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { InventoryListingV2 } from 'reaxl-listing';

import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import { inventoryImpressions } from '@/analytics/srpAnalyticsHandlers';

function PremiumSpotlightContainer({
    premiumSpotlight,
    urgencyDriverData,
    listingIndex,
}) {
    const getListingProps = useInventoryListingProps();

    const {
        srp_listings_cropped_image: [enableFixedImageHeight],
    } = useFeatures([
        'srp_listings_cropped_image',
    ]);

    const sendListingImpression = useCallback(() => {
        sendImpressions({
            name: inventoryImpressions,
            data: {
                cmp: getSpotlightUrgencyDriver(urgencyDriverData.type),
                selectedItemIds: [premiumSpotlight?.id],
            },
        });
    }, [premiumSpotlight?.id, urgencyDriverData.type]);

    useEffect(() => {
        if (premiumSpotlight) {
            sendListingImpression();
        }
    }, [premiumSpotlight, premiumSpotlight?.id, sendListingImpression]);

    const showUrgencyDriver = premiumSpotlight && urgencyDriverData?.type !== 'default';

    return showUrgencyDriver && (
        <div className="col-sm-4 col-xs-12 display-flex">
            <div
                data-cmp="PremiumSpotlightContainer"
                key="PremiumSpotlightContainer"
                className="panel panel-default bg-brand-secondary display-flex justify-content-around align-items-center flex-grow padding-bottom-3"
            >
                <div
                    className="padding-horizontal-5 padding-top-3 row"
                    style={{ height: '100%', width: '100%' }}
                >
                    <div
                        className="col-12 display-flex align-items-center"
                        style={{ minWidth: '70%', height: '10%' }}
                    >
                        <div className="display-flex align-items-center">
                            <Image
                                height={32}
                                width={32}
                                src={urgencyDriverData.imgSrc}
                                alt=""
                            />
                            <div className="margin-left-3">
                                <Text
                                    componentClass="div"
                                    color="blue-darker"
                                    weight="bold"
                                    size={400}
                                    className="line-height-sm"
                                >
                                    {urgencyDriverData.heading}
                                </Text>
                                <Text
                                    color="success"
                                    weight="bold"
                                    componentClass="div"
                                >
                                    {urgencyDriverData.subText}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div
                        className="padding-top-3"
                        style={{ minWidth: '70%', height: showUrgencyDriver ? '85%' : '95%' }}
                    >
                        <SponsoredText />
                        <InventoryListingV2
                            uiContext="simplified"
                            style={{ height: '100%' }}
                            {...getListingProps('peekaboo', premiumSpotlight, listingIndex)}
                            className={clsx({ 'image-fixed-height': enableFixedImageHeight }, 'bg-neutral-secondary cursor-pointer display-flex')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(PremiumSpotlightContainer);
