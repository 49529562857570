import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { pageNames } from '@atc/bonnet-paths';

import { useFeatures } from 'reaxl-features';

import adUnits from '@/config/adUnits';

import srpAdsDuck from '@/ducks/srp/srpAdsDuck';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

const backFillSize = [320, 50];
const { SEARCH_RESULTS } = pageNames;

export function FooterLeaderAdSlotContainer() {
    const device = useDevice();

    const {
        srp_ads_single_size: [isSingleSizeOnly],
        preserve_ad_space: [preserveAdSpace, {
            footer_ad_height_desktop: maxHeightDesktop,
            footer_ad_height_mobile: maxHeightMobile,
        }],
    } = useFeatures(['srp_ads_single_size', 'preserve_ad_space']);

    const adsLazyLoadingOffset = useSelector(srpAdsDuck.selectors.getAdsLazyLoadingOffset);

    // ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
    let extraSmallSize = isSingleSizeOnly ? [backFillSize] : [backFillSize, [320, 250]];
    let normalSize = [[728, 90]];

    if (preserveAdSpace) {
        extraSmallSize = [
            ...extraSmallSize,
            ...[[1, maxHeightMobile]],
        ];
        normalSize = [
            ...normalSize,
            ...[[1, maxHeightDesktop]],
        ];
    }

    const isXs = _get(device, 'is.xs', false);
    const size = isXs ? extraSmallSize : normalSize;

    const targeting = {
        anml: 'N',
        pos: 'e',
        prd: 'dlr',
    };

    const path = `${adUnits?.atcFindcar}/srp`;

    return (
        <DisableAdsFeatureFlag>
            <AtcAdSlot
                className="display-flex flex-column justify-content-center"
                lazyLoading
                lazyLoadingBottomOffset={adsLazyLoadingOffset}
                lazyLoadingTopOffset={adsLazyLoadingOffset}
                path={path}
                preserveSpace={isSingleSizeOnly || preserveAdSpace}
                showPlaceholder
                size={size}
                slotId="footerLeader"
                targeting={targeting}
                adSelector={srpAdsDuck.selectors.getDuckState}
                pageName={SEARCH_RESULTS}
            />
        </DisableAdsFeatureFlag>
    );
}

export default FooterLeaderAdSlotContainer;
