import React from 'react';

import PropTypes from 'prop-types';

import qs from 'querystring';

import clsx from 'clsx';
import get from 'lodash/get';

import { Carousel } from 'reaxl';
import {
    InventoryListingV2,
} from 'reaxl-listing';

import { imageSizes, placeholderImages } from 'axl-config';

/**
 *  Carousel for similar inventory
 */
function InventoryCarousel({
    carouselSettings = {},
    className,
    clickType = '',
    isCompared = () => { },
    lazyPlaceholder = placeholderImages.imageLoading,
    listings = [],
    onCompareClick = () => { },
    onInventoryListingClick = () => { },
    onListingRemove,
    onListingSelect,
    onNextButtonClick = () => { },
    onPrevButtonClick = () => { },
    onSaveToggle,
    onSettle = () => { },
    onStaticClick = () => { },
    orientation = 'landscape',
    parentId = '',
    savedListingIds = [],
    showCompareButton = false,
    showFooter = true,
    ...rest
}) {

    delete rest.onCollapsibleHeaderClick;
    delete rest.listingCTA;
    delete rest.birfParams;
    delete rest.lazyLoad;
    delete rest.onPricingDetailClick;
    delete rest.paymentsCTA;
    delete rest.onViewOwnerInventoryClick;
    delete rest.uiContext;
    delete rest.onTileClick;
    delete rest.pageValue;

    const getListings = () => (
        listings.map((listing, index) => {
            const modListing = structuredClone(listing);
            const imgIndex = get(modListing, 'images.primary', 0);

            modListing.images = modListing.images ? modListing.images : {
                sources: {},
            };

            modListing.images.sources[imgIndex] = {
                ...modListing.images.sources[imgIndex],
                sizes: imageSizes.inventoryCarousel,
                className: 'img-responsive-scale',
            };

            const addOns = {
                ...listing.addOns,
            };

            if (!addOns.emailSent) {
                addOns.onListingSelect = onListingSelect;
                addOns.onListingRemove = onListingRemove;
            }

            const onClick = (event) => onInventoryListingClick(event, listing, index);
            const onSaveToggleClick = onSaveToggle && ((event, data, isSaved) => { onSaveToggle(event, data, isSaved, index); });

            // Define the listingCTA prop value if the clickType is specified
            // If a clickType query param is specified  - preserve it
            // Vdp url sample: "/cars-for-sale/vehicledetails.xhtml?listingId=558988226&zip=94566"
            const listingCTA = {};
            if (clickType) {
                const vdpUrl = get(modListing, 'website.href', '');
                const [urlPath = '', urlQuery = ''] = vdpUrl ? vdpUrl.split('?') : [];

                const queryParams = qs.parse(urlQuery);
                queryParams.clickType = queryParams.clickType || clickType;
                listingCTA.href = urlPath ? urlPath + '?' + qs.stringify(queryParams) : '';
            }

            return (
                <InventoryListingV2
                    className="display-flex flex-column margin-left-3 margin-top-4"
                    onClick={onClick}
                    addOns={addOns}
                    onSaveToggle={onSaveToggleClick}
                    isSaved={savedListingIds.includes(listing.id)}
                    listing={modListing}
                    listingCTA={listingCTA}
                    ribbon={listing.ribbon}
                    lazyLoad={false}
                    loading="eager"
                    showFooter={showFooter}
                    uiContext="snapshot"
                    isCompared={isCompared(modListing)}
                    onCompareClick={showCompareButton && onCompareClick(modListing)}
                    key={`listing-${listing.id}-${index}`}
                    clickType={clickType}
                    parentId={parentId}
                />
            );
        })
    );

    const getCarousel = () => (
        <div
            data-cmp="inventoryCarousel"
            className={clsx('inventory-carousel', className)}
            {...rest}
        >
            <Carousel
                cellWidth={`${imageSizes.inventoryCarousel.width}px`}
                initialIndex={0}
                fullHeight
                fullWidth={false}
                onStaticClick={onStaticClick}
                onSettle={onSettle}
                lazyLoad={1}
                onNextButtonClick={onNextButtonClick}
                onPrevButtonClick={onPrevButtonClick}
                onDragStart={() => { document.ontouchmove = (e) => e.preventDefault(); }}
                onDragEnd={() => { document.ontouchmove = () => true; }}
                {...carouselSettings}
            >
                {getListings()}
            </Carousel>

            {rest.children}

        </div>
    );
    return getCarousel();
}

InventoryCarousel.propTypes = {
    /**
     * The device orientation ( can be used with featureFlags to control
     * options like view dealer inventory )
     */
    orientation: PropTypes.oneOf([
        'portrait',
        'landscape',
    ]),
    /**
     * Handler for inventory click in carousel
     */
    onInventoryListingClick: PropTypes.func,
    /**
     * The callback to handle events that will fire on static click
     */
    onStaticClick: PropTypes.func,
    /**
     * Toggle handler for save functionality on InventoryAlphaListing
     */
    onSaveToggle: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    /**
     * The callback to handle when the the settling of the carousel
    */
    onSettle: PropTypes.func,
    /**
    * The className to apply to the root node
    */
    className: PropTypes.string,
    /**
     * src for the placeholder to be used before lazy loaded image loads
     */
    lazyPlaceholder: PropTypes.string,
    /**
     * Listings to be passed to Carousel
     */
    listings: PropTypes.array,
    /**
     * Property to append to URL for tagging purposes
     */
    clickType: PropTypes.string,
    /**
     * The callback to handle events that will fire on click of the next arrow button
     */
    onNextButtonClick: PropTypes.func,
    /**
     * The callback to handle events that will fire on click of the prev arrow button
     */
    onPrevButtonClick: PropTypes.func,
    /**
     * All saved listings in redux
     */
    savedListingIds: PropTypes.array,
    /**
     * Any extra carousel settings that might be needed on an instance level
     */
    carouselSettings: PropTypes.object,
    /**
     * When false, the footer on the inventory listing will not show
     */
    showFooter: PropTypes.bool,
};

export default InventoryCarousel;
