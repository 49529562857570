import { fetchJSON } from '@bonnet/next/fetch';

export default (query = {}) => fetchJSON(
    '/rest/lsc/listing/count',
    {
        credentials: 'same-origin',
        circuitBreaker: {
            id: 'count_listing',
            timeout: 3000,
            resetTimeout: 300000,
            fallback: {},
        },
        query,
        headers: {
            'X-fwd-svc': 'atc',
        },
    }
);
