import {
    buildModelSeriesFilterOption,
    buildPackagesFilterOption,
    buildTrimFilterOption,
} from '@/utilities/getReferenceFiltersData';

export default async (ctx, isFilterGroups) => {
    const lscData = ctx?.data?.filters;
    const lscFilterGroupsData = ctx?.data?.filterGroups;
    const queryList = ctx?.query;
    // filterByCategory - is used to limit the filters returned to those within the specified category. e.g. ('COMMERCIAL', 'LUXURY')
    const filterCategoryValue = ctx?.data?.requestParams?.filterByCategory;
    const category = filterCategoryValue && filterCategoryValue.length > 0 ? filterCategoryValue[0] : '';

    const modelListData = await buildModelSeriesFilterOption(lscData, 'modelCode', queryList, lscFilterGroupsData, isFilterGroups, category) || {};
    const trimList = await buildTrimFilterOption(lscData, 'trimCode', queryList, lscFilterGroupsData, isFilterGroups, category) || {};
    const packageList = await buildPackagesFilterOption(lscData, 'packages', queryList, lscFilterGroupsData, isFilterGroups, category) || {};

    const filterGroupObj = {
        ...(trimList != null && trimList?.trimCode) ? { trimCode: { ...trimList.trimCode } } : '',
        ...(modelListData != null && modelListData?.modelCode) ? { modelCode: { ...modelListData.modelCode } } : '',
        ...(modelListData != null && modelListData?.seriesCode) ? { seriesCode: { ...modelListData.seriesCode } } : '',
        ...(packageList != null && packageList) ? { ...packageList } : '',
    };
    return filterGroupObj || {};
};
