import { memo } from 'react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
    Box,
    Col,
    ListColumns,
    Row,
    SubHeading,
    Text,
} from 'reaxl';
import { ConsumerRatings, RatingBreakdown } from 'reaxl-ratings-reviews';

const ratingName = (label = '') => {
    switch (label) {
        case 'Driver Crash Grade':
            return 'Driver Side';
        case 'Passenger Crash Grade':
            return 'Passenger Side';
        case 'Side Impact Crash Front':
            return 'Front Seats';
        case 'Side Impact Crash Rear':
            return 'Rear Seats';
        case 'Rollover Resistance':
            return 'Overall';
        default:
            return '';
    }
};

const categorizeRatings = (ratings = []) => {
    const frontalImpact = [];
    const sideImpact = [];
    const rolloverResist = [];

    ratings.forEach((rating = {}) => {
        rating.name = ratingName(rating.label);
        if (rating.label.includes('Crash Grade')) frontalImpact.push(rating);
        if (rating.label.includes('Side Impact')) sideImpact.push(rating);
        if (rating.label.includes('Rollover Resistance')) rolloverResist.push(rating);
    });

    return { frontalImpact, sideImpact, rolloverResist };
};

function RatingGroup({ title, ratings }) {
    return ratings.length && (
        <>
            <Text weight="bold">{title}</Text>
            <RatingBreakdown
                ratings={ratings}
                className="margin-bottom-2"
                layout="stacked"
            />
        </>
    );
}

function SafetyReferenceContainer({ safetyInfo }) {
    const safetyRatings = _get(safetyInfo, '[0].features["Safety Ratings"]', []);
    const safetyFeatures = _get(safetyInfo, '[0].features["Safety Features"]', []);

    const availableRatingsValues = safetyRatings
        .map(({ value }) => Number(value))
        .filter((value) => value !== 0);

    const ratingsAverage = availableRatingsValues.length
        ? availableRatingsValues.reduce((start, next) => start + next) / availableRatingsValues.length
        : undefined;

    const featuresList = safetyFeatures
        .filter((feature) => feature.value === 'std')
        .map(({ label }) => label);

    const { frontalImpact, sideImpact, rolloverResist } = categorizeRatings(safetyRatings);

    return (!!safetyInfo) && (
        <Row className="margin-top-4">
            {!!ratingsAverage && (
                <Col
                    xs={12}
                    sm={5}
                    className="margin-bottom-4 margin-bottom-sm-0"
                >
                    <ConsumerRatings
                        title="Crash Test Ratings"
                        overallRating={ratingsAverage}
                    />
                    <div className="margin-top-4">
                        <RatingGroup
                            title="Frontal Impact"
                            ratings={frontalImpact}
                        />
                        <RatingGroup
                            title="Side Impact Crash"
                            ratings={sideImpact}
                        />
                        <RatingGroup
                            title="Rollover Resistance Rating"
                            ratings={rolloverResist}
                        />
                    </div>
                </Col>
            )}
            {!_isEmpty(featuresList) && (
                <Col
                    xs={12}
                    sm={7}
                >
                    <Box
                        borderColor="neutral-tertiary"
                        backgroundColor="neutral-primary"
                        padding="compact"
                    >
                        <SubHeading
                            level={3}
                            className="margin-bottom-3"
                        >
                            Safety Features
                        </SubHeading>
                        <ListColumns
                            className="list-unstyled"
                            xs={2}
                            sm={3}
                            md={3}
                            lg={3}
                            items={featuresList}
                            expanded
                        />
                    </Box>
                </Col>
            )}
        </Row>
    );
}

export default memo(SafetyReferenceContainer);
