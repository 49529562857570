import React from 'react';

import ContentLoader from 'react-content-loader';

import { Panel } from 'reaxl';

function renderArticlePlaceholder(offset = 0) {
    return (
        <>
            <rect
                x={offset}
                y="20"
                width="150"
                height="100"
            />
            <rect
                x={165 + offset}
                y="20"
                width="120"
                height="10"
            />
            <rect
                x={165 + offset}
                y="40"
                width="90"
                height="5"
            />
            <rect
                x={165 + offset}
                y="60"
                width="90"
                height="5"
            />
            <rect
                x={165 + offset}
                y="80"
                width="90"
                height="5"
            />
        </>
    );
}

function EVArticlesPlaceholder({ ...rest }) {
    return (
        <Panel
            data-cmp="evArticlesPlaceholder"
            {...rest}
        >
            <Panel.Body className="padding-horizontal-2 padding-vertical-0">
                <ContentLoader
                    speed={2}
                    height="108"
                    backgroundColor="#e1e4e8"
                    foregroundColor="#f8f8f8"
                    style={{ width: '100%' }}
                >
                    {renderArticlePlaceholder()}
                    {renderArticlePlaceholder(300)}
                    {renderArticlePlaceholder(600)}
                    {renderArticlePlaceholder(900)}
                </ContentLoader>
            </Panel.Body>
        </Panel>
    );
}
export default EVArticlesPlaceholder;
