import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import {
    sendClick,
    sendImpressions,
} from 'reaxl-analytics';
import {
    buyOnlinePromoClick,
    buyOnlinePromoImpression,
} from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { PromoCard } from 'reaxl-listing';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function BuyOnlinePromoCard() {

    const {
        hyundai_buy_online: [enableHyundaiBuyOnline],
        srp_buy_online: [enableBuyOnline],
    } = useFeatures(['hyundai_buy_online', 'srp_buy_online']);

    const navigateToSrp = useSrpNavigation();
    const dispatch = useDispatch();

    const device = useDevice();
    const orientation = device?.is?.xs ? 'portrait' : 'landscape';

    const homeServicesOptions = useSelector(srpFiltersDuck.selectors.getHomeServicesOptions);
    const filterValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const makeValues = useSelector(srpFiltersDuck.selectors.getMakeFilterValues);
    const isSponsoredExperience = enableHyundaiBuyOnline && makeValues.includes('HYUND');

    useEffect(() => {
        sendImpressions({
            name: buyOnlinePromoImpression,
            data: {
                filterValues,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (e) => {
        const updatedFilterValues = {
            ...filterValues,
        };

        let filter = {
            collapsed: false,
            label: 'Dealer Home Services',
            name: 'homeServices',
            options: homeServicesOptions,
            value: [],
        };

        let sponsoredMake = '';

        if (isSponsoredExperience) {
            filter = {};
            sponsoredMake = 'hyundai';
            updatedFilterValues.experience = 'hyundai-buy-online';
        } else {
            filter.value = ['BUY_ONLINE'];
            updatedFilterValues.homeServices = ['BUY_ONLINE'];
            if (enableBuyOnline) {
                updatedFilterValues.experience = 'buy-online';
            }
        }

        dispatch(srpFiltersDuck.creators.applyFilterChange(filter?.value, filter, false));

        sendClick(buyOnlinePromoClick, e, { filterValues, sponsoredMake });

        navigateToSrp({
            filtersValues: updatedFilterValues,
            resetPagination: true,
        });
    };

    let promoConfiguration = {
        buttonText: 'Buy My Car Online',
        'data-cmp': 'buyOnlinePromoCard',
        heading: 'Buy Your Next Car Online',
        imgSrc: '/content/static/img/icon/promocard/buy_online.svg',
        imgHeight: 110,
        imgWidth: 110,
    };

    if (isSponsoredExperience) {
        promoConfiguration = {
            buttonText: 'Visit Hyundai Buy Online Store',
            'data-cmp': 'hyundaiBuyOnlinePromoCard',
            heading: 'Save Time. Buy Online.',
            iconName: 'info',
            imgSrc: '/content/static/img/experience/buy-online/hyundai-promo-card.png',
            imgHeight: 140,
            imgWidth: 233,
        };
    }

    return (
        <PromoCard
            className="col-xs-12 margin-vertical-5"
            handleButtonClick={handleButtonClick}
            panelClassName="col-xs-12 col-sm-10"
            subText="Shop online with or without help from the seller for an easy car-buying experience."
            orientation={orientation}
            {...promoConfiguration}
        />
    );
}

export default BuyOnlinePromoCard;
