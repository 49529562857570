import {
    BodyStyleFilter,
    BuyOnlineFilter,
    DoorsFilter,
    DriveTypeFilter,
    ElectricMileRangeFilter,
    EngineCodeFilter,
    EngineDisplacementFilter,
    ExteriorColorFilter,
    FeaturesFilter,
    FuelEconomyFilter,
    FuelTypeFilter,
    HomeServicesFilter,
    InteriorColorFilter,
    ListingTypeFilter,
    LocationFilter,
    MakeModelTrimFilter,
    MileageFilter,
    PriceFilter,
    PriceRatingFilter,
    SedanSizeFilter,
    SellerTypeFilter,
    SUVSizeFilter,
    TransmissionFilter,
    TruckBedLengthFilter,
    TruckCabSizeFilter,
    VanSizeFilter,
    VehicleExchangeFilter,
    VehicleHistoryTypeFilter,
    VehicleUseTypeFilter,
    YearFilter,
} from '@/components/filters';

const filterComponentsMap = {
    BodyStyleFilter,
    BuyOnlineFilter,
    DoorsFilter,
    DriveTypeFilter,
    ElectricMileRangeFilter,
    EngineCodeFilter,
    EngineDisplacementFilter,
    ExteriorColorFilter,
    FeaturesFilter,
    FuelEconomyFilter,
    FuelTypeFilter,
    HomeServicesFilter,
    InteriorColorFilter,
    ListingTypeFilter,
    LocationFilter,
    MakeModelTrimFilter,
    MileageFilter,
    PriceFilter,
    PriceRatingFilter,
    SedanSizeFilter,
    SellerTypeFilter,
    SUVSizeFilter,
    TransmissionFilter,
    TruckBedLengthFilter,
    TruckCabSizeFilter,
    VanSizeFilter,
    VehicleExchangeFilter,
    VehicleHistoryTypeFilter,
    VehicleUseTypeFilter,
    YearFilter,
};

export default filterComponentsMap;
