import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import {
    Select,
} from 'reaxl';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
} from '@/ducks/srp';

function CompareTrimFuelTypeDropdown() {
    const dispatch = useDispatch();
    const device = useDevice();
    const lessThanMd = _get(device, 'lessThan.md', false);

    const fuelTypeOptions = useSelector((state) => srpActiveInteractionDuck.selectors.getFuelTypeOptions(state) || [], _isEqual);
    const defaultFuelType = useSelector(srpActiveInteractionDuck.selectors.getCompareTrimsFuelType || '');
    const fuelTypeGroupFilterValues = useSelector(srpFiltersDuck.selectors.getFuelTypeValues);
    const fuelTypeGroupFilterOptions = useSelector(srpFiltersDuck.selectors.getFuelTypeOptions);

    const changeFuelType = useCallback(
        (fuelType) => {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                compareTrimsFuelType: fuelType,
            }));
        },
        [dispatch]
    );

    const onSelectFuelType = (event) => {
        const fuelType = _get(event, 'target.value', '');
        changeFuelType(fuelType);
    };

    useEffect(() => () => {
        changeFuelType('');
    }, [changeFuelType]);

    useEffect(() => {
        if (fuelTypeGroupFilterValues.length === 1 && Array.isArray(fuelTypeGroupFilterValues[0])) {
            const value = fuelTypeGroupFilterValues[0];
            const selectedOption = fuelTypeGroupFilterOptions.find((option) => option.value === value);
            let newFuelType = selectedOption.label === 'Gasoline' ? 'Gas' : selectedOption.label;

            if (!fuelTypeOptions.find((trim) => trim.value === newFuelType)) {
                newFuelType = '';
            }

            dispatch(srpActiveInteractionDuck.creators.setKeys({
                compareTrimsFuelType: newFuelType,
            }));
        }
    }, [dispatch, fuelTypeGroupFilterOptions, fuelTypeGroupFilterValues, fuelTypeOptions]);

    const extendFuelTypeOptions = [
        { label: 'All Fuel Types', value: '' },
        ...fuelTypeOptions,
    ];

    return (
        <Select
            id="fuel-type-dropdown"
            title="Select Fuel Type"
            label="Fuel Type"
            value={defaultFuelType}
            onChange={onSelectFuelType}
            options={extendFuelTypeOptions}
            layout="elementOnly"
            style={{
                width: lessThanMd ? '140px' : '150px',
            }}
        />
    );
}

export default CompareTrimFuelTypeDropdown;
